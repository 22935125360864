import React, { useState } from 'react';
import { Row, Col, Form, Button, Table, Dropdown, InputGroup, DropdownButton } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiTrashCanOutline, mdiChevronDown } from '@mdi/js';

// sales deta
import PurchaseReturnTableData from "./purchase-return-data.json";

export default function NewQuickSalesReturn() {
  // input value
  const [data, setData] = useState({
    invoice_number: "53", amountReceived: "0", amountDue: "0", amountRepaid: "0", subTotal: "0.00",
    cgst: "0.00", igst: "0.00", sgst: "0.00", totalTaxAmount: "0.00", taxableAmount: "0",
    totalUnitPrice: "0.00", discount: "0", totalDiscount: "0.00", totalItemDiscount: "0.00",
    advancePayment: "0", advancePaymentAmount: "0", grand_total: "0"
  });


  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const [PurchaseTableData] = useState(PurchaseReturnTableData);
  return (
    <>
      <Form>
        <Row>
          <Col sm={3} md={4} xl={1}>
            <div className="mb-3 custom-form-input">
              <Form.Label>Number</Form.Label>
              <Form.Control type="number" name='invoice_number' value={data.invoice_number} onChange={onChange} id='invoice_number' />
            </div>
          </Col>
          <Col sm={9} md={8} xl={4}>
            <div className="mb-3 d-flex">
              <div className='custom-form-input w-100'>
                <Form.Label>Customer Name</Form.Label>
                <Form.Control type="text" placeholder="Enter customer name" name='customerName' id='customerName' />
              </div>
              <Button variant="light custom-form-input-btn ms-2 mt-2">
                <Icon path={mdiPlus} size={1} />
              </Button>
            </div>
          </Col>
          <Col xl={7}>
            <Row>
              <Col md={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control type="number" placeholder="(+001) 1234 5678 90" name='mobileNumber' id='mobileNumber' />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Tax Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter tax name" name='taxName' id='taxName' />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Sale Date</Form.Label>
                  <Form.Control type="date" placeholder="24 November, 2022" name='saleDate' id='saleDate' />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <ul className='list-inline text-end text-muted fs-14 mb-3'>
        <li className='list-inline-item'>
          GST No : <span className='text-muted-dark'>07AAGFF2194N1Z1</span>
        </li>
        <li className='list-inline-item ms-5'>
          Advance Payment : <span className='text-muted-dark'>0</span>
        </li>
        <li className='list-inline-item ms-5'>
          Wallet Balance : <span className='text-muted-dark'>0</span>
        </li>
      </ul>
      <Table responsive bordered className='table-nowrap'>
        <thead className='table-light'>
          <tr>
            <th>Sr.No</th>
            <th>Description</th>
            <th>Unit Price</th>
            <th>Qty</th>
            <th>Disc</th>
            <th>Tax(%)</th>
            <th>Tax Amount</th>
            <th>Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {PurchaseTableData.map((data, i) =>
            <tr key={i}>
              <td>{data.srNo}</td>
              <td>{data.desc}</td>
              <td>{data.unitPrice}</td>
              <td>{data.qty}</td>
              <td>{data.disc}</td>
              <td>
                <Form.Select aria-label="Default select example">
                  <option>None</option>
                  <option value="1">5%</option>
                  <option value="2">10%</option>
                  <option value="2">15%</option>
                  <option value="2">20%</option>
                </Form.Select>
              </td>
              <td>{data.taxAmount}</td>
              <td>{data.total}</td>
              <td className='py-1'>
                <div className='btn-list d-flex'>
                  <Button type='button' variant='light btn-sm'>Add</Button>{' '}
                  <Button type='button' variant='light btn-sm'>Update</Button>{' '}
                  <Button variant="soft-danger btn-sm px-3 ms-auto"><Icon path={mdiTrashCanOutline} className="btn-icon" /></Button>{' '}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Button variant="light btn-sm mb-5"><Icon path={mdiPlus} className="btn-icon me-1" />Add New Row</Button>{' '}

      <Form>
        <Row>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Number Of Qty</Form.Label>
              <Form.Control type="text" placeholder="Enter number of qty..." name='numberQty' id='numberQty' />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Payment Type</Form.Label>
              <Form.Select aria-label="Default select example" name='paymentType' id='paymentType'>
                <option>Cash</option>
                <option value="1">Bank</option>
                <option value="2">Online</option>
              </Form.Select>
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Amount Received</Form.Label>
              <Form.Control type="text" name='amountReceived' id='amountReceived' value={data.amountReceived} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Amount Due</Form.Label>
              <Form.Control type="text" name='amountDue' id='amountDue' value={data.amountDue} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Amount Repaid</Form.Label>
              <Form.Control type="text" name='amountRepaid' id='amountRepaid' value={data.amountRepaid} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Sub Total</Form.Label>
              <Form.Control type="text" name='subTotal' id='subTotal' value={data.subTotal} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>CGST</Form.Label>
              <Form.Control type="text" name='cgst' id='cgst' value={data.cgst} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>SGST</Form.Label>
              <Form.Control type="text" name='igst' id='igst' value={data.igst} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>IGST</Form.Label>
              <Form.Control type="text" name='sgst' id='sgst' value={data.sgst} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Total Tax</Form.Label>
              <Form.Control type="text" name='totalTaxAmount' id='totalTaxAmount' value={data.totalTaxAmount} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Taxable Amount</Form.Label>
              <Form.Control type="text" name='taxableAmount' id='taxableAmount' value={data.taxableAmount} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Total Unit Price</Form.Label>
              <Form.Control type="text" name='totalUnitPrice' id='totalUnitPrice' value={data.totalUnitPrice} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Control aria-label="Text input with dropdown button" name='discount' id='discount' value={data.discount} onChange={onChange} />
              <Form.Label>Discount</Form.Label>
              <DropdownButton variant="light" title={
                <>
                  % Off <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                </>
              } id="input-group-dropdown-2" align="end">
                <Dropdown.Item href="#">10% Off</Dropdown.Item>
                <Dropdown.Item href="#">20% Off</Dropdown.Item>
                <Dropdown.Item href="#">30% Off</Dropdown.Item>
                <Dropdown.Item href="#">40% Off</Dropdown.Item>
                <Dropdown.Item href="#">50% Off</Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Total Discount</Form.Label>
              <Form.Control type="text" name='totalDiscount' id='totalDiscount' value={data.totalDiscount} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Total Item Discount</Form.Label>
              <Form.Control type="text" name='totalItemDiscount' id='totalItemDiscount' value={data.totalItemDiscount} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Advance Payment</Form.Label>
              <Form.Control type="text" name='advancePayment' id='advancePayment' value={data.advancePayment} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Advance Payment Amount</Form.Label>
              <Form.Control type="text" name='advancePaymentAmount' id='advancePaymentAmount' value={data.advancePaymentAmount} onChange={onChange} />
            </div>
          </Col>
          <Col sm={6} md={4} lg={3} xl={2}>
            <div className="mb-3 custom-form-input custom-form-input-sm">
              <Form.Label>Grand Total</Form.Label>
              <Form.Control type="text" name='grand_total' value={data.grand_total} onChange={onChange} />
            </div>
          </Col>
        </Row>
        <ul className='list-inline text-dark fw-medium mb-1 fs-16'>
          <li className='list-inline-item me-5 mb-2 pe-5'>Product Vendor : 10</li>
          <li className='list-inline-item me-5 mb-2 pe-5'>Product Cost Price : 10</li>
          <li className='list-inline-item me-5 mb-2 pe-5'>Product Quantity : 10</li>
          <li className='list-inline-item me-5 mb-2 pe-5'>Product Tax : 10</li>
          <li className='list-inline-item me-5 mb-2 pe-5'>Product Tax Type: 10</li>
          <li className='list-inline-item'>Product Price : 10</li>
        </ul>
        <Row className='align-items-end'>
          <Col xl={5} xxl={6}>
            <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
              <Form.Label>Narration</Form.Label>
              <Form.Control type="text" name='narration' id='narration' placeholder='Enter Narration' />
            </div>
          </Col>
          <Col xl={7} xxl={6} className='text-end'>
            <div className="btn-list">
              <Button variant="primary">Generate and New SR</Button>{' '}
              <Button variant="success">Generate SalesReturn</Button>{' '}
              <Button variant="secondary">Cancel</Button>{' '}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}
