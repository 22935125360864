import React, { useState } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

// react-select
import Select from 'react-select';

import BranchAdminData from "./branch-admin-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function ViewBranchAdmin() {
    // multi-select
    const [value, setValue] = useState(null)
    const options = [
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
        { value: "NFS Warehouse", label: "NFS Warehouse" },
    ]

    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // input value
    const [data, setData] = useState({
        branchName: "Enter NFS Uniform", branch: "BD Enterprise", emailId: "parlepoint@nfs.com", password: "123456789", address: "Parle Point"
    });
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    // table data
    const [branchTableData] = useState(BranchAdminData);
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>NFS Uniform Details</Card.Title>
                        <div className='d-flex'>
                            <div className="btn-list">
                                <Button variant="soft-info p-0" onClick={handleShow}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                                <Button variant="soft-danger p-0">
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                            </div>
                        </div>
                    </div>
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {branchTableData.brnachTable.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>
                                    <th>{data.tableheading2}</th>
                                    <td>{data.tablesubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-3'>Edit NFS Uniform Details</h5>

                    <Form>
                        <Row>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder='' name='branchName' id='branchName' value={data.branchName} onChange={onChange} />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                <Form.Label className='z-1'>Branch</Form.Label>
                                    <Select options={options} defaultValue={value} placeholder="Select Branch..." name='productBranch' id='productBranch' onChange={setValue} isMulti></Select>
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control type="email" placeholder='' name='emailId' id='emailId' value={data.emailId} onChange={onChange} />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder='Enter ...' name='password' id='password' value={data.password} onChange={onChange} />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" placeholder='Enter ...' name='address' id='address' value={data.address} onChange={onChange} />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder='Enter mobile number ...' name='mobileNumber' id='mobileNumber' />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select aria-label="Default select example" name='gender' id='gender'>
                                        <option>Male</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Birthday</Form.Label>
                                    <Form.Control type="date" placeholder='DD/MM/YYYY' name='birthday' id='birthday' />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Date of Join</Form.Label>
                                    <Form.Control type="date" placeholder='DD/MM/YYYY' name='dateofJoin' id='dateofJoin' />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter alternate name...' name='alternateName' id='alternateName' />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder='Enter alternate phone number...' name='alternatePhoneNumber' id='alternatePhoneNumber' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Address</Form.Label>
                                    <Form.Control type="text" placeholder='Enter alternate address...' name='alternateAddress' id='alternateAddress' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Id Proof</Form.Label>
                                    <Form.Control type="text" placeholder='Enter id proof...' name='idProof' id='idProof' />
                                </div>
                            </Col>
                        </Row>
                        <div className='text-end btn-list'>
                            <Button variant="primary" onClick={handleClose}>Update</Button>{' '}
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
