import React, { useState, useEffect } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card, Form, Table, Button, Nav, Fade, Modal } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { postToAPI, getFromAPI, deleteFromAPI, putToAPI, showAlert } from '../../Utils/utils';
import { toast, ToastContainer } from 'react-toastify';
import { fetchBranchData, fetchServiceCategory } from '../../Utils/function_list';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import ServicesLeftData from "./services-left-data.json";

// react-select
import Select from 'react-select'

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiAccountDetails, mdiPlus, mdiAirplaneCog, mdiSquareEditOutline, mdiTrashCanOutline, mdiCardAccountDetailsOutline } from '@mdi/js';


export default function Services() {
    // multi-select
    const initialInputData = {
        name: '',
        service_category: null,
        sac: null,
        duration: null,
        price: '',
        earning: null,
        description: null,
        branch: null,
    };

    const [inputData, setInputData] = useState(initialInputData)
    const [serviceData, setServiceData] = useState([]);
    const [serviceCategory, setServiceCategoryData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editServiceId, setEditServiceId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchServices = async () => {
        try {
            const response = await getFromAPI('fts_services/');
            setServiceData(response); // Assuming response contains an array of service objects
        } catch (error) {
            console.error('Error fetching service data:', error);
        }
    };

    useEffect(() => {
        fetchServices(); // Fetch the services when the component mounts
        fetchServiceCategory(setServiceCategoryData)
        fetchBranchData(setBranchData); // Fetch branch data

    }, []);

    // left navlink active
    useEffect(() => {
        var a = document.getElementsByClassName("left-link-item");
        for (var i = 0; i < a.length; i++) {
            a[i].onclick = function () {
                var b = document.querySelector(".left-link-item a.active");
                if (b) b.classList.remove("active");
                this.children[0].classList.add('active');
                setEditServiceId(this.getAttribute('data-id')); // Update the selected customer ID

            };
        }
    }, []);


    // datatable
    const [ServicesLeftListData] = useState(serviceData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (serviceData.length > 0 && !$.fn.dataTable.isDataTable('#mainDatatableViewServices')) {
            $('#mainDatatableViewServices').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, [serviceData]);

    // Handle Delete Customer Entry
    const handleDeleteService = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Customer?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDelete(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            console.error('Error deleting Customer:', error);
            toast.error('Error deleting Customer', 'error');
        }
    };

    const handleEdit = async (id, e) => {
        e.preventDefault();
        try {
            const response = await getFromAPI(`fts_services/${id}/`);

            // Assuming `response` is an object, not an array
            const service = response.find(item => item.id === id);

            if (service) {
                // Handle the branch field with error handling
                let branchOptions = [];
                if (service.branch) {
                    try {
                        // Replace single quotes with double quotes
                        const branchString = service.branch.replace(/'/g, '"');

                        // Parse the JSON string
                        branchOptions = JSON.parse(branchString);
                    } catch (error) {
                        console.error('Error parsing branch field:', error);
                    }
                }

                setInputData({
                    name: service.name,
                    service_category: service.service_category,
                    sac: service.sac,
                    duration: service.duration,
                    price: service.price,
                    earning: service.earning,
                    description: service.description,
                    branch: branchOptions, // Set the parsed branch options
                });
                setEditServiceId(id);
                setShowEditModal(true);
            } else {
                toast.error('Failed to fetch service details');
            }
        } catch (error) {
            console.error('Error fetching service details:', error);
            toast.error('An unexpected error occurred');
        }
    };


    const handleSubmit = async () => {
        try {
            console.log("InputData:",inputData)
            if(!inputData.name)
                {
                   toast.error('Please Enter Name:','error')
                   return;
                }
                if(!inputData.price)
                {
                   toast.error('Please Enter Price:','error')
                   return
                }
            if (editServiceId) {
                // Edit mode
                const response = await putToAPI(`fts_services/${editServiceId}/`, inputData);
                if (response.status) {
                    toast.success('Service Updated Successfully', 'success');
                } else {
                    toast.error('Failed to update service');
                }
            } else {
                // Add mode
                console.log("Hello")
                const response = await postToAPI("fts_services/", inputData);
                console.log("Response:",response)
                if (response.status) {
                    toast.success('Service Added Successfully', 'success');
                    setInputData(initialInputData)
                } else {
                    toast.error('Failed to add service');
                }
            }
            setShowEditModal(false);
            fetchServices();
        } catch (error) {
            console.error('Error saving service:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await deleteFromAPI(`fts_services/${id}/`);
            if (response.status) {
                toast.success('Service Deleted Successfully', 'success');
                fetchServices(); // Refresh the service data
            } else {
                toast.error('Failed to delete service');
            }
        } catch (error) {
            console.error('Error deleting service:', error);
            toast.error('An unexpected error occurred');
        }
    };

    const filteredCustomerData = serviceData.filter(service => {
        // Convert search term to lower case
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        // Check if either name or service_category includes the search term
        return service.name.toLowerCase().includes(lowerCaseSearchTerm) ||
            (service.service_category && service.service_category.toLowerCase().includes(lowerCaseSearchTerm));
    });


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleAddServiceTabClick = () => {
        setInputData({
            name: null,
            service_category: null,
            sac: null,
            duration: null,
            price: null,
            earning: null,
            description: null,
            branch: null,
        });
        setEditServiceId(null); // Reset editServiceId to null to switch to Add mode

    };

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Services" breadcrumbLast="Service" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-lg-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control
                                                        className=''
                                                        type="text"
                                                        placeholder="Search service..."
                                                        name='serviceSearch'
                                                        id='serviceSearch'
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-lg-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-services-tab" data-bs-toggle="pill" data-bs-target="#pills-view-services" type="button" role="tab" aria-controls="pills-view-services" aria-selected="true">
                                                            <Icon path={mdiAccountDetails} className="btn-icon me-1" />View Services
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-add-service-tab" data-bs-toggle="pill" data-bs-target="#pills-add-service" type="button" role="tab" aria-controls="pills-add-service" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />Add Service
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-manage-rate-card-tab" data-bs-toggle="pill" data-bs-target="#pills-manage-rate-card" type="button" role="tab" aria-controls="pills-manage-rate-card" aria-selected="false">
                                                            <Icon path={mdiCardAccountDetailsOutline} className="btn-icon me-1" />Manage Rate Card
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-import-service-tab" data-bs-toggle="pill" data-bs-target="#pills-import-service" type="button" role="tab" aria-controls="pills-import-service" aria-selected="false">
                                                            <Icon path={mdiAirplaneCog} className="btn-icon me-1" />Import Service
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {filteredCustomerData.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i} data-id={data.id}>
                                                        <Nav.Link className={i === 0 ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.service_category}</h5>
                                                            <p className='product-tab-sub-title'>{data.name}</p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>

                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-services" role="tabpanel" aria-labelledby="pills-view-services-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>View Services</Card.Title>

                                                <Table responsive bordered className="table-nowrap" id="mainDatatableViewServices">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Service Details</th>
                                                            <th>Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {serviceData.length > 0 ? (
                                                            serviceData.map((service, index) => (
                                                                <tr key={index}>
                                                                    <td>{service.name}</td>
                                                                    <td>{service.price}</td>
                                                                    <td className="py-1">
                                                                        <div className="d-flex">
                                                                            <a  variant="link" className="me-2" onClick={(e) => handleEdit(service.id, e)}>
                                                                                <div className="avatar avatar-sm bg-soft-info text-info">
                                                                                    <Icon path={mdiSquareEditOutline} className="avatar-icon" />
                                                                                </div>
                                                                            </a>
                                                                            <a variant="link" onClick={() => handleDeleteService(service.id)}>
                                                                                <div className="avatar avatar-sm bg-soft-danger text-danger">
                                                                                    <Icon path={mdiTrashCanOutline} className="avatar-icon" />
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="3" className="text-center">No services available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </ Card.Body>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-add-service" role="tabpanel" aria-labelledby="pills-add-service-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Add Service</Card.Title>
                                                <Form>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter your name..."
                                                                    name='yourName'
                                                                    id='yourName'
                                                                    value={inputData.name}
                                                                    onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3 d-flex">
                                                                <div className="mb-3 custom-form-input">
                                                                    <Form.Label className='z-1'>Service Category</Form.Label>
                                                                    <Form.Select
                                                                        aria-label="Default select example"
                                                                        name='serviceCategory'
                                                                        id='serviceCategory'
                                                                        value={inputData.service_category}
                                                                        onChange={e => setInputData({ ...inputData, service_category: e.target.value })}
                                                                        isClearable
                                                                    >
                                                                    <option value="">Select Service Category</option>
                                                                    {serviceCategory.map(item => (
                                                                        <option key={item.id}>{item.name}</option>
                                                                    ))}
                                                                    </Form.Select>

                                                                </div>                                        
                                                                     
                                                                <Button variant="light" className="custom-form-input-btn ms-2 mt-2">
                                                                    <Icon path={mdiPlus} size={1} style={{ height: '44px' }} />
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={3}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>SAC</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter SAC..."
                                                                    name='sac'
                                                                    id='sac'
                                                                    value={inputData.sac}
                                                                    onChange={e => setInputData({ ...inputData, sac: e.target.value })}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={3}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Duration(Min)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter duration..."
                                                                    name='durationMin'
                                                                    id='durationMin'
                                                                    value={inputData.duration}
                                                                    onChange={e => setInputData({ ...inputData, duration: e.target.value })}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={3}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Price (₹)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Price..."
                                                                    name='price'
                                                                    id='price'
                                                                    value={inputData.price}
                                                                    onChange={e => setInputData({ ...inputData, price: e.target.value })}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={3}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Earning(%)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Earning..."
                                                                    name='Earning'
                                                                    id='Earning'
                                                                    value={inputData.earning}
                                                                    onChange={e => setInputData({ ...inputData, earning: e.target.value })}

                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Description</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={4}
                                                                    placeholder='Type description...'
                                                                    name='description'
                                                                    id='description'
                                                                    value={inputData.description}
                                                                    onChange={e => setInputData({ ...inputData, description: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label className='z-1'>Branch</Form.Label>
                                                                <Select
                                                                    options={branchData.map(branch => ({
                                                                        value: branch.id,
                                                                        label: branch.name
                                                                    }))}
                                                                    value={inputData.branch}
                                                                    onChange={selectedOption => setInputData({ ...inputData, branch: selectedOption })}
                                                                    isClearable
                                                                    isMulti
                                                                    placeholder="Select Branch"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className="btn-list text-end">
                                                    <Button variant="primary" type="submit" onClick={handleSubmit}>Add</Button>
                                                    <Button variant="secondary" type="button">Cancel</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-manage-rate-card" role="tabpanel" aria-labelledby="pills-manage-rate-card-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Manage Rate Card</Card.Title>
                                                <Form>
                                                    <Row>
                                                        <Col xxl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Service Name</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter service name..." name='serviceName' id='serviceName' />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Current Rate</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter current rate..." name='currentRate' id='currentRate' />
                                                            </div>
                                                        </Col>
                                                        <Col sm={6} xxl={4}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>New Rate</Form.Label>
                                                                <Form.Control type="text" placeholder="Enter new rate..." name='newRate' id='newRate' />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className="btn-list text-end">
                                                    <Button variant="primary" type="submit">Update</Button>
                                                    <Button variant="secondary" type="button">Cancel</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-import-service" role="tabpanel" aria-labelledby="pills-import-service-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Import Services</Card.Title>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group controlId="formFileImportProducts" className="mb-3">
                                                            <Form.Label>Select File</Form.Label>
                                                            <Form.Control type="file" name='importProducts' />
                                                        </Form.Group>
                                                        <Row className='justify-content-center'>
                                                            <Col xl={8} className="text-center">
                                                                <h5 className='mb-3'>Download a sample csv file that you can populate with your own data</h5>
                                                                <p className='text-muted lh-lg fs-14'>Use the button below to upload a csv file of your service list. Once you have selected a file to upload, your service list will be imported.
                                                                    Duplicate services(based on service name & service category) are not created by this process, so you can correct errors and
                                                                    upload the same file more than once, if necessary.</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className="btn-list text-end">
                                                    <Button variant="primary">Download Sample</Button>{' '}
                                                    <Button variant="success">Import</Button>{' '}
                                                    <Button variant="secondary">Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Edit Service Modal */}
                <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{editServiceId ? 'Edit Service' : 'Add Service'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={12}>
                                    <div className="mb-3" style={{ marginBottom: '1rem' }}>
                                        <Form.Label style={{ display: 'block', marginBottom: '0.5rem' }}>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter service name..."
                                            value={inputData.name}
                                            onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3 d-flex">
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label className='z-1'>Service Category</Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                name='serviceCategory'
                                                id='serviceCategory'
                                                value={inputData.service_category}
                                                onChange={e => setInputData({ ...inputData, service_category: e.target.value })}
                                                isClearable
                                            >
                                                <option value="">Select Service Category</option>
                                                    {serviceCategory.map(item => (
                                                    <option key={item.id}>{item.name}</option>
                                                    ))}
                                                </Form.Select>
                                        </div>

                                        <Button variant="light" className="custom-form-input-btn ms-2 mt-2">
                                            <Icon path={mdiPlus} size={1} style={{ height: '44px' }} />
                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3" style={{ marginBottom: '1rem' }}>
                                        <Form.Label style={{ display: 'block', marginBottom: '0.5rem' }}>SAC</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter SAC..."
                                            value={inputData.sac}
                                            onChange={e => setInputData({ ...inputData, sac: e.target.value })}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3" style={{ marginBottom: '1rem' }}>
                                        <Form.Label style={{ display: 'block', marginBottom: '0.5rem' }}>Duration (Min)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter duration..."
                                            value={inputData.duration}
                                            onChange={e => setInputData({ ...inputData, duration: e.target.value })}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3" style={{ marginBottom: '1rem' }}>
                                        <Form.Label style={{ display: 'block', marginBottom: '0.5rem' }}>Price (₹)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Price..."
                                            value={inputData.price}
                                            onChange={e => setInputData({ ...inputData, price: e.target.value })}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3" style={{ marginBottom: '1rem' }}>
                                        <Form.Label style={{ display: 'block', marginBottom: '0.5rem' }}>Earning (%)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Earning..."
                                            value={inputData.earning}
                                            onChange={e => setInputData({ ...inputData, earning: e.target.value })}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3" style={{ marginBottom: '1rem' }}>
                                        <Form.Label style={{ display: 'block', marginBottom: '0.5rem' }}>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            placeholder="Type description..."
                                            value={inputData.description}
                                            onChange={e => setInputData({ ...inputData, description: e.target.value })}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="mb-3" style={{ marginBottom: '1rem' }}>
                                        <Form.Label style={{ display: 'block', marginBottom: '0.5rem' }}>Branch</Form.Label>
                                        <Select
                                            options={branchData.map(branch => ({
                                                value: branch.id,
                                                label: branch.name
                                            }))}
                                            value={inputData.branch}
                                            onChange={selectedOption => setInputData({ ...inputData, branch: selectedOption })}
                                            isClearable
                                            isMulti
                                            placeholder="Select Branch"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditModal(false)}>Close</Button>
                        <Button variant="primary" onClick={handleSubmit}>Save changes</Button>
                    </Modal.Footer>
                </Modal>


                <Footer />
            </div>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />

        </>
    )
}
