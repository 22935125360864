import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Button, Form, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import YearlySummaryReportTableData from "./yearly_summary_report.json";

export default function YearlySummaryReport() {

    // datatable
    const [yearlySummaryData] = useState(YearlySummaryReportTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('.mainDatatableYearlySReport')) {
            $('.mainDatatableYearlySReport').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Reports" breadcrumbLast="Yearly Summary Report" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-0'>Yearly Summary Report</Card.Title>
                                            <Button variant="light">Export to excel</Button>
                                        </div>

                                        <div className="d-md-flex justify-content-between align-items-center mb-3">
                                            <h4 className='mb-3 mb-md-0'>Yearly Tax Summary Report - 2022 To 2023</h4>
                                            <div className="custom-form-input custom-form-input-sm min-w">
                                                <Form.Label>Report Type</Form.Label>
                                                <Form.Select aria-label="Default select example" name='reportType' id='reportType'>
                                                    <option>Purchase</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                </Form.Select>
                                            </div>
                                        </div>

                                        <div>
                                            {yearlySummaryData.map((data, i) =>
                                                <Card className='border shadow-none' key={i}>
                                                    <Card.Body>
                                                        <Card.Title>{data.cardTitle}</Card.Title>

                                                        <Table responsive bordered className='table-nowrap mb-0 mainDatatableYearlySReport'>
                                                            <thead className='table-light'>
                                                                <tr>
                                                                    <th>GST %</th>
                                                                    <th>Taxable Amount</th>
                                                                    <th>SGST</th>
                                                                    <th>CGST</th>
                                                                    <th>IGST</th>
                                                                    <th>CESS</th>
                                                                    <th>Total Tax</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.monthlyGstData.map((data1, j) =>
                                                                    <tr key={j}>
                                                                        <td>{data1.gst}</td>
                                                                        <td>{data1.taxableAmount}</td>
                                                                        <td>{data1.sGST}</td>
                                                                        <td>{data1.cGST}</td>
                                                                        <td>{data1.iGST}</td>
                                                                        <td>{data1.cESS}</td>
                                                                        <td>{data1.totalTax}</td>
                                                                        <td>{data1.total}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                            <tfoot className='table-light'>
                                                                <tr>
                                                                    <th className='fw-normal'>{data.totalGST}</th>
                                                                    <th className='fw-normal'>{data.totalTaxableAmount}</th>
                                                                    <th className='fw-normal'>{data.totalSGST}</th>
                                                                    <th className='fw-normal'>{data.totalCGST}</th>
                                                                    <th className='fw-normal'>{data.totalIGST}</th>
                                                                    <th className='fw-normal'>{data.totalCESS}</th>
                                                                    <th className='fw-normal'>{data.totalTotalTax}</th>
                                                                    <th className='fw-normal'>{data.totalTotal}</th>
                                                                </tr>
                                                            </tfoot>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            )}

                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
