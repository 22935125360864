import React, { useState } from 'react';
import { Card, Form, Button, Row, Col, Dropdown, InputGroup, DropdownButton } from 'react-bootstrap';

// react-select
import Select from 'react-select'

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiChevronDown } from '@mdi/js';
import CreateInvoiceTableData from './CreateInvoiceTableData';

export default function CreateInvoice() {
    const [value, setValue] = useState(null)
    const options = [
        { value: "Test", label: "Test" },
        { value: "Umesh bhai", label: "Umesh bhai" },
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
    ]
    return (
        <>
            <Card>
                <Card.Body className='pb-0'>
                    <Form>
                        <Row>
                            <Col xl={4}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter customer name" name='customerName' id='customerName' />
                                    </div>
                                    <Button variant="light custom-form-input-btn ms-2 mt-2">
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder="(+001) 1234 5678 90" name='mobileNumber' id='mobileNumber' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Invoice Date</Form.Label>
                                    <Form.Control type="date" placeholder="24 November, 2022" name='challanDate' id='challanDate' />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>GST No :</h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Advance Payment : <span className='fw-normal'>0₹</span></h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Membership : <span className='fw-normal'>0</span></h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Notes :</h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Wallet Balance : <span className='fw-normal'>0₹</span></h5>
                        </Col>
                        <Col md={6} xl={4}>
                            <h5 className='fs-16 mb-3'>Membership Credit Balance : <span className='fw-normal'>0₹</span></h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-view-vendor" role="tabpanel" aria-labelledby="pills-view-vendor-tab" tabIndex="0">
                            <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-service-tab" data-bs-toggle="pill" data-bs-target="#pills-service" type="button" role="tab" aria-controls="pills-service" aria-selected="true">Service</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-product-tab" data-bs-toggle="pill" data-bs-target="#pills-product" type="button" role="tab" aria-controls="pills-product" aria-selected="false">Product</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-membership-tab" data-bs-toggle="pill" data-bs-target="#pills-membership" type="button" role="tab" aria-controls="pills-membership" aria-selected="false">Membership</button>
                                </li>
                            </ul>

                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-service" role="tabpanel" aria-labelledby="pills-service-tab" tabIndex="0">
                                    <Form>
                                        <Row className='align-items-end'>
                                            <Col xl={11}>
                                                <Row>
                                                    <Col sm={6} xl={3}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Service Category</Form.Label>
                                                            <Form.Select aria-label="Default select example" name='serviceCategory' id='serviceCategory'>
                                                                <option>All</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} xl={3}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Services</Form.Label>
                                                            <Form.Select aria-label="Default select example" name='services' id='services'>
                                                                <option>Select Service</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} xl={3}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label className='z-1'>Provider</Form.Label>
                                                            <Select options={options} defaultValue={value} placeholder="Select Branch..." name='provider' id='provider' onChange={setValue} isMulti></Select>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} xl={3}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Campaign</Form.Label>
                                                            <Form.Select aria-label="Default select example" name='campaign' id='campaign'>
                                                                <option>Nothing selected</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xl={1} className='text-end'>
                                                <Button variant="primary btn-w-lg mb-3"><Icon path={mdiPlus} className="btn-icon me-1" /> Add</Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    <CreateInvoiceTableData />
                                </div>
                                <div className="tab-pane fade" id="pills-product" role="tabpanel" aria-labelledby="pills-product-tab" tabIndex="0">
                                    <Form>
                                        <Row className='align-items-end'>
                                            <Col lg={11}>
                                                <Row sm={2} md={3} xxl={5} className='row-cols-1'>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Product Category</Form.Label>
                                                            <Form.Select aria-label="Default select example" name='productCategory' id='productCategory'>
                                                                <option>All</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Product</Form.Label>
                                                            <Form.Control type="text" placeholder='Enter product...' name='product' id='product' />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label className='z-1'>Provider</Form.Label>
                                                            <Select options={options} defaultValue={value} placeholder="Select Branch..." name='provider' id='provider' onChange={setValue} isMulti></Select>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Current Stock</Form.Label>
                                                            <Form.Control type="text" placeholder='0' name='currentStock' id='currentStock' />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Quantity</Form.Label>
                                                            <Form.Control type="text" placeholder='1' name='quantity' id='quantity' />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={1} className='text-end'>
                                                <Button variant="primary btn-w-lg mb-3"><Icon path={mdiPlus} className="btn-icon me-1" /> Add</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <CreateInvoiceTableData />
                                </div>
                                <div className="tab-pane fade" id="pills-membership" role="tabpanel" aria-labelledby="pills-membership-tab" tabIndex="0">
                                    <Form>
                                        <Row className='align-items-end'>
                                            <Col lg={10} xl={11}>
                                                <Row>
                                                    <Col sm={6} xl={4}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Memberships</Form.Label>
                                                            <Form.Select aria-label="Default select example" name='memberships' id='memberships'>
                                                                <option>Nothing Selected</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} xl={4}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Start Date</Form.Label>
                                                            <Form.Control type="date" placeholder="24 November, 2022" name='startDate' id='startDate' />
                                                        </div>
                                                    </Col>
                                                    <Col xl={4}>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label className='z-1'>Provider</Form.Label>
                                                            <Select options={options} defaultValue={value} placeholder="Select Branch..." name='provider' id='provider' onChange={setValue} isMulti></Select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={2} xl={1} className='text-end'>
                                                <Button variant="primary btn-w-lg mb-3"><Icon path={mdiPlus} className="btn-icon me-1" /> Add</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <CreateInvoiceTableData />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Sub Total :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'>0</h5>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Total Item Discount :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'>0</h5>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Discount :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <InputGroup className="w-inherit" >
                                        <Form.Control size='sm' aria-label="Text input with dropdown button" name='discount' id='discount' />
                                        <DropdownButton variant="light" title={
                                            <>
                                                % Off <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                            </>
                                        } id="input-group-dropdown-2" align="end">
                                            <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                            <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                            <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                            <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                            <Dropdown.Item href="#">50% Off</Dropdown.Item>
                                        </DropdownButton>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Campaign :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <div className="w-inherit">
                                        <Form.Select aria-label="Default select example" name='campaign' id='campaign' size='sm'>
                                            <option>Nothing selected</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Loyalty :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <div className="w-inherit">
                                        <Form.Select aria-label="Default select example" name='loyalty' id='loyalty' size='sm'>
                                            <option>Nothing selected</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Loyalty Amount :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'>0</h5>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Taxable Amount :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'>0</h5>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>CGST :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'> </h5>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>SGST :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'> </h5>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6}>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Total Tax Amount :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'> </h5>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Advance Payment :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <div className="w-inherit">
                                        <Form.Control type="text" placeholder="" size='sm' name='advancePayment' id='advancePayment' />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Advance Payment Amount :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <div className="w-inherit">
                                        <Form.Control type="text" placeholder="" size='sm' name='advancePaymentAmount' id='advancePaymentAmount' />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Grand Total :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'>0</h5>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Payment Type :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <div className="w-inherit">
                                        <Form.Select aria-label="Default select example" name='paymentType' id='paymentType' size='sm'>
                                            <option>Cash</option>
                                            <option value="1">Bank</option>
                                            <option value="2">Online</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Amount Received :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <div className="w-inherit">
                                        <Form.Control type="text" placeholder="" size='sm' name='amountReceived' id='amountReceived' />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Amount Due :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <h5 className='fs-18 mb-0 fw-normal'>0</h5>
                                </Col>
                            </Row>
                            <Row className='align-items-center mb-3'>
                                <Col xxl={4} className='col-6'>
                                    <h5 className='fs-18 mb-0'>Amount Repaid :</h5>
                                </Col>
                                <Col xxl={8} className='col-6'>
                                    <div className="w-inherit">
                                        <Form.Control type="text" placeholder="" size='sm' name='amountRepaid' id='amountRepaid' />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='align-items-end'>
                        <Col xl={5} xxl={6}>
                            <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                                <Form.Label>Narration</Form.Label>
                                <Form.Control type="text" name='narration' id='narration' placeholder='Enter Narration' />
                            </div>
                        </Col>
                        <Col xl={7} xxl={6} className='text-end'>
                            <div className="btn-list">
                                <Button variant="primary">Generate and Print</Button>{' '}
                                <Button variant="success">Generate and New</Button>{' '}
                                <Button variant="info">Generate Invoice</Button>{' '}
                                <Button variant="warning">Print Option</Button>{' '}
                                <Button variant="secondary">Cancel</Button>{' '}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}
