import React, { useState } from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';


// react-select
import Select from 'react-select';
export default function AddMembership() {
    // multi-select
    const [value, setValue] = useState(null)
    const options = [
        { value: "All Branch", label: "All Branch" },
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
        { value: "NFS Warehouse", label: "NFS Warehouse" },
    ]

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>Add Membership</Card.Title>
                    <Form>
                        <Row>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter member name..." name='memberName' id='memberName' />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Sales Date</Form.Label>
                                    <Form.Control type="date" placeholder="Enter member name..." name='salesDate' id='salesDate' />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="text" placeholder="Enter price..." name='price' id='price' />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Validity</Form.Label>
                                    <Form.Select aria-label="Default select example" name='creditsApplicable' id='creditsApplicable'>
                                        <option>Year</option>
                                        <option value="1">Month</option>
                                        <option value="2">Today</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Min Bill Amt</Form.Label>
                                    <Form.Control type="text" placeholder="Enter bill amount..." name='minBillAmt' id='minBillAmt' />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Validity Period</Form.Label>
                                    <Form.Control type="text" placeholder="Enter validity period..." name='validityPeriod' id='validityPeriod' />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Apply Benefits</Form.Label>
                                    <Form.Select aria-label="Default select example" name='creditsApplicable' id='creditsApplicable'>
                                        <option>Same Invoice</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Credits Applicable?</Form.Label>
                                    <Form.Select aria-label="Default select example" name='creditsApplicable' id='creditsApplicable'>
                                        <option>No</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label className='z-1'>Branch</Form.Label>
                                    <Select options={options} defaultValue={value} placeholder="Select Branch..." name='productBranch' id='productBranch' onChange={setValue} isMulti></Select>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className="btn-list text-end">
                        <Button variant="primary" type="submit">Add</Button>{' '}
                        <Button variant="secondary" type="button">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
