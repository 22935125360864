import React, { useState, useEffect } from 'react';
import { Card, Table, Badge, Button } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// invoices data
import InvoicesData from "./invoices-data.json";

export default function Invoices() {
    // datatable
    const [invoicesTableData] = useState(InvoicesData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableCustomersInvoices')) {
            $('#mainDatatableCustomersInvoices').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <Card.Title>Invoices</Card.Title>
            <Table responsive bordered className='table-nowrap' id='mainDatatableCustomersInvoices'>
                <thead className='table-light'>
                    <tr>
                        <th>Invoice#</th>
                        <th>Date</th>
                        <th>Total</th>
                        <th>Amt Due</th>
                        <th>Status</th>
                        <th>Payment Type</th>
                        <th>Branch Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {invoicesTableData.map((data, i) =>
                        <tr key={i}>
                            <td>{data.invoicesNo}</td>
                            <td>{data.invoicesDate}</td>
                            <td>{data.invoicesTotal}</td>
                            <td>{data.invoicesAmtDue}</td>
                            <td>
                                <Badge bg="soft-success">Complete</Badge>
                            </td>
                            <td>{data.invoicesPaymentType}</td>
                            <td>{data.invoicesBranch}</td>
                            <td>
                                <Button variant="light btn-sm">View Invoice</Button>
                            </td>
                        </tr>
                    )}

                </tbody>
            </Table>
        </>
    )
}
