import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Table, Button, Badge } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';

import ViewSalesReturnData from "./view-sales-return.data.json";


export default function ViewSalesReturn() {
    // datatable
    const [ViewSalesTableData] = useState(ViewSalesReturnData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableSalesReturn')) {
            $('#mainDatatableSalesReturn').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableSalesReturn_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableSalesReturn_wrapper").classList.remove("dtl-right");
        }
    }, []);
    return (
        <>
            <Row className='justify-content-center'>
                <Col xxl={10}>
                    <Row>
                        <Col xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Rows : 10</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Invoice Amt : 16425.00</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Taxable Amt : 15417.31</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3}>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Tax Amt : 1007.72</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Table responsive bordered className='table-nowrap' id='mainDatatableSalesReturn'>
                <thead className='table-light'>
                    <tr>
                        <th>Invoice#</th>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Total</th>
                        <th>Amt Paid</th>
                        <th>Amt Due</th>
                        <th>Status</th>
                        <th>Payment Type</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {ViewSalesTableData.map((data, i) =>
                        <tr key={i}>
                            <td>{data.invoice}</td>
                            <td>{data.date}</td>
                            <td>{data.customer}</td>
                            <td>{data.total}</td>
                            <td>{data.amtPaid}</td>
                            <td>{data.amtDue}</td>
                            <td><Badge bg="soft-danger">Unpaid</Badge></td>
                            <td>{data.paymentType}</td>
                            <td className='py-1'>
                                <div className='btn-list d-flex'>
                                    <Button type='button' variant='light btn-sm'>View Invoice</Button>{' '}
                                    <Button type='button' variant='light btn-sm'>Edit Invoice</Button>{' '}
                                    <Button type='button' variant='light btn-sm'>Print Invoice</Button>{' '}
                                    <Button variant="soft-danger btn-sm px-3 ms-auto"><Icon path={mdiTrashCanOutline} className="btn-icon" /></Button>{' '}
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    )
}
