import React, { useState, useEffect } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import WalletData from "./wallet-data.json";

export default function Wallet() {

    // datatable
    const [walletTableData] = useState(WalletData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableWallet')) {
            $('#mainDatatableWallet').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <Card.Title>Wallet</Card.Title>
            <Table responsive bordered className='table-nowrap' id='mainDatatableWallet'>
                <thead className='table-light'>
                    <tr>
                        <th>Date</th>
                        <th>Promotional Amount</th>
                        <th>Redeemable Amount</th>
                        <th>Type</th>
                        <th>Invoice</th>
                        <th>Branch Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {walletTableData.map((data, i) =>
                        <tr key={i}>
                            <td>{data.invoicesDate}</td>
                            <td>{data.invoicesPromotionalAmount}</td>
                            <td>{data.invoicesRedeemableAmount}</td>
                            <td>{data.invoicesType}</td>
                            <td>{data.invoicesInvoice}</td>
                            <td>{data.invoicesBranch}</td>
                            <td>
                                <Button variant="light btn-sm">View Invoice</Button>{' '}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    )
}
