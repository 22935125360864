import React, { useState, useEffect, useContext } from 'react';
import { Table, Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI } from '../../../Utils/utils';
// icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { CustomerContext1 } from '../customerContext';
import { useCustomer, CustomerProvider } from '../getCustomerData'; // Import the useCustomer hook
import { toast, ToastContainer } from "react-toastify";


export default function CustomerDetails() {
    const selectedCustomerId = useContext(CustomerContext1);
    const [showEditModal, setShowEditModal] = useState(false);
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array
    const { fetchCustomerData } = useCustomer();
    const [country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [Tax, setTax] = useState([]);
    const [Customer, setCustomer] = useState([]);
    const [Bank, setBank] = useState([]);
    const [stateName, setStateName] = useState();
    const [stateCode, setStateCode] = useState();
    const [cityName, setCityName] = useState();
    const [inputData, setInputData] = useState({
        customer_name: '',
        email: '',
        Mobile_Number: '',
        gender: '',
        birthday: null,
        anniversary: null,
        address: '',
        referred_by: '',
        GST: null,
        country: '',
        state: '',
        city: '',
        tax: '',
        pan: null,
        opening_balance: null,
        bank_name: '',
        ifsc_code: '',
        AccountNumber: null,
    });


    useEffect(() => {
        const fetchCustomerDetails = async () => {
            try {
                const response = await getFromAPI(`FtsClients/?id=${selectedCustomerId}`);
                if (response && response.length > 0) {
                    const customerData = response[0];
                    setInputData({
                        customer_name: customerData.customer_name || '',
                        email: customerData.email || '',
                        Mobile_Number: customerData.Mobile_Number || '',
                        gender: customerData.gender || '',
                        birthday: customerData.birthday || '',
                        anniversary: customerData.anniversary || '',
                        address: customerData.address || '',
                        referred_by: customerData.referred_by || '',
                        GST: customerData.GST || '',
                        country: customerData.country || '',
                        state: customerData.state || '',
                        city: customerData.city || '',
                        tax: customerData.tax || '',
                        pan: customerData.pan || '',
                        opening_balance: customerData.opening_balance || '',
                        bank_name: customerData.bank_name || '',
                        ifsc_code: customerData.ifsc_code || '',
                        AccountNumber: customerData.AccountNumber || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching customer data:', error);
            }
        };

        if (selectedCustomerId) {
            fetchCustomerDetails();
        }
    }, [selectedCustomerId]);


    const fetchCustomerData1 = async () => {
        try {
            const response = await getFromAPI(`FtsClients/?id=${selectedCustomerId}`);
            const country = response[0].country;
            const stateRespose = await getFromAPI(`FtsState/?country_code=91`);
            // Fetch customer data
            const stateCode = response[0].state; // Assuming state contains the state_code
            const state = stateRespose.find(state => state.state_code == stateCode);
            let stateName = '';
            if (state) {
                stateName = state.state_name
            } else {
                console.log('State not found');
            }
            const cityDetails = await  getFromAPI(`FtsCity/?state_id=${stateCode}`)
            const cityNames = cityDetails.map(city => city.city_name);
            setCity(cityNames)

            setStateCode(stateCode)
            setCityName(response[0].city)

            // Fetch state name based on state code
        
            // Map API response to match the table data structure
            const mappedData = [
                { customerheading1: "Customer Name :", customersubtext1: response[0].customer_name, customerheading2: "Mobile Number :", customersubtext2: response[0].Mobile_Number },
                { customerheading1: "Email :", customersubtext1: response[0].email, customerheading2: "Gender :", customersubtext2: response[0].gender },
                { customerheading1: "Birthday :", customersubtext1: response[0].birthday, customerheading2: "Anniversary :", customersubtext2: response[0].anniversary },
                { customerheading1: "Address :", customersubtext1: response[0].address, customerheading2: "Referred By :", customersubtext2: response[0].referred_by },
                { customerheading1: "State :", customersubtext1: stateName, customerheading2: "City :", customersubtext2: response[0].city },
                { customerheading1: "Tax :", customersubtext1: response[0].tax, customerheading2: "GST :", customersubtext2: response[0].GST },
                { customerheading1: "PAN :", customersubtext1: response[0].pan, customerheading2: "Opening Balance :", customersubtext2: response[0].opening_balance },
                { customerheading1: "First Visit Date :", customersubtext1: "NA", customerheading2: "First Visit Branch :", customersubtext2: "NA" },
                { customerheading1: "Last Visit Date :", customersubtext1: "NA", customerheading2: "Last Visit Branch :", customersubtext2: "NA" },
                { customerheading1: "Last Appointment :", customersubtext1: "NA", customerheading2: "Total visits :", customersubtext2: "NA" },
                { customerheading1: "Total Service Spending :", customersubtext1: "NA", customerheading2: "Total Product Spending :", customersubtext2: "NA" },
                { customerheading1: "Total Membership Spending :", customersubtext1: "NA", customerheading2: "Current Active Membership :", customersubtext2: "NA" },
                { customerheading1: "Total Gift Card Spending :", customersubtext1: "NA", customerheading2: "Cashback Rs. :", customersubtext2: "NA" },
                { customerheading1: "Total Points :", customersubtext1: "NA", customerheading2: "Wallet Balance :", customersubtext2: "NA" },
                { customerheading1: "Total Referral :", customersubtext1: "NA", customerheading2: "Due Amount :", customersubtext2: "NA" },
                { customerheading1: "Bank Name :", customersubtext1: response[0].bank_name, customerheading2: "IFSC Code :", customersubtext2: response[0].ifsc_code },
                { customerheading1: "Account Number :", customersubtext1: response[0].AccountNumber, customerheading2: "Current balance :", customersubtext2: "NA" }
            ];

            setTableData(mappedData); // Update state with the mapped data
        } catch (error) {
            console.error('Error fetching customer data:', error);
        }
    };


    useEffect(() => {
        fetchCustomerData1();
        fetchCustomerData();
    }, [selectedCustomerId]);

    useEffect(() => {
        fetchCustomerData();
    }, [selectedCustomerId]);

    // Handle Delete Customer Entry
    const handleDeleteLocker = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Customer?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteCustomer(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            console.error('Error deleting Customer:', error);
            toast.error('Error deleting Customer', 'error');
        }
    };

    // Handle input specifically for Mobile Number and Email
    const handleMobileChange = (e) => {
        const { name, value } = e.target;
        // Remove spaces from the input value
        setInputData((prev) => ({
            ...prev,
            [name]: value.replace(/\s+/g, ''), // Remove all spaces
        }));
    };

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const response = await getFromAPI("FtsCountries/");
                setCountry(response); // Save country data
            } catch (error) {
                console.error('Error fetching country data:', error);
            }
        };

        fetchCountryData();
    }, []);

    useEffect(() => {
        const fetchBankData = async () => {
            try {
                const response = await getFromAPI('FtsBanks/');
                setBank(response); // Save tax data
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchBankData();
    }, []);


    useEffect(() => {
        const fetchTaxData = async () => {
            try {
                const response = await getFromAPI('FtsTaxes/');
                setTax(response); // Save tax data
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchTaxData();
    }, []);

    useEffect(() => {
        const fetchCutsomerData = async () => {
            try {
                const response = await getFromAPI('FtsClients/');
                setCustomer(response);

            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchCutsomerData();
    }, []);

    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setInputData(prevState => ({ ...prevState, country: selectedCountry }));

        try {
            const response = await getFromAPI(`FtsState/?calling_code=${selectedCountry}`);
            setState(response); // Save state data
            setCity([]); // Reset city data
        } catch (error) {
            console.error('Error fetching state data:', error);
        }
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setInputData(prevState => ({ ...prevState, state: selectedState }));

        try {
            const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
            setCity(response.map(city => city.city_name));
        } catch (error) {
            console.error('Error fetching city data:', error);
        }
    };

    // Function to extract state code and PAN from GST number
    const handleGSTChange = async (e) => {
        const gstNumber = e.target.value;
        setInputData(prevState => ({ ...prevState, GST: gstNumber }));

        if (gstNumber.length >= 12) {
            const stateCode = gstNumber.substring(0, 2); // First two digits as state code
            const panNumber = gstNumber.substring(2, 12); // Next ten digits as PAN

            try {
                // Fetch the state data using the extracted state code
                const response = await getFromAPI(`FtsState/?state_id=${stateCode}`);

                if (response.length > 0) {
                    const stateName = response[0].state_name;
                    setInputData(prevState => ({
                        ...prevState,
                        state: stateName,
                        pan: panNumber
                    }));

                    // Set the State select options based on the selected country if needed
                    setState([{
                        state_id: stateCode,
                        state_name: stateName
                    }]);
                } else {
                    // Handle case where state is not found
                    setInputData(prevState => ({
                        ...prevState,
                        state: '',
                        pan: ''
                    }));
                }
            } catch (error) {
                console.error('Error fetching state data:', error);
            }
        } else {
            // Reset state and PAN if GST number is too short
            setInputData(prevState => ({
                ...prevState,
                state: '',
                pan: ''
            }));
        }
    };

    const handleDeleteCustomer = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsClients/${itemId}/`);
            if (response.status) {
                toast.success('Customer deleted successfully', 'success');
                // Optionally, redirect or update state to reflect deletion
                setTableData([]); // Clear table data or handle as needed
            }
        } catch (error) {
            console.error('Error deleting customer:', error);
            toast.error('Customer Not Deleted', 'error');
        }

        fetchCustomerData(); // Update the customer list
    };

    const handleEdit = () => {
        setShowEditModal(true);
    };

    const handleSaveChanges = async () => {
        
        try {
            await putToAPI(`FtsClients/${selectedCustomerId}/`, inputData);
            toast.success('Customer details updated successfully', 'success');
            setShowEditModal(false);
            fetchCustomerData();
            fetchCustomerData1();
        } catch (error) {
            console.error('Error updating customer details:', error);
            toast.error('Failed to update customer details', 'error');
        }
    };

    const handleCloseModal = () => {
        setShowEditModal(false);
    };


    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Card.Title className='mb-0'>Details</Card.Title>
                <div className='d-flex'>
                    <button
                        className='btn btn-link'
                        style={{ marginRight: '0', padding: '0' }}
                        onClick={handleEdit}
                    >
                        <div className='avatar avatar-sm bg-soft-info text-info'>
                            <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                        </div>
                    </button>
                    <button
                        className='btn btn-link'
                        onClick={() => handleDeleteLocker(selectedCustomerId)} // Use a function reference
                    >
                        <div className='avatar avatar-sm bg-soft-danger text-danger'>
                            <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                        </div>
                    </button>
                </div>
            </div>

            <Table responsive className='table-lg border table-nowrap mb-0'>
                <tbody>
                    {tableData.map((data, i) => (
                        <tr key={i}>
                            <th>{data.customerheading1}</th>
                            <td>{data.customersubtext1}</td>
                            <th>{data.customerheading2}</th>
                            <td>{data.customersubtext2}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={showEditModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Customer Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='customer_name'
                                        id='customer_name'
                                        placeholder="Enter Customer Name..."
                                        value={inputData.customer_name}
                                        onChange={e => setInputData({ ...inputData, customer_name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name='email'
                                        id='email'
                                        placeholder="Enter Email Address..."
                                        value={inputData.email}
                                        onChange={e => setInputData({ ...inputData, email: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='Mobile_Number'
                                        id='Mobile_Number'
                                        placeholder="Please Enter Mobile Number..."
                                        value={inputData.Mobile_Number}
                                        onChange={handleMobileChange}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='gender'
                                        id='gender'
                                        value={inputData.gender}
                                        onChange={e => setInputData({ ...inputData, gender: e.target.value })}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Birthday</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name='birthday'
                                        id='birthday'
                                        placeholder="DD/MM/YYYY"
                                        value={inputData.birthday}
                                        onChange={e => setInputData({ ...inputData, birthday: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Anniversary</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name='anniversary'
                                        id='anniversary'
                                        placeholder="DD/MM/YYYY"
                                        value={inputData.anniversary}
                                        onChange={e => setInputData({ ...inputData, anniversary: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='address'
                                        id='address'
                                        placeholder="Enter address..."
                                        value={inputData.address}
                                        onChange={e => setInputData({ ...inputData, address: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Referred By</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='referred_by'
                                        id='referred_by'
                                        value={inputData.referred_by}
                                        onChange={e => setInputData({ ...inputData, referred_by: e.target.value })}
                                    >
                                        <option value="">None</option>
                                        {Customer.map(item => (
                                            <option key={item.id}>{item.customer_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='GST'
                                        id='GST'
                                        placeholder="Enter GST number..."
                                        value={inputData.GST}
                                        onChange={e => setInputData({ ...inputData, GST: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <Form.Group className="mb-3 custom-form-input">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                        aria-label="Country"
                                        name='country'
                                        value={inputData.country}
                                        onChange={handleCountryChange}
                                    >
                                        <option value="">Select Country</option>
                                        {country.map((c, i) => (
                                            <option key={i} value={c.country_code}>{c.short_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6} xxl={4}>
                                <Form.Group className="mb-3 custom-form-input">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        aria-label="State"
                                        name='state'
                                        value={inputData.state}
                                        onChange={handleStateChange}
                                    >
                                        <option value="">Select State</option>
                                        {State.map((s, i) => (
                                            <option key={i} value={s.state_code}>{s.state_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6} xxl={4}>
                                <Form.Group className="mb-3 custom-form-input">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select
                                        aria-label="City"
                                        name='city'
                                        value={inputData.city}
                                        onChange={e => setInputData({ ...inputData, city: e.target.value })}
                                    >
                                        <option value="">Select City</option>
                                        {city.map((c, i) => (
                                            <option key={i} value={c}>{c}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='tax'
                                        id='tax'
                                        value={inputData.tax}
                                        onChange={e => setInputData({ ...inputData, tax: e.target.value })}
                                    >
                                        <option value="">Select Tax</option>
                                        {Tax.map(item => (
                                            <option key={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='pan'
                                        id='pan'
                                        placeholder="Enter PAN number..."
                                        value={inputData.pan}
                                        onChange={e => setInputData({ ...inputData, pan: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='opening_balance'
                                        id='opening_balance'
                                        placeholder="Enter opening balance..."
                                        value={inputData.opening_balance}
                                        onChange={e => setInputData({ ...inputData, opening_balance: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='bank_name'
                                        id='bank_name'
                                        value={inputData.bank_name}
                                        onChange={e => setInputData({ ...inputData, bank_name: e.target.value })}
                                    >
                                        <option value="">Select Bank Name</option>
                                        {Bank.map(item => (
                                            <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>IFSC Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='ifsc_code'
                                        id='ifsc_code'
                                        placeholder="Enter IFSC code..."
                                        value={inputData.ifsc_code}
                                        onChange={e => setInputData({ ...inputData, ifsc_code: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='AccountNumber'
                                        id='AccountNumber'
                                        placeholder="Enter Account Number..."
                                        value={inputData.AccountNumber}
                                        onChange={e => setInputData({ ...inputData, AccountNumber: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
        </>
    );
}
