import React, { useState } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

import GSTConfigurationData from "./gst-configuration-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function ViewTax() {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // table data
    const [gstConfigurationTableData] = useState(GSTConfigurationData);

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Tax</Card.Title>
                        <div className='d-flex'>
                            <div className="btn-list">
                                <Button variant="soft-info p-0" onClick={handleShow}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                                <Button variant="soft-danger p-0">
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                            </div>
                        </div>
                    </div>
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {gstConfigurationTableData.gstConfigurationTable.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>
                                    <th>{data.tableheading2}</th>
                                    <td>{data.tablesubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-3'>Edit Tax</h5>

                    <Form>
                        <Row className='align-items-center'>
                            <Col xl={8}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter tax name...' name='taxName' id='taxName' />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <ul className='list-inline text-xl-center my-3'>
                                    <li className='list-inline-item me-4'>
                                        {['radio'].map((type) => (
                                            <div key={`simple-tax-${type}`}>
                                                <Form.Check
                                                    type={type}
                                                    name="Tax"
                                                    id={`simple-tax-check-${type}`}
                                                    label={`Simple Tax`}
                                                />
                                            </div>
                                        ))}
                                    </li>
                                    <li className='list-inline-item'>
                                        {['radio'].map((type) => (
                                            <div key={`combine-tax-${type}`}>
                                                <Form.Check
                                                    type={type}
                                                    name="Tax"
                                                    id={`combine-tax-check-${type}`}
                                                    label={`Combine Tax`}
                                                />
                                            </div>
                                        ))}
                                    </li>
                                </ul>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Rate</Form.Label>
                                    <Form.Control type="text" placeholder='Enter tax rate...' name='taxRate' id='taxRate' />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Rate 1</Form.Label>
                                    <Form.Select aria-label="Default select example" name='enterTaxRate1' id='enterTaxRate1' >
                                        <option>Enter tax rate 1</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Rate 2</Form.Label>
                                    <Form.Select aria-label="Default select example" name='enterTaxRate1' id='enterTaxRate1' >
                                        <option>Enter tax rate 2</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <ul className='list-inline mb-3'>
                                    <li className='list-inline-item me-4'>
                                        {['radio'].map((type) => (
                                            <div key={`inclusive1-${type}`}>
                                                <Form.Check
                                                    type={type}
                                                    name="Tax2"
                                                    id={`inclusive1-check-${type}`}
                                                    label={`Inclusive`}
                                                    defaultChecked={true}
                                                />
                                            </div>
                                        ))}
                                    </li>
                                    <li className='list-inline-item'>
                                        {['radio'].map((type) => (
                                            <div key={`exclusive1-${type}`}>
                                                <Form.Check
                                                    type={type}
                                                    name="Tax2"
                                                    id={`exclusive1-check-${type}`}
                                                    label={`Exclusive`}
                                                />
                                            </div>
                                        ))}
                                    </li>
                                </ul>

                                {['checkbox'].map((type) => (
                                    <div key={`default-${type}`} className="mb-3">
                                        <Form.Check
                                            type={type}
                                            id={`default-Unchecked1-${type}`}
                                            label={`Set this tax as default tax. It will used by default for business.`}
                                        />
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        <div className='text-end btn-list'>
                            <Button variant="primary" onClick={handleClose}>Update</Button>{' '}
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
