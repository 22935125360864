import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";

import AccountCategoryData from "./account-category-data.json";

// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';
import ViewAccountCategory from './ViewAccountCategory';

export default function AccountCategory() {
    // left navlink active
    useEffect(() => {
        var a = document.getElementsByClassName("left-link-item");
        for (var i = 0; i < a.length; i++) {
            a[i].onclick = function () {
                var b = document.querySelector(".left-link-item a.active");
                if (b) b.classList.remove("active");
                this.children[0].classList.add('active');
            };
        }
    }, []);
    const [accountCategoryListData] = useState(AccountCategoryData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Settings" breadcrumbLast="Account Category" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search Accounts..." name='accountsSearch' id='accountsSearch' />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-account-category-tab" data-bs-toggle="pill" data-bs-target="#pills-view-account-category" type="button" role="tab" aria-controls="pills-view-account-category" aria-selected="true">
                                                            <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Account Category
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-new-account-category-tab" data-bs-toggle="pill" data-bs-target="#pills-new-account-category" type="button" role="tab" aria-controls="pills-new-account-category" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Account Category
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {accountCategoryListData.accountCategoryList.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i}>
                                                        <Nav.Link className={i === 0 ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.accountCategoryLink}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>

                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-account-category" role="tabpanel" aria-labelledby="pills-view-account-category-tab" tabIndex="0">
                                        <ViewAccountCategory />
                                    </div>
                                    <div className="tab-pane fade" id="pills-new-account-category" role="tabpanel" aria-labelledby="pills-new-account-category-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>New Account Category</Card.Title>
                                                <Form>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Account Category Name</Form.Label>
                                                                <Form.Control type="text" placeholder='Enter account category name...' name='accountCategoryName' id='accountCategoryName' />
                                                            </div>
                                                        </Col>
                                                        <Col xl={6}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Code</Form.Label>
                                                                <Form.Control type="text" placeholder='Enter Code...' name='accountCategoryCode' id='accountCategoryCode' />
                                                            </div>
                                                        </Col>
                                                        <Col xl={6}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Account type</Form.Label>
                                                                <Form.Select aria-label="Default select example" name='accountType' id='accountType'>
                                                                    <option>Trading Account</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                </Form.Select>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <ul className='list-inline'>
                                                                <li className='list-inline-item'>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`expense-${type}`} className="mb-3">
                                                                            <Form.Check
                                                                                type={type}
                                                                                name="account-radio"
                                                                                id={`expense-check-${type}`}
                                                                                label={`Expense`}
                                                                                defaultChecked={true}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </li>
                                                                <li className='list-inline-item'>
                                                                    {['radio'].map((type) => (
                                                                        <div key={`income-${type}`} className="mb-3">
                                                                            <Form.Check
                                                                                type={type}
                                                                                name="account-radio"
                                                                                id={`income-check-${type}`}
                                                                                label={`Income`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className='text-end btn-list'>
                                                    <Button variant="primary">Add</Button>{' '}
                                                    <Button variant="secondary">Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
