import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Table, Button, Dropdown, InputGroup, DropdownButton } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiReceiptTextOutline, mdiTrashCanOutline, mdiChevronDown } from '@mdi/js';

import purchaseReturnData from "./purchase-return-table-data.json";
import addPurchaseReturnData from "./add-purchase-return-table-data.json";

export default function PurchaseReturn() {
    // table data
    const [purchaseReturnTableData] = useState(purchaseReturnData);
    const [addPurchaseReturnTableData] = useState(addPurchaseReturnData);

    // input value
    const [prNumber, setPrNumber] = useState('11');
    const onChange = event => {
        setPrNumber(event.target.value);
    }

    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatablePurchaseReturn')) {
            $('#mainDatatablePurchaseReturn').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to pdf'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatablePurchaseReturn_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatablePurchaseReturn_wrapper").classList.remove("dtl-right");
        }
    }, []);

    // Change card title
    const handleClick = event => {
        var buttonID = event.currentTarget.id;
        var button = document.getElementById(buttonID);
        var cardtitle = document.getElementById('cardTitle2');
        var buttonText = button.textContent;
        cardtitle.innerHTML = buttonText;
    };

    // const [checked, setChecked] = useState(false)

    // //toggle checkbox
    // const rowCheckbox = useRef('')
    // const toggleCheckboxes = () => {
    //     setChecked(prevState => !prevState)
    // }

    return (
        <>
            <div className="d-md-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-md-0' id="cardTitle2">View Purchase Return</Card.Title>
                <div className="custom-top-nav-tab d-flex mt-3 mt-md-0">
                    <ul className="nav nav-pills btn-list me-2" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" onClick={handleClick} data-text="View Purchase Return" id="pills-view-purchase-return-tab" data-bs-toggle="pill" data-bs-target="#pills-view-purchase-return" type="button" role="tab" aria-controls="pills-view-purchase-return" aria-selected="true">
                                <Icon path={mdiReceiptTextOutline} className="btn-icon me-1" />View Purchase Return
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={handleClick} data-text="Add Purchase Return" id="pills-add-purchase-return-tab" data-bs-toggle="pill" data-bs-target="#pills-add-purchase-return" type="button" role="tab" aria-controls="pills-add-purchase-return" aria-selected="false">
                                <Icon path={mdiPlus} className="btn-icon me-1" />Add Purchase Return
                            </button>
                        </li>
                    </ul>
                    <Form>
                        <Form.Select aria-label="Default select example" size="sm">
                            <option>All Time</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                        </Form.Select>
                    </Form>
                </div>
            </div>

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-view-purchase-return" role="tabpanel" aria-labelledby="pills-view-purchase-return-tab" tabIndex="0">
                    <Row sm={2} lg={3} xl={4} xxl={5} className='row-cols-1 justify-content-center align-items-center'>
                        <Col>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Row : 12</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Invoice Amt : 12493.30</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Taxable Amt : 10675.00</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Tax Amt : 751.80</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='border shadow-none text-center'>
                                <Card.Body>
                                    <h5 className='mb-0 fs-17 fw-medium'>Total Non Taxable Amt : 1066.50</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Table responsive bordered className='table-nowrap' id='mainDatatablePurchaseReturn'>
                        <thead className='table-light'>
                            <tr>
                                <th>Id</th>
                                <th>PR#</th>
                                <th>Return Date</th>
                                <th>Vendor Name</th>
                                <th>Total</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseReturnTableData.map((data, i) =>
                                <tr key={i}>
                                    <td>{data.purchaseReturnId}</td>
                                    <td>{data.pr}</td>
                                    <td>{data.returnDate}</td>
                                    <td>{data.vendorName}</td>
                                    <td>{data.total}</td>
                                    <td className='py-1'>
                                        <div className='btn-list d-flex'>
                                            <Button type='button' variant='light btn-sm'>View Details</Button>{' '}
                                            <Button type='button' variant='light btn-sm'>Update</Button>{' '}
                                            <Button type='button' variant='light btn-sm'>Print</Button>{' '}
                                            <Button variant="soft-danger btn-sm ms-auto"><Icon path={mdiTrashCanOutline} className="btn-icon" /></Button>{' '}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <div className="tab-pane fade" id="pills-add-purchase-return" role="tabpanel" aria-labelledby="pills-add-purchase-return-tab" tabIndex="0">
                    <Form>
                        <Row>
                            <Col sm={3} lg={1}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PR No</Form.Label>
                                    <Form.Control type="text" value={prNumber} onChange={onChange} placeholder="Enter pr no..." name='prNo' id='prNo' />
                                </div>
                            </Col>
                            <Col sm={9} lg={4}>
                                <div className="mb-3 d-flex">
                                    <div className="custom-form-input w-100">
                                        <Form.Label>Vendor Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter vendor name..." name='vendorName' id='vendorName' />
                                    </div>
                                    <Button variant="light custom-form-input-btn ms-2 mt-2">
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col lg={7}>
                                <Row>
                                    <Col md={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Vendor Tax</Form.Label>
                                            <Form.Control type="text" placeholder="Tax" name='vendorTax' id='vendorTax' />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Purchase Return Date</Form.Label>
                                            <Form.Control type="date" name='purchaseReturnDate' id='purchaseReturnDate' />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Account</Form.Label>
                                            <Form.Select aria-label="Default select example">
                                                <option>Purchase Return</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <ul className='list-inline text-md-end text-muted fs-14 mb-2'>
                        <li className='list-inline-item me-5 mb-2'>
                            GST No : <span className='text-muted-dark'>0</span>
                        </li>
                        <li className='list-inline-item mb-2'>
                            Advance Payment : <span className='text-muted-dark'>0</span>
                        </li>
                    </ul>

                    <Table responsive bordered className='table-nowrap'>
                        <thead className='table-light'>
                            <tr>
                                <th>
                                    <Form>
                                        {['checkbox'].map((type) => (
                                            <div key={`default-${type}`}>
                                                <Form.Check
                                                    type={type}
                                                    id={`purchase-return-${type}`}
                                                    // onClick={toggleCheckboxes}
                                                    // checked={checked}
                                                />
                                            </div>
                                        ))}
                                    </Form>
                                </th>
                                <th>Sr.No</th>
                                <th>Description</th>
                                <th>Unit Price</th>
                                <th>Act.Qty</th>
                                <th>F.Qty</th>
                                <th>Rtn.Qty</th>
                                <th>Disc</th>
                                <th>Tax(%)</th>
                                <th>Tax Amount</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addPurchaseReturnTableData.map((data, i) =>
                                <tr key={i}>
                                    <td>
                                        <Form>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`}>
                                                    <Form.Check

                                                        type={type}
                                                        id={`purchase-return-${type}`}
                                                        // ref={rowCheckbox}
                                                        // checked={checked}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </td>
                                    <td>{data.srNo}</td>
                                    <td>{data.desc}</td>
                                    <td>{data.unitPrice}</td>
                                    <td>{data.actQty}</td>
                                    <td>{data.fQty}</td>
                                    <td>{data.rtnQty}</td>
                                    <td>{data.disc}</td>
                                    <td>
                                        <Form.Select aria-label="Default select example">
                                            <option>None</option>
                                            <option value="1">5%</option>
                                            <option value="2">10%</option>
                                            <option value="2">15%</option>
                                            <option value="2">20%</option>
                                        </Form.Select>
                                    </td>
                                    <td>{data.taxAmount}</td>
                                    <td>{data.total}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Button variant='light btn-sm mb-3'><Icon path={mdiPlus} className="btn-icon me-1" />Add New Row</Button>

                    <ul className='list-inline text-dark fw-medium fs-16 mb-2'>
                        <li className='list-inline-item mb-2 me-5'>Total Row : 12</li>
                        <li className='list-inline-item mb-2'>Total Invoice Amt : 12493.30</li>
                    </ul>
                    <Form>
                        <Row className='row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5'>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>LR. No.</Form.Label>
                                    <Form.Control type="text" placeholder="Enter LR. No..." name='lrNo' id='lrNo' />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Courier Company Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter company name..." name='courierCompanyName' id='courierCompanyName' />
                                </div>
                            </Col>
                            <Col>
                                <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Control aria-label="Text input with dropdown button" placeholder='Enter chargs...' name='freightCharges' id='freightCharges' />
                                    <Form.Label>Freight Charges</Form.Label>
                                    <DropdownButton variant="light" title={
                                        <>
                                            None <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                        </>
                                    } id="input-group-dropdown-2" align="end">
                                        <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">50% Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Control aria-label="Text input with dropdown button" placeholder='Enter chargs...' name='otherCharges' id='otherCharges' />
                                    <Form.Label>Other Charges</Form.Label>
                                    <DropdownButton variant="light" title={
                                        <>
                                            None <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                        </>
                                    } id="input-group-dropdown-2" align="end">
                                        <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">50% Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Sender Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter sender name..." name='senderName' id='senderName' />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Packet Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter packet number..." name='packetNumber' id='packetNumber' />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Total Tax</Form.Label>
                                    <Form.Control type="text" placeholder="Enter total tax..." name='totalTax' id='totalTax' />
                                </div>
                            </Col>
                            <Col>
                                <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Control aria-label="Text input with dropdown button" placeholder='Enter chargs...' name='otherCharges' id='otherCharges' />
                                    <Form.Label>Overall Discount</Form.Label>
                                    <DropdownButton variant="light" title={
                                        <>
                                            %Off <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                        </>
                                    } id="input-group-dropdown-2" align="end">
                                        <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">50% Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Advance Payment</Form.Label>
                                    <Form.Control type="text" placeholder="Enter advance payment..." name='advancePayment' id='advancePayment' />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Amount Due</Form.Label>
                                    <Form.Control type="text" placeholder="Enter amount due..." name='amountDue' id='amountDue' />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Invoice Total</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Invoice total..." name='invoiceTotal' id='invoiceTotal' />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Total Item Discount</Form.Label>
                                    <Form.Control type="text" placeholder="Enter total item discount..." name='totalItemDiscount' id='totalItemDiscount' />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Advance Payment Amount</Form.Label>
                                    <Form.Control type="text" placeholder="Enter advance payment..." name='advancePaymentAmount' id='advancePaymentAmount' />
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Grand Total</Form.Label>
                                    <Form.Control type="text" placeholder="Enter grand total..." name='grandTotal' id='grandTotal' />
                                </div>
                            </Col>
                        </Row>
                        <Row className='align-items-end'>
                            <Col xl={5} xxl={6}>
                                <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                                    <Form.Label>Narration</Form.Label>
                                    <Form.Control type="text" name='narration' id='narration' placeholder='Enter Narration' />
                                </div>
                            </Col>
                            <Col xl={7} xxl={6} className='text-end'>
                                <div className="btn-list">
                                    <Button variant="primary">Generate & New PR</Button>{' '}
                                    <Button variant="success">Generate PR</Button>{' '}
                                    <Button variant="secondary">Cancel</Button>{' '}
                                    <Button variant="danger">Delete</Button>{' '}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
