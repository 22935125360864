import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function PageTitle(props) {
    
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div className='d-flex justify-content-between align-items-center page-title-content'>
                            <h3 className='page-title'>{props.breadcrumbLast}</h3>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">My Retail</Breadcrumb.Item>
                                {props.breadcrumb1 ? <Breadcrumb.Item>{props.breadcrumb1}</Breadcrumb.Item> : ""}
                                {props.breadcrumb2 ? <Breadcrumb.Item>{props.breadcrumb2}</Breadcrumb.Item> : ""}
                                {props.breadcrumb3 ? <Breadcrumb.Item>{props.breadcrumb3}</Breadcrumb.Item> : ""}
                                <Breadcrumb.Item active>{props.breadcrumbLast}</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
