import React from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

export default function NewAccount() {
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>Add Account</Card.Title>
                    <Form>
                        <Row>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter account name..." name='accountName' id='accountName' />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter account number..." name='accountNumber' id='accountNumber' />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select aria-label="Default select example" name='accountTax' id='accountTax'>
                                        <option>b2c</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Accounts Payable (A/P)</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                        </Form.Select>
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2">
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GST Number..." name='gstNumber' id='gstNumber' />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select aria-label="Default select example" name='state' id='state'>
                                        <option>Gujarat</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Type description..." name='accountDescription' id='accountDescription' />
                                </div>
                            </Col>
                            <Col xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control type="text" placeholder="Enter opening balance..." name='openingBalance' id='openingBalance' />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>(%)Share of Profit</Form.Label>
                                    <Form.Control type="text" placeholder="Enter share of Pprofit..." name='shareOfProfit' id='shareOfProfit' />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Across Branch</Form.Label>
                                    <Form.Select aria-label="Default select example" name='accountAcrossBranch' id='accountAcrossBranch'>
                                        <option>Yes</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Holder Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter holder name..." name='holderName' id='holderName' />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Budget</Form.Label>
                                    <Form.Control type="text" placeholder="Enter budget..." name='accountBudget' id='accountBudget' />
                                </div>
                            </Col>
                        </Row>
                        <div className="d-flex">
                            {['radio'].map((type) => (
                                <div key={`default-${type}`} className="mb-3 me-4">
                                    <Form.Check
                                        type={type}
                                        name={`accountAddCheckbox`}
                                        id={`default-check-${type}`}
                                        label={`Credit`}
                                        defaultChecked={true}
                                    />
                                </div>
                            ))}
                            {['radio'].map((type) => (
                                <div key={`default-${type}`} className="mb-3">
                                    <Form.Check
                                        type={type}
                                        name={`accountAddCheckbox`}
                                        id={`default-checked-${type}`}
                                        label={`Debit`}
                                    />
                                </div>
                            ))}
                        </div>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className="btn-list text-end">
                        <Button variant="primary" type="button">Add</Button>{' '}
                        <Button variant="secondary" type="button">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
