import React, { useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Table, Badge, Button } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiFileDocumentOutline, mdiPlus } from '@mdi/js';
import CreateChallans from './CreateChallans';

export default function Challan() {
    // Change card title
    const handleClick = event => {
        var buttonID = event.currentTarget.id;
        var button = document.getElementById(buttonID);
        var cardtitle = document.getElementById('cardTitle');
        var buttonText = button.textContent;
        cardtitle.innerHTML = buttonText;
      };

    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#maindatatableChallan')) {
            $('#maindatatableChallan').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Sales" breadcrumbLast="Challan" />

                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-sm-flex justify-content-between align-items-center mb-0'>
                                            <Card.Title className='mb-0' id="cardTitle">View Challans</Card.Title>
                                            <div className="custom-top-nav-tab d-flex mt-3 mt-sm-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" onClick={handleClick} data-text="View Challans" id="pills-view-challans-tab" data-bs-toggle="pill" data-bs-target="#pills-view-challans" type="button" role="tab" aria-controls="pills-view-challans" aria-selected="true">
                                                            <Icon path={mdiFileDocumentOutline} className="btn-icon me-1" />View Challans
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" onClick={handleClick} data-text="Create Challan" id="pills-create-challan-tab" data-bs-toggle="pill" data-bs-target="#pills-create-challan" type="button" role="tab" aria-controls="pills-create-challan" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />Create Challan
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-challans" role="tabpanel" aria-labelledby="pills-view-challans-tab" tabIndex="0">
                                        <Card>
                                            <Card.Body>
                                                <Table responsive bordered className='table-nowrap' id='maindatatableChallan'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Challans#</th>
                                                            <th>Customer</th>
                                                            <th>Date</th>
                                                            <th>Total</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>01</td>
                                                            <td>Alan L. Iniguez</td>
                                                            <td>28/12/2022</td>
                                                            <td>16</td>
                                                            <td><Badge bg="soft-success">Delivered</Badge></td>
                                                            <td className='py-1'><Button type='button' variant='light btn-sm'>View Challans</Button></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-create-challan" role="tabpanel" aria-labelledby="pills-create-challan-tab" tabIndex="0">
                                        <CreateChallans/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
