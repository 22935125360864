import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import AccountTabData from './AccountTabData';
import { Container, Row, Col, Card, Form, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";

import AccountPageData from "./account-page-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiListBoxOutline, mdiMagnify } from '@mdi/js';

export default function AccountInfoSupport() {
    // left navlink active
    useEffect(() => {
        var a = document.getElementsByClassName("left-link-item");
        for (var i = 0; i < a.length; i++) {
            a[i].onclick = function () {
                var b = document.querySelector(".left-link-item a.active");
                if (b) b.classList.remove("active");
                this.children[0].classList.add('active');
            };
        }
    }, []);
    const [AccountData] = useState(AccountPageData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Settings" breadcrumbLast="Account Info & Support" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-lg-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search account..." name='accountSearch' id='accountSearch' />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-lg-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-options-tab" data-bs-toggle="pill" data-bs-target="#pills-view-options" type="button" role="tab" aria-controls="pills-view-options" aria-selected="true">
                                                            <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Options
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-new-options-tab" data-bs-toggle="pill" data-bs-target="#pills-new-options" type="button" role="tab" aria-controls="pills-new-options" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Options
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-add-default-options-tab" data-bs-toggle="pill" data-bs-target="#pills-add-default-options" type="button" role="tab" aria-controls="pills-add-default-options" aria-selected="false">
                                                            <Icon path={mdiListBoxOutline} className="btn-icon me-1" />Add Default Options
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {AccountData.accountListData.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i}>
                                                        <Nav.Link className={i === 0 ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.acountListLink}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>
                                <AccountTabData />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
