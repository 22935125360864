import React, { useState, useEffect, useContext } from 'react';
import { Table, Card, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI } from '../../Utils/utils';
import { toast, ToastContainer } from "react-toastify";
import { CustomerContext1 } from './vendorContext';
import { useCustomer, CustomerProvider } from './getVendor'; // Import the useCustomer hook

// icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline, mdiPhoneInTalk, mdiMessageProcessing, mdiEmail } from '@mdi/js';

export default function Details() {
    const selectedCustomerId = useContext(CustomerContext1);
    const { fetchCustomerData } = useCustomer();
    const [showEditModal, setShowEditModal] = useState(false);
    const [stateName, setStateName] = useState();
    const [State, setState] = useState([]);
    //const [stateCode, setStateCode] = useState();
    const [city, setCity] = useState([]);
    const [Tax, setTax1] = useState([]);
    const [phoneShow, setPhoneShow] = useState(false);
    const [msgShow, setMsgShow] = useState(false);
    const [cityName, setCityName] = useState();
    const [emailShow, setEmailShow] = useState(false);
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array
    const [contactPerson, setContactPerson] = useState();
    const [mobileNo, setMobileNo] = useState();
    const [email, setEmail] = useState();
    const [website, setWebsite] = useState();
    const [address, setAddress] = useState();
    const [Country, setCountry] = useState();
    const [tax, setTax] = useState();
    const [gst, setGst] = useState();
    const [pan, setPan] = useState();
    const [openingBalance, setOpeningBalance] = useState();
    const [Alternate, setAlternate] = useState();
    const [bank, setBank] = useState();
    const [IFSC, setIFSC] = useState();
    const [AccNo, setAccNo] = useState();
    const [Bank, setBank1] = useState([]);
    const [country, setCountry1] = useState([]);
    const handleCloseModal = () => {
        setShowEditModal(false);
    };
    const [inputData,setInputData] = useState({
        name : '',
        company_name : null,
        phone_number1 : '',
        email : '',
        gst_number : null,
        country : null,
        state : null,
        city : null,
        tax : null,
        pan_card_number : null,
        opening_balance : null, 
        bank_branch_name : null,
        bank_branch_ifsc_code : null,
        bank_account_number : 0,
        website : null,
        phone_number2 : null,
        primary_address : null
    })

    useEffect(() => {
        const fetchCustomerDetails = async () => {
            try {
                const response = await getFromAPI(`FtsVendor/?id=${selectedCustomerId}`);
                if (response && response.length > 0) {
                    const vendorData = response[0];
                    setInputData({
                        name: vendorData.name || '',
                        company_name: vendorData.company_name || '',
                        phone_number1: vendorData.phone_number1 || '',
                        email : vendorData.email || '',
                        primary_address: vendorData.primary_address || '',
                        gst_number: vendorData.gst_number || '',
                        country: vendorData.country || '',
                        state: vendorData.state || '',
                        city: vendorData.city || '',
                        tax: vendorData.tax || '',
                        pan_card_number: vendorData.pan_card_number || '',
                        opening_balance: vendorData.opening_balance || '',
                        bank_branch_name: vendorData.bank_branch_name || '',
                        bank_branch_ifsc_code: vendorData.bank_branch_ifsc_code || '',
                        bank_account_number: vendorData.bank_account_number || '',
                        website : vendorData.website || '',
                        phone_number2 : vendorData.phone_number2 || ''
                    });
                }
            } catch (error) {
                console.error('Error fetching vendor data:', error);
            }
        };

        if (selectedCustomerId) {
            fetchCustomerDetails();
        }
    }, [selectedCustomerId]);


     // Handle input specifically for Mobile Number and Email
     const handleMobileChange = (e) => {
        const { name, value } = e.target;
        // Remove spaces from the input value
        setInputData((prev) => ({
            ...prev,
            [name]: value.replace(/\s+/g, ''), // Remove all spaces
        }));
    };

    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setInputData(prevState => ({ ...prevState, country: selectedCountry }));

        try {
            const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
            setState(response); // Save state data
            setCity([]); // Reset city data
        } catch (error) {
            console.error('Error fetching state data:', error);
        }
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setInputData(prevState => ({ ...prevState, state: selectedState }));

        try {
            const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
            setCity(response); // Save city data
        } catch (error) {
            console.error('Error fetching city data:', error);
        }
    };

    // Currently commenting this code as it was in confilicted code and it was not clear that which function has this code.

    //     if (gstNumber.length >= 12) {
    //         const stateCode = gstNumber.substring(0, 2); // First two digits as state code
    //         const panNumber = gstNumber.substring(2, 12); // Next ten digits as PAN

    //         try {
    //             // Fetch the state data using the extracted state code
    //             const response = await getFromAPI(`FtsState/?state_id=${stateCode}`);

    //             if (response.length > 0) {
    //                 const stateName = response[0].state_name;
    //                 setInputData(prevState => ({
    //                     ...prevState,
    //                     state: stateName,
    //                     pan: panNumber
    //                 }));

    //                 // Set the State select options based on the selected country if needed
    //                 setState([{
    //                     state_id: stateCode,
    //                     state_name: stateName
    //                 }]);
    //             } else {
    //                 // Handle case where state is not found
    //                 setInputData(prevState => ({
    //                     ...prevState,
    //                     state: '',
    //                     pan: ''
    //                 }));
    //             }
    //         } catch (error) {
    //             console.error('Error fetching state data:', error);
    //         }
    //     } else {
    //         // Reset state and PAN if GST number is too short
    //         setInputData(prevState => ({
    //             ...prevState,
    //             state: '',
    //             pan: ''
    //         }));
    //     }
    // };

    const handleDeleteVendor = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsVendor/${itemId}/`);
            if (response.status) {
                toast.success('Vendor deleted successfully', 'success');
                // Optionally, redirect or update state to reflect deletion
                setTableData([]); // Clear table data or handle as needed
                fetchCustomerData();
            }
        } catch (error) {
            console.error('Error deleting vendor:', error);
            toast.error('Vendor Not Deleted', 'error');
        }

        //fetchCustomerData(); // Update the customer list
    };

    // Handle Delete Customer Entry
    const handleDeleteVendor1 = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Vendor?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteVendor(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            console.error('Error deleting Customer:', error);
            toast.error('Error deleting Customer', 'error');
        }
    };

    let response = '';
    const fetchCustomerData1 = async () => {
        try {
            response = await getFromAPI(`FtsVendor/?id=${selectedCustomerId}`);
            // Fetch customer data
            const contact_name = response[0].name;
            setContactPerson(contact_name)
            const mobile = response[0].phone_number1;
            setMobileNo(mobile)
            const email = response[0].email;
            setEmail(email)
            const address = response[0].primary_address;
            setAddress(address)
            let countryCode = response[0].country;
            if(countryCode != 0) {
                const countryData = await getFromAPI(`FtsCountries/?calling_code=${countryCode}`);
                setCountry(countryData[0].short_name)
            } else setCountry("");
            const cityCode = response[0].city
            if(cityCode != 0) {
                const cityData = await getFromAPI(`FtsCity/?id=${cityCode}`);
                setCityName(cityData[0].city_name)
            } else setCityName("");
            const gst = response[0].gst_number
            setGst(gst)
            const Tax = response[0].tax
            setTax(Tax)
            const pan = response[0].pan_card_number
            setPan(pan)
            const opening_balance = response[0].opening_balance
            setOpeningBalance(opening_balance)
            const Bank_Name = response[0].bank_branch_name
            setBank(Bank_Name)
            const IFSC_CODE = response[0].bank_branch_ifsc_code
            setIFSC(IFSC_CODE)
            const accNo = response[0].bank_account_number
            setAccNo(accNo)
            const web = response[0].website
            setWebsite(web)
            const alt = response[0].phone_number2
            setAlternate(alt)
            const stCode = response[0].state; 
            if(stCode != 0) {
                const stateData = await getFromAPI(`FtsState/?state_id=${stCode}`);
                setStateName(stateData[0].state_name);
            } else setStateName("");
            // setStateCode(stateCode)

            // let stateName = '';
            // // Use a switch case to set stateName based on stateCode
            // switch (stateCode) {
            //     case "01":
            //         stateName = "Jammu and Kashmir";
            //         setStateName(stateName)
            //         break;
            //     case "02":
            //         stateName = "Himachal Pradesh";
            //         setStateName(stateName)
            //         break;
            //     case "03":
            //         stateName = "Punjab";
            //         setStateName(stateName)
            //         break;
            //     case "04":
            //         stateName = "Chandigarh";
            //         setStateName(stateName)
            //         break;
            //     case "05":
            //         stateName = "Uttarakhand";
            //         setStateName(stateName)
            //         break;
            //     case "06":
            //         stateName = "Haryana";
            //         setStateName(stateName)
            //         break;
            //     case "07":
            //         stateName = "Delhi";
            //         setStateName(stateName)
            //         break;
            //     case "8":
            //         stateName = "Rajasthan";
            //         setStateName(stateName)
            //         break;
            //     case "09":
            //         stateName = "Uttar Pradesh";
            //         setStateName(stateName)
            //         break;
            //     case "10":
            //         stateName = "Bihar";
            //         setStateName(stateName)
            //         break;
            //     case "11":
            //         stateName = "Sikkim";
            //         setStateName(stateName)
            //         break;
            //     case "12":
            //         stateName = "Arunachal Pradesh";
            //         setStateName(stateName)
            //         break;
            //     case "13":
            //         stateName = "Nagaland";
            //         setStateName(stateName)
            //         break;
            //     case "14":
            //         stateName = "Manipur";
            //         setStateName(stateName)
            //         break;
            //     case "15":
            //         stateName = "Mizoram";
            //         setStateName(stateName)
            //         break;
            //     case "16":
            //         stateName = "Tripura";
            //         setStateName(stateName)
            //         break;
            //     case "17":
            //         stateName = "Meghalaya";
            //         setStateName(stateName)
            //         break;
            //     case "18":
            //         stateName = "Assam";
            //         setStateName(stateName)
            //         break;
            //     case "19":
            //         stateName = "West Bengal";
            //         setStateName(stateName)
            //         break;
            //     case "20":
            //         stateName = "Jharkhand";
            //         setStateName(stateName)
            //         break;
            //     case "21":
            //         stateName = "Odisha";
            //         setStateName(stateName)
            //         break;
            //     case "22":
            //         stateName = "Chhattisgarh";
            //         setStateName(stateName)
            //         break;
            //     case "23":
            //         stateName = "Madhya Pradesh";
            //         setStateName(stateName)
            //         break;
            //     case "24":
            //         stateName = "Gujarat";
            //         setStateName(stateName)
            //         break;
            //     case "26":
            //         stateName = "Dadra and Nagar Haveli and Daman and Diu";
            //         setStateName(stateName)
            //         break;
            //     case "27":
            //         stateName = "Maharashtra";
            //         setStateName(stateName)
            //         break;
            //     case "28":
            //         stateName = "Andhra Pradesh (Before Division)";
            //         setStateName(stateName)
            //         break;
            //     case "29":
            //         stateName = "Karnataka";
            //         setStateName(stateName)
            //         break;
            //     case "30":
            //         stateName = "Goa";
            //         setStateName(stateName)
            //         break;
            //     case "31":
            //         stateName = "Lakshadweep";
            //         setStateName(stateName)
            //         break;
            //     case "32":
            //         stateName = "Kerala";
            //         setStateName(stateName)
            //         break;
            //     case "33":
            //         stateName = "Tamil Nadu";
            //         setStateName(stateName)
            //         break;
            //     case "34":
            //         stateName = "Puducherry";
            //         setStateName(stateName)
            //         break;
            //     case "35":
            //         stateName = "Andaman and Nicobar Islands";
            //         setStateName(stateName)
            //         break;
            //     case "36":
            //         stateName = "Telangana";
            //         setStateName(stateName)
            //         break;
            //     case "37":
            //         stateName = "Andhra Pradesh (Newly Added)";
            //         setStateName(stateName)
            //         break;
            //     case "38":
            //         stateName = "Ladakh (Newly Added)";
            //         setStateName(stateName)
            //         break;
            //     case "97":
            //         stateName = "Other Territory";
            //         setStateName(stateName)
            //         break;
            //     case "99":
            //         stateName = "Centre Jurisdiction";
            //         setStateName(stateName)
            //         break;
            //     default:
            //         stateName = stateCode;
            //         setStateName(stateName)
            //         break;
            // }


            // Fetch state name based on state code
            // const stateResponse = await getFromAPI(`FtsState/?state_id=${stateCode}`); 
            // const stateName = stateResponse[0].state_name; // Assuming state_name is the field in the response
        } catch (error) {
            console.error('Error fetching vendor data:', error);
        }
    };

    const handleEdit = () => {
        setShowEditModal(true);
    };

    const handleSaveChanges = async () => {
        try {
            await putToAPI(`FtsVendor/${selectedCustomerId}/`, inputData);
            toast.success('Vendor details updated successfully', 'success');
            setShowEditModal(false);
            fetchCustomerData();
            fetchCustomerData1();
        } catch (error) {
            console.error('Error updating vendor details:', error);
            toast.error('Failed to update vendor details', 'error');
        }
    };


    useEffect(() => {
        fetchCustomerData1();
        fetchCustomerData();
    }, [selectedCustomerId]);

    useEffect(() => {
        const fetchBankData = async () => {
            try {
                const response = await getFromAPI('FtsBanks/');
                setBank1(response); // Save tax data
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchBankData();
    }, []);

    useEffect(() => {
        const fetchTaxData = async () => {
            try {
                const response = await getFromAPI('FtsTaxes/');
                setTax1(response); // Save tax data
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchTaxData();
    }, []);

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const response = await getFromAPI("FtsCountries/");
                setCountry1(response); // Save country data
            } catch (error) {
                console.error('Error fetching country data:', error);
            }
        };

        fetchCountryData();
    }, []);

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Card.Title className='mb-0'>Creative Industries Details</Card.Title>
                <div className='d-flex'>
                    <button
                        className='btn btn-link'
                        style={{ marginRight: '0', padding: '0' }}
                        onClick={handleEdit}

                    >
                        <div className='avatar avatar-sm bg-soft-info text-info'>
                            <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                        </div>
                    </button>
                    <button
                        className='btn btn-link'
                        onClick={() => handleDeleteVendor1(selectedCustomerId)} // Use a function reference
                    >
                        <div className='avatar avatar-sm bg-soft-danger text-danger'>
                            <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                        </div>
                    </button>
                </div>
            </div>

            <Table responsive className='table-lg border table-nowrap'>
                <tbody>
                    <tr>
                        <th>Contact Person Name :</th>
                        <td>{contactPerson}</td>
                        <th>Mobile Number :</th>
                        <td>{mobileNo}
                            <Button variant="link text-muted py-0 px-3 ms-4" onClick={() => setPhoneShow(true)}>
                                <Icon path={mdiPhoneInTalk} className='icon-size-16' />
                            </Button>
                            <Button variant="link text-muted py-0 px-3" onClick={() => setMsgShow(true)}>
                                <Icon path={mdiMessageProcessing} className='icon-size-16' />
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <th>Email :</th>
                        <td>{email}
                            <Button variant="link text-muted py-0 px-3 ms-4" onClick={() => setEmailShow(true)}>
                                <Icon path={mdiEmail} className='icon-size-16' />
                            </Button>
                        </td>
                        <th>Website :</th>
                        <td>{website}</td>
                    </tr>
                    <tr>
                        <th>Address :</th>
                        <td>{address}</td>
                        <th>Country :</th>
                        <td>{Country}</td>
                    </tr>
                    <tr>
                        <th>State :</th>
                        <td>{stateName}</td>
                        <th>City :</th>
                        <td>{cityName}</td>
                    </tr>
                    <tr>
                        <th>Tax :</th>
                        <td>{tax}</td>
                        <th>GSTIN :</th>
                        <td>{gst}</td>
                    </tr>
                    <tr>
                        <th>PAN :</th>
                        <td>{pan}</td>
                        <th>Opening Balance :</th>
                        <td>{openingBalance}</td>
                    </tr>
                    <tr>
                        <th>Vendor Tax :</th>
                        <td>N/A</td>
                        <th>Tax Type :</th>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <th>Date Create :</th>
                        <td>N/A <span className='mx-2'>|</span></td>
                        <th>Alternate Number :</th>
                        <td>{Alternate}</td>
                    </tr>
                    <tr>
                        <th>Bank Name :</th>
                        <td>{bank}</td>
                        <th>IFSC Code :</th>
                        <td>{IFSC}</td>
                    </tr>
                    <tr>
                        <th>Account Number :</th>
                        <td>{AccNo}</td>
                        <th>Current balance :</th>
                        <td>N/A</td>
                    </tr>
                </tbody>
            </Table>

            {/* Phone Modal */}
            <Modal show={phoneShow} onHide={() => setPhoneShow(false)} centered>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='avatar avatar-xxxl bg-soft-success mx-auto text-success mx-auto rounded-circle mx-auto mt-4'>
                        <Icon path={mdiPhoneInTalk} className='avatar-icon' />
                    </div>
                    <h5 className='mt-4'>{mobileNo}</h5>
                </Modal.Body>
                <Modal.Footer className='mb-4'>
                    <Button variant="success mx-auto">
                        Call Now
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Phone Modal */}
            <Modal show={msgShow} onHide={() => setMsgShow(false)} centered>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='avatar avatar-xxxl bg-soft-primary mx-auto text-primary mx-auto rounded-circle mx-auto mt-4'>
                        <Icon path={mdiMessageProcessing} className='avatar-icon' />
                    </div>
                    <h5 className='mt-4'>{mobileNo}</h5>
                </Modal.Body>
                <Modal.Footer className='mb-4'>
                    <Button variant="primary mx-auto">
                        Message Send
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Email Modal */}
            <Modal show={emailShow} onHide={() => setEmailShow(false)} centered>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='avatar avatar-xxxl bg-soft-info mx-auto text-info mx-auto rounded-circle mx-auto mt-4'>
                        <Icon path={mdiEmail} className='avatar-icon' />
                    </div>
                    <h5 className='mt-4'>{email}</h5>
                </Modal.Body>
                <Modal.Footer className='mb-4'>
                    <Button variant="info mx-auto">
                        Email Send
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='companyName'
                                        id='companyName'
                                        placeholder="Enter company name..."
                                        value={inputData.company_name}
                                        onChange={e => setInputData({ ...inputData, company_name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Contact Person</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter contact person..."
                                        name='contactPerson'
                                        id='contactPerson'
                                        value={inputData.name}
                                        onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Please Enter Mobile Number..."
                                        name='phone_number1'
                                        id='phone_number1'
                                        value={inputData.phone_number1}
                                        onChange={handleMobileChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email..."
                                        name='contactPerson'
                                        id='contactPerson'
                                        value={inputData.email}
                                        onChange={e => setInputData({ ...inputData, email: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your address..."
                                        name='primary_address'
                                        id='primary_address'
                                        value={inputData.primary_address}
                                        onChange={e => setInputData({ ...inputData, primary_address: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter GST"
                                        name='gst_number'
                                        id='gst_number'
                                        value={inputData.gst_number}
                                        onChange={e => setInputData({ ...inputData, gst_number: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='country'
                                        id='country'
                                        value={inputData.country}
                                        onChange={handleCountryChange}
                                    >
                                        <option value="">Select Country</option>
                                        {country.map(item => (
                                            <option key={item.id} value={item.country_code}>{item.short_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='state'
                                        id='state'
                                        value={inputData.state}
                                        onChange={handleStateChange}
                                    >
                                        <option value="">Select State</option>
                                        {State.map(item => (
                                            <option key={item.id} value={item.state_id}>{item.state_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='city'
                                        id='city'
                                        value={inputData.city}
                                        onChange={e => setInputData({ ...inputData, city: e.target.value })}

                                    >
                                        <option value="">Select City</option>
                                        {city.map(item => (
                                            <option key={item.id} value={item.city_id}>{item.city_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='tax'
                                        id='tax'
                                        value={inputData.tax}
                                        onChange={e => setInputData({ ...inputData, tax: e.target.value })}

                                    >
                                        <option value="">Select Tax</option>
                                        {Tax.map(item => (
                                            <option key={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} >
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className='text-up'
                                        placeholder="Enter PAN number..."
                                        name='panNumber'
                                        id='panNumber'
                                        value={inputData.pan_card_number}
                                        onChange={e => setInputData({ ...inputData, pan_card_number: e.target.value })}
                                    />
                                </div>
                            </Col>
                             <Col md={6}>
                            
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter balance..."
                                        name='openingBalance'
                                        id='openingBalance'
                                        value={inputData.opening_balance}
                                        onChange={e => setInputData({ ...inputData, opening_balance: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md = {6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your website..."
                                        name='website'
                                        id='website'
                                        value={inputData.website}
                                        onChange={e => setInputData({ ...inputData, website: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter alternate number..."
                                        name='alternateNumber'
                                        id='alternateNumber'
                                        value={inputData.phone_number2}
                                        onChange={e => setInputData({ ...inputData, phone_number2: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='bankName'
                                        id='bankName'
                                        value={inputData.bank_branch_name}
                                        onChange={e => setInputData({ ...inputData, bank_branch_name: e.target.value })}

                                    >
                                        <option value="">Select Bank Name</option>
                                        {Bank.map(item => (
                                            <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>IFSC Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter IFSC code..."
                                        name='ifscCode'
                                        id='ifscCode'
                                        value={inputData.bank_branch_ifsc_code}
                                        onChange={e => setInputData({ ...inputData, bank_branch_ifsc_code: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter account number..."
                                        name='accountNumber'
                                        id='accountNumber'
                                        value={inputData.bank_account_number}
                                        onChange={e => setInputData({ ...inputData, bank_account_number: e.target.value })}

                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />

        </>
    )
}
