import { React, useState } from 'react';
// import ReactDOM from 'react-dom';
import Topbar from './Topbar';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
// import $ from "jquery";

// icon
import Icon from '@mdi/react';
import { mdiHomeVariantOutline, mdiCalendarCheckOutline, mdiAccountSearchOutline, mdiFileDocumentCheckOutline, mdiAccountGroupOutline, mdiStorefrontOutline, mdiPackageVariant, mdiTicketPercentOutline, mdiCalculatorVariantOutline, mdiDotsHorizontalCircleOutline, mdiChevronDown, mdiAccountWrenchOutline, mdiBankOutline } from '@mdi/js';

export default function TopMenu() {

    //const [dropdownActive, setDropdownActive] = useState(false);
    const [activeItem, setActiveItem] = useState(null);


    const handleClick = (event) => {
        /*let element = event.target;
        let pelement = element.parentNode.parentNode;
        const childElement = pelement.querySelector('.dropdown-toggle');
        childElement.classList.add('active');*/
        // console.log('Child element:');
        // console.log(childElement);
        // console.log("handleItemClick ==> ");
        // const componentInstance = ReactDOM.unstable_getInstanceFromNode(childElement);
        // console.log(componentInstance);
    };

    // var $a = $(".topmenu .dropdown-item.active").click(function (e) {

    //     e.preventDefault(); // just for testing...
    //     $(".active").removeClass('active');
    //     $(this).closest('.dropdown').find('.dropdown-toggle').addClass("active");
    //     console.log(this);
    //     $(this).addClass("active");

    // });
    // $a.filter('.active').trigger('click');

    // $('.dropdown-item').on('click', function (e) {
    //     $(".active").removeClass('active');
    //     $(this).closest('.dropdown').find('.dropdown-toggle').addClass("active");
    //     console.log(this);
    //     $(this).addClass("active");
    // });

    // const dropLinks = document.getElementsByClassName('dropdown-item');
    // if (dropLinks.length) {
    //     const dropLink = document.getElementsByClassName('dropdown-item active')[0];
    //     console.log(dropLink);

    //     if (dropLink) {
    //         const sb = dropLink.parentElement.parentElement.querySelectorAll('.dropdown-toggle');
    //         sb[0].classList.add('active');
    //         console.log(sb);

    //         sb[0].addEventListener("blur", () => {
    //             sb[0].classList.toggle('active');
    //         });
    //     } 
    //     else {
    //         Array.from(dropLinks).forEach((el) => {
    //             el.classList.remove("active");
    //         });
    //     }
    // }


    // var links = document.querySelectorAll(".dropdown-item");
    // if (links.length) {
    //     const dropLink = document.getElementsByClassName('dropdown-item active');
    //     console.log('dropLink  === ');
    //     console.log(dropLink[0]);
    //     if (dropLink.length) {
    //         const sb = dropLink[0].parentElement.parentElement.querySelectorAll('.dropdown-toggle');
    //         sb[0].classList.add('active');
    //         console.log('sb == ');
    //         console.log(sb);

    //         sb[0].addEventListener("blure", sb[0].classList.add('active'));
    //     } else {
    //         const dropLink1 = document.getElementsByClassName('dropdown-item');
    //         Array.prototype.forEach.call(dropLink1, function (el) {
    //             el.classList.remove("active");
    //         });
    //     }
    // }
    return (
        <>
            <div className="topbar-menu">
                <Topbar />
                <div className='topmenu'>
                    <Navbar bg="white" expand="md" className='topmenu-content'>
                        <Container id='topmenu-container'>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            {/* show-menu-xxl-up */}
                            <Navbar.Collapse className='show-menu-xxl-up' id="basic-navbar-nav">
                                <Nav className="me-auto" id='menu-nav1'>
                                    <NavLink to='/dashboard' className='nav-link'>
                                        <Icon path={mdiHomeVariantOutline} className="icon-size-22 top-navlink-icon" />
                                        Dashboard
                                    </NavLink>

                                    <Nav.Link href="#appointment">
                                        <Icon path={mdiCalendarCheckOutline} className="icon-size-22 top-navlink-icon" />
                                        Appointment
                                    </Nav.Link>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiAccountSearchOutline} className="icon-size-22 top-navlink-icon" />
                                            HRM
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="hrm-dropdown">
                                        <NavDropdown.Item href="#action/3.1">Link One</NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.2">Link Two</NavDropdown.Item>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Inventory
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/manage_purchase" className='dropdown-item'>Manage Purchase</NavLink>
                                        <NavLink to="/manage_transfer" className='dropdown-item'>Manage Transfer</NavLink>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Auth
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/sign_up" className='dropdown-item'>Sign Up</NavLink>
                                        <NavLink to="/" className='dropdown-item'>Login</NavLink>
                                    </NavDropdown>

                                    <NavLink to='/customers' className='nav-link'>
                                        <Icon path={mdiAccountGroupOutline} className="icon-size-22 top-navlink-icon" />
                                        Customers
                                    </NavLink>

                                    <NavLink to='/vendor' className='nav-link'>
                                        <Icon path={mdiStorefrontOutline} className="icon-size-22 top-navlink-icon" />
                                        Vendors
                                    </NavLink>

                                    <NavLink to='/product' className='nav-link'>
                                        <Icon path={mdiPackageVariant} className="icon-size-22 top-navlink-icon" />
                                        Product
                                    </NavLink>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiTicketPercentOutline} className="icon-size-22 top-navlink-icon" />
                                            Sales
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="services-dropdown" onSelect={() => setActiveItem('hrm')}>
                                        <NavDropdown.Item><NavLink onClick={() => setActiveItem('hrm')} to="/sales" className='dropdown-item'>Sales</NavLink></NavDropdown.Item>
                                        <NavLink onClick={handleClick} to="/sales_return" className='dropdown-item'>Sales Return</NavLink>
                                        <NavLink onClick={handleClick} to="/quote" className='dropdown-item'>Quote</NavLink>
                                        <NavLink onClick={handleClick} to="/challan" className='dropdown-item'>Challan</NavLink>
                                        <NavLink onClick={handleClick} to="/invoices" className='dropdown-item'>View Invoices</NavLink>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiCalculatorVariantOutline} className="icon-size-22 top-navlink-icon" />
                                            Accounting
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="services-dropdown">
                                        <NavLink to="/accounts" className='dropdown-item'>Accounts</NavLink>
                                        <NavLink to="/ac_reports" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/book_closure" className='dropdown-item'>Book Closure</NavLink>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiAccountWrenchOutline} className="icon-size-22 top-navlink-icon" />
                                            Services
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="services-dropdown">
                                        <NavLink to="/services" className='dropdown-item'>Service</NavLink>
                                        <NavLink to="/services_memberships" className='dropdown-item'>Services Memberships</NavLink>
                                    </NavDropdown>

                                    <NavLink to='/bank_cash' className='nav-link'>
                                        <Icon path={mdiBankOutline} className="icon-size-22 top-navlink-icon" />
                                        Bank Cash
                                    </NavLink>

                                    <NavDropdown align="end" title={
                                        <>
                                            <Icon path={mdiDotsHorizontalCircleOutline} className="icon-size-22 top-navlink-icon" />
                                            More
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="moreservices-dropdown">
                                        <NavLink to="/marketing" className='dropdown-item'>Marketing</NavLink>
                                        <NavLink to="/reports_pages" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/settings" className='dropdown-item'>Settings</NavLink>
                                        <NavLink to="/about_us" className='dropdown-item'>About Us</NavLink>
                                    </NavDropdown>
                                </Nav>

                            </Navbar.Collapse>

                            {/* show-menu-xl-up */}
                            <Navbar.Collapse className='show-menu-xl-up'>
                                <Nav className="me-auto" id='menu-nav2'>
                                    <NavLink to='/dashboard' className='nav-link'>
                                        <Icon path={mdiHomeVariantOutline} className="icon-size-22 top-navlink-icon" />
                                        Dashboard
                                    </NavLink>

                                    <Nav.Link href="#appointment">
                                        <Icon path={mdiCalendarCheckOutline} className="icon-size-22 top-navlink-icon" />
                                        Appointment
                                    </Nav.Link>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiAccountSearchOutline} className="icon-size-22 top-navlink-icon" />
                                            HRM
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="hrm-dropdown">
                                        <NavDropdown.Item href="#action/3.1">Link One</NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.2">Link Two</NavDropdown.Item>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Inventory
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/manage_purchase" className='dropdown-item'>Manage Purchase</NavLink>
                                        <NavLink to="/manage_transfer" className='dropdown-item'>Manage Transfer</NavLink>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Auth
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/sign_up" className='dropdown-item'>Sign Up</NavLink>
                                        <NavLink to="/" className='dropdown-item'>Login</NavLink>
                                    </NavDropdown>

                                    <NavLink to='/customers' className='nav-link'>
                                        <Icon path={mdiAccountGroupOutline} className="icon-size-22 top-navlink-icon" />
                                        Customers
                                    </NavLink>

                                    <NavLink to='/vendor' className='nav-link'>
                                        <Icon path={mdiStorefrontOutline} className="icon-size-22 top-navlink-icon" />
                                        Vendors
                                    </NavLink>

                                    <NavLink to='/product' className='nav-link'>
                                        <Icon path={mdiPackageVariant} className="icon-size-22 top-navlink-icon" />
                                        Product
                                    </NavLink>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiTicketPercentOutline} className="icon-size-22 top-navlink-icon" />
                                            Sales
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="services-dropdown">
                                        <NavLink to="/sales" className='dropdown-item'>Sales</NavLink>
                                        <NavLink to="/sales_return" className='dropdown-item'>Sales Return</NavLink>
                                        <NavLink to="/quote" className='dropdown-item'>Quote</NavLink>
                                        <NavLink to="/challan" className='dropdown-item'>Challan</NavLink>
                                        <NavLink to="/invoices" className='dropdown-item'>View Invoices</NavLink>
                                    </NavDropdown>

                                    <NavDropdown align="end" title={
                                        <>
                                            <Icon path={mdiDotsHorizontalCircleOutline} className="icon-size-22 top-navlink-icon" />
                                            More
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="moreservices-dropdown">
                                        <NavLink to="/accounts" className='dropdown-item'>Accounts</NavLink>
                                        <NavLink to="/ac_reports" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/book_closure" className='dropdown-item'>Book Closure</NavLink>
                                        <NavLink to="/services" className='dropdown-item'>Services</NavLink>
                                        <NavLink to="/services_memberships" className='dropdown-item'>Services Memberships</NavLink>
                                        <NavLink to="/bank_cash" className='dropdown-item'>Bank Cash</NavLink>
                                        <NavLink to="/marketing" className='dropdown-item'>Marketing</NavLink>
                                        <NavLink to="/reports_pages" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/settings" className='dropdown-item'>Settings</NavLink>
                                        <NavLink to="/about_us" className='dropdown-item'>About Us</NavLink>
                                    </NavDropdown>
                                </Nav>

                            </Navbar.Collapse>

                            {/* show-menu-lg-up */}
                            <Navbar.Collapse className='show-menu-lg-up'>
                                <Nav className="me-auto" id='menu-nav3'>
                                    <NavLink to='/dashboard' className='nav-link'>
                                        <Icon path={mdiHomeVariantOutline} className="icon-size-22 top-navlink-icon" />
                                        Dashboard
                                    </NavLink>

                                    <Nav.Link href="#appointment">
                                        <Icon path={mdiCalendarCheckOutline} className="icon-size-22 top-navlink-icon" />
                                        Appointment
                                    </Nav.Link>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiAccountSearchOutline} className="icon-size-22 top-navlink-icon" />
                                            HRM
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="hrm-dropdown">
                                        <NavDropdown.Item href="#action/3.1">Link One</NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.2">Link Two</NavDropdown.Item>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Inventory
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/manage_purchase" className='dropdown-item'>Manage Purchase</NavLink>
                                        <NavLink to="/manage_transfer" className='dropdown-item'>Manage Transfer</NavLink>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Auth
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/sign_up" className='dropdown-item'>Sign Up</NavLink>
                                        <NavLink to="/" className='dropdown-item'>Login</NavLink>
                                    </NavDropdown>

                                    <NavLink to='/customers' className='nav-link'>
                                        <Icon path={mdiAccountGroupOutline} className="icon-size-22 top-navlink-icon" />
                                        Customers
                                    </NavLink>

                                    <NavLink to='/vendor' className='nav-link'>
                                        <Icon path={mdiStorefrontOutline} className="icon-size-22 top-navlink-icon" />
                                        Vendors
                                    </NavLink>

                                    <NavLink to='/product' className='nav-link'>
                                        <Icon path={mdiPackageVariant} className="icon-size-22 top-navlink-icon" />
                                        Product
                                    </NavLink>

                                    <NavDropdown align="end" title={
                                        <>
                                            <Icon path={mdiDotsHorizontalCircleOutline} className="icon-size-22 top-navlink-icon" />
                                            More
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="moreservices-dropdown">
                                        <NavLink to="/sales" className='dropdown-item'>Sales</NavLink>
                                        <NavLink to="/sales_return" className='dropdown-item'>Sales Return</NavLink>
                                        <NavLink to="/quote" className='dropdown-item'>Quote</NavLink>
                                        <NavLink to="/challan" className='dropdown-item'>Challan</NavLink>
                                        <NavLink to="/invoices" className='dropdown-item'>View Invoices</NavLink>
                                        <NavLink to="/accounts" className='dropdown-item'>Accounts</NavLink>
                                        <NavLink to="/ac_reports" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/book_closure" className='dropdown-item'>Book Closure</NavLink>
                                        <NavLink to="/services" className='dropdown-item'>Services</NavLink>
                                        <NavLink to="/services_memberships" className='dropdown-item'>Services Memberships</NavLink>
                                        <NavLink to="/bank_cash" className='dropdown-item'>Bank Cash</NavLink>
                                        <NavLink to="/marketing" className='dropdown-item'>Marketing</NavLink>
                                        <NavLink to="/reports_pages" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/settings" className='dropdown-item'>Settings</NavLink>
                                        <NavLink to="/about_us" className='dropdown-item'>About Us</NavLink>
                                    </NavDropdown>
                                </Nav>

                            </Navbar.Collapse>

                            {/* show-menu-md-up */}
                            <Navbar.Collapse className='show-menu-md-up'>
                                <Nav className="me-auto" id='menu-nav4'>
                                    <NavLink to='/dashboard' className='nav-link'>
                                        <Icon path={mdiHomeVariantOutline} className="icon-size-22 top-navlink-icon" />
                                        Dashboard
                                    </NavLink>

                                    <Nav.Link href="#appointment">
                                        <Icon path={mdiCalendarCheckOutline} className="icon-size-22 top-navlink-icon" />
                                        Appointment
                                    </Nav.Link>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiAccountSearchOutline} className="icon-size-22 top-navlink-icon" />
                                            HRM
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="hrm-dropdown">
                                        <NavDropdown.Item href="#action/3.1">Link One</NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.2">Link Two</NavDropdown.Item>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Inventory
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/manage_purchase" className='dropdown-item'>Manage Purchase</NavLink>
                                        <NavLink to="/manage_transfer" className='dropdown-item'>Manage Transfer</NavLink>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Auth
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/sign_up" className='dropdown-item'>Sign Up</NavLink>
                                        <NavLink to="/" className='dropdown-item'>Login</NavLink>
                                    </NavDropdown>

                                    <NavLink to='/customers' className='nav-link'>
                                        <Icon path={mdiAccountGroupOutline} className="icon-size-22 top-navlink-icon" />
                                        Customers
                                    </NavLink>

                                    <NavLink to='/vendor' className='nav-link'>
                                        <Icon path={mdiStorefrontOutline} className="icon-size-22 top-navlink-icon" />
                                        Vendors
                                    </NavLink>

                                    <NavDropdown align="end" title={
                                        <>
                                            <Icon path={mdiDotsHorizontalCircleOutline} className="icon-size-22 top-navlink-icon" />
                                            More
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="moreservices-dropdown">
                                        <NavLink to="/product" className='dropdown-item'>Product</NavLink>
                                        <NavLink to="/sales" className='dropdown-item'>Sales</NavLink>
                                        <NavLink to="/sales_return" className='dropdown-item'>Sales Return</NavLink>
                                        <NavLink to="/quote" className='dropdown-item'>Quote</NavLink>
                                        <NavLink to="/challan" className='dropdown-item'>Challan</NavLink>
                                        <NavLink to="/invoices" className='dropdown-item'>View Invoices</NavLink>
                                        <NavLink to="/accounts" className='dropdown-item'>Accounts</NavLink>
                                        <NavLink to="/ac_reports" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/book_closure" className='dropdown-item'>Book Closure</NavLink>
                                        <NavLink to="/services" className='dropdown-item'>Services</NavLink>
                                        <NavLink to="/services_memberships" className='dropdown-item'>Services Memberships</NavLink>
                                        <NavLink to="/bank_cash" className='dropdown-item'>Bank Cash</NavLink>
                                        <NavLink to="/marketing" className='dropdown-item'>Marketing</NavLink>
                                        <NavLink to="/reports_pages" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/settings" className='dropdown-item'>Settings</NavLink>
                                        <NavLink to="/about_us" className='dropdown-item'>About Us</NavLink>
                                    </NavDropdown>
                                </Nav>

                            </Navbar.Collapse>

                            {/* show-menu-sm-up */}
                            <Navbar.Collapse className='show-menu-sm-up'>
                                <Nav className="me-auto" id='menu-nav5'>
                                    <NavLink to='/dashboard' className='nav-link'>
                                        <Icon path={mdiHomeVariantOutline} className="icon-size-22 top-navlink-icon" />
                                        Dashboard
                                    </NavLink>

                                    <Nav.Link href="#appointment">
                                        <Icon path={mdiCalendarCheckOutline} className="icon-size-22 top-navlink-icon" />
                                        Appointment
                                    </Nav.Link>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiAccountSearchOutline} className="icon-size-22 top-navlink-icon" />
                                            HRM
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="hrm-dropdown">
                                        <NavDropdown.Item href="#action/3.1">Link One</NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.2">Link Two</NavDropdown.Item>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Inventory
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/manage_purchase" className='dropdown-item'>Manage Purchase</NavLink>
                                        <NavLink to="/manage_transfer" className='dropdown-item'>Manage Transfer</NavLink>
                                    </NavDropdown>

                                    <NavDropdown title={
                                        <>
                                            <Icon path={mdiFileDocumentCheckOutline} className="icon-size-22 top-navlink-icon" />
                                            Auth
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="inventory-dropdown">
                                        <NavLink to="/sign_up" className='dropdown-item'>Sign Up</NavLink>
                                        <NavLink to="/" className='dropdown-item'>Login</NavLink>
                                    </NavDropdown>

                                    <NavDropdown align="end" title={
                                        <>
                                            <Icon path={mdiDotsHorizontalCircleOutline} className="icon-size-22 top-navlink-icon" />
                                            More
                                            <Icon path={mdiChevronDown} className="top-navlink-drop-arrow" />
                                        </>
                                    } id="moreservices-dropdown">
                                        <NavLink to="/customers" className='dropdown-item'>Customers</NavLink>
                                        <NavLink to="/vendor" className='dropdown-item'>Vendors</NavLink>
                                        <NavLink to="/product" className='dropdown-item'>Product</NavLink>
                                        <NavLink to="/sales" className='dropdown-item'>Sales</NavLink>
                                        <NavLink to="/sales_return" className='dropdown-item'>Sales Return</NavLink>
                                        <NavLink to="/quote" className='dropdown-item'>Quote</NavLink>
                                        <NavLink to="/challan" className='dropdown-item'>Challan</NavLink>
                                        <NavLink to="/invoices" className='dropdown-item'>View Invoices</NavLink>
                                        <NavLink to="/accounts" className='dropdown-item'>Accounts</NavLink>
                                        <NavLink to="/ac_reports" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/book_closure" className='dropdown-item'>Book Closure</NavLink>
                                        <NavLink to="/services" className='dropdown-item'>Services</NavLink>
                                        <NavLink to="/services_memberships" className='dropdown-item'>Services Memberships</NavLink>
                                        <NavLink to="/bank_cash" className='dropdown-item'>Bank Cash</NavLink>
                                        <NavLink to="/marketing" className='dropdown-item'>Marketing</NavLink>
                                        <NavLink to="/reports_pages" className='dropdown-item'>Reports</NavLink>
                                        <NavLink to="/settings" className='dropdown-item'>Settings</NavLink>
                                        <NavLink to="/about_us" className='dropdown-item'>About Us</NavLink>
                                    </NavDropdown>
                                </Nav>

                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>
        </>
    )
}
