import React, { useState } from 'react';
import { Col, Row, Form, Table, Button, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';

import TransferInvoiceTableData from "./transfer-invoice-data.json";

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiChevronDown } from '@mdi/js';

export default function TransferReturn() {
    // input value
    const [data, setData] = useState({
        ti_number: "TI#00014", branch: "BD Enterprise", freightCharges: "0", otherCharges: "0", cgst: "0", amountPaid: "0", taxableAmount: "0",
        overallDiscount: "0", advancePayment: "0", amountDue: "0", advancePaymentAmount: "0"
    })
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    // Transfer Invoice table data
    const [transferInvoiceData] = useState(TransferInvoiceTableData);
    return (
        <>
            <Row>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="text" name='ti_number' id='ti_number' value={data.ti_number} onChange={onChange} placeholder="Your Name" size="sm" />
                    </div>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="text" name='branch' id='branch' value={data.branch} onChange={onChange} placeholder="Your Name" size="sm" />
                    </div>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="text" placeholder="To Branch" size="sm" />
                    </div>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <Form.Select aria-label="Default select example" size="sm">
                        <option>Umesh bhai</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                    </Form.Select>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="text" placeholder="Ref. Number" size="sm" />
                    </div>
                </Col>
                <Col sm={6} md={4} lg={3} xl={2}>
                    <div className="mb-3">
                        <Form.Control type="date" placeholder="dd/mm/yyyy" size="sm" />
                    </div>
                </Col>
            </Row>

            <ul className='list-inline text-end mb-0'>
                <li className='list-inline-item me-5'>
                    {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                            <Form.Check
                                type={type}
                                id={`direct-transfer-${type}`}
                                label={`Direct transfer`}
                                defaultChecked={true}
                            />
                        </div>
                    ))}
                </li>
                <li className='list-inline-item'>
                    {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                            <Form.Check
                                type={type}
                                id={`check-out-${type}`}
                                label={`Check out to floor`}
                            />
                        </div>
                    ))}
                </li>
            </ul>

            <Table responsive bordered className='table-nowrap'>
                <thead className='table-light'>
                    <tr>
                        <th>
                            {['checkbox'].map((type) => (
                                <div key={`default-${type}`}>
                                    <Form.Check
                                        type={type}
                                        id={`transfer-invoice-${type}`}
                                    />
                                </div>
                            ))}
                        </th>
                        <th>Sr.No</th>
                        <th>Description</th>
                        <th>Unit Price</th>
                        <th>Qty</th>
                        <th>Disc</th>
                        <th>Tax(%)</th>
                        <th>Tax Amount</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {transferInvoiceData.map((data, i) =>
                        <tr key={i}>
                            <td>{['checkbox'].map((type) => (
                                <div key={`default-${type}`}>
                                    <Form.Check
                                        type={type}
                                        id={`transfer-invoice-${type}`}
                                    />
                                </div>
                            ))}</td>
                            <td>{data.srNo}</td>
                            <td>{data.desc}</td>
                            <td>{data.unitPrice}</td>
                            <td>{data.qty}</td>
                            <td>{data.disc}</td>
                            <td>
                                <Form.Select aria-label="Default select example">
                                    <option>None</option>
                                    <option value="1">5%</option>
                                    <option value="2">10%</option>
                                    <option value="2">15%</option>
                                    <option value="2">20%</option>
                                </Form.Select>
                            </td>
                            <td>{data.taxAmount}</td>
                            <td>{data.total}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Button variant="light btn-sm mb-4"><Icon path={mdiPlus} className="btn-icon me-1" />Add New Row</Button>{' '}

            <Form>
                <Row>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Cost Price</Form.Label>
                            <Form.Control type="text" placeholder="Enter cost price..." name='costPrice' id='costPrice' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Qty (From Branch)</Form.Label>
                            <Form.Control type="text" placeholder="Enter qty..." name='qtyFromBranch' id='qtyFromBranch' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Qty (To Branch)</Form.Label>
                            <Form.Control type="text" placeholder="Enter qty..." name='qtyToBranch' id='qtyToBranch' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>LR. No.</Form.Label>
                            <Form.Control type="text" placeholder="Enter LR. No..." name='lrNo' id='lrNo' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Courier Company Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name..." name='courierCompanyName' id='courierCompanyName' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Packet Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter Cost Price..." name='packetNumber' id='packetNumber' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Control aria-label="Text input with dropdown button" name='freightCharges' id='freightCharges' value={data.freightCharges} onChange={onChange} />
                            <Form.Label>Freight Charges</Form.Label>
                            <DropdownButton variant="light" title={
                                <>
                                    None <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                </>
                            } id="input-group-dropdown-2" align="end">
                                <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                <Dropdown.Item href="#">50% Off</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Control aria-label="Text input with dropdown button" name='otherCharges' id='otherCharges' value={data.otherCharges} onChange={onChange} />
                            <Form.Label>Other Charges</Form.Label>
                            <DropdownButton variant="light" title={
                                <>
                                    None <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                </>
                            } id="input-group-dropdown-2" align="end">
                                <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                <Dropdown.Item href="#">50% Off</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Payment Type</Form.Label>
                            <Form.Select aria-label="Default select example" name='paymentType' id='paymentType'>
                                <option>Cash</option>
                                <option value="1">Bank</option>
                                <option value="2">Online</option>
                            </Form.Select>
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Round Off</Form.Label>
                            <Form.Control type="text" placeholder="Enter round off..." name='roundOff' id='roundOff' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Amount Repaid</Form.Label>
                            <Form.Control type="text" placeholder="Enter amount repaid..." name='amountRepaid' id='amountRepaid' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>CGST</Form.Label>
                            <Form.Control type="text" placeholder="Enter Cost Price..." name='cgst' id='cgst' value={data.cgst} onChange={onChange} />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Amount Paid</Form.Label>
                            <Form.Control type="text" placeholder="Enter amount paid..." name='amountPaid' id='amountPaid' value={data.amountPaid} onChange={onChange} />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Taxable Amount</Form.Label>
                            <Form.Control type="text" placeholder="Enter amount repaid..." name='taxableAmount' id='taxableAmount' value={data.taxableAmount} onChange={onChange} />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Control aria-label="Text input with dropdown button" name='overallDiscount' id='overallDiscount' value={data.overallDiscount} onChange={onChange} />
                            <Form.Label>Overall Discount</Form.Label>
                            <DropdownButton variant="light" title={
                                <>
                                    %Off <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                </>
                            } id="input-group-dropdown-2" align="end">
                                <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                <Dropdown.Item href="#">50% Off</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Advance Payment</Form.Label>
                            <Form.Control type="text" placeholder="Enter advance payment..." name='advancePayment' id='advancePayment' value={data.advancePayment} onChange={onChange} />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Courier Company Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name..." name='courierCompanyName' id='courierCompanyName' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>SGST</Form.Label>
                            <Form.Control type="text" placeholder="Enter SGST..." name='sgst' id='sgst' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Amount Due</Form.Label>
                            <Form.Control type="text" placeholder="Enter advance payment..." name='amountDue' id='amountDue' value={data.amountDue} onChange={onChange} />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Invoice Total</Form.Label>
                            <Form.Control type="text" placeholder="Enter invoice total..." name='invoiceTotal' id='invoiceTotal' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Total Discount</Form.Label>
                            <Form.Control type="text" placeholder="Enter total discount..." name='totalDiscount' id='totalDiscount' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Advance Payment Amount</Form.Label>
                            <Form.Control type="text" placeholder="Enter advance payment amount..." name='advancePaymentAmount' id='advancePaymentAmount' value={data.advancePaymentAmount} onChange={onChange} />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Total Tax</Form.Label>
                            <Form.Control type="text" placeholder="Enter total tax..." name='totalTax' id='totalTax' />
                        </div>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <div className="mb-3 custom-form-input custom-form-input-sm">
                            <Form.Label>Grand Total</Form.Label>
                            <Form.Control type="text" placeholder="Enter grand total..." name='grandTotal' id='grandTotal' />
                        </div>
                    </Col>
                </Row>
                <Row className='align-items-end'>
                    <Col xl={5} xxl={6}>
                        <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                            <Form.Label>Narration</Form.Label>
                            <Form.Control type="text" name='narration' id='narration' placeholder='Enter Narration' />
                        </div>
                    </Col>
                    <Col xl={7} xxl={6} className='text-end'>
                        <div className="btn-list">
                            <Button variant="primary">Transfer(a)</Button>{' '}
                            <Button variant="success">Transfer and Print</Button>{' '}
                            <Button variant="secondary">Cancel</Button>{' '}
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
