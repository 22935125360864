import React, { useState } from 'react';
import { Table, Form, Button } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

const ListViewTable = ({ initialRows, columnsConfig, onAddRow, handleOnChange }) => {

  return (
    <div>
      <Table responsive bordered className='table-nowrap'>
        <thead className='table-light'>
          <tr>
            {columnsConfig.map((col, index) => (
              <th key={index}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {initialRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnsConfig.map((col, colIndex) => (
                <td key={colIndex}>
                  {col.type === 'autoIncrement' && (
                    rowIndex + 1 // Auto-incrementing serial number
                  )}
                  {col.type === 'checkbox' && (
                    <Form.Check
                      type="checkbox"
                      checked={row[col.field]}
                      onChange={(e) =>
                        handleOnChange(rowIndex, col.field, e.target.checked)
                      }
                    />
                  )}
                  {col.type === 'text' && (
                    <Form.Control
                      size="sm"
                      type="text"
                      value={row[col.field]}
                      onChange={(e) =>
                        handleOnChange(rowIndex, col.field, e.target.value)
                      }
                    />
                  )}
                  {col.type === 'number' && (
                    <Form.Control
                      size="sm"
                      type="number"
                      value={row[col.field]}
                      onChange={(e) =>
                        handleOnChange(rowIndex, col.field, e.target.value)
                      }
                    />
                  )}
                  {col.type === 'select' && (
                    <Form.Control
                      size="sm"
                      as="select"
                      value={row[col.field]}
                      onChange={(e) =>
                        handleOnChange(rowIndex, col.field, e.target.value)
                      }
                    >
                      <option value="">{col.defualt}</option>
                      {col.options.map((option, optIndex) => (
                        <option key={optIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant='light btn-sm mb-3' onClick={onAddRow}>
        <Icon path={mdiPlus} className="btn-icon me-1" />Add New Row
      </Button>
    </div>
  );
};

export default ListViewTable;
