import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table, Badge, Button, Form, Dropdown, InputGroup, DropdownButton } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import { toast } from "react-toastify";
import { CustomerProvider, useCustomer } from '../vendor/getVendor';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiReceiptTextOutline, mdiChevronDown } from '@mdi/js';

import purchaseInvoiceData from "./purchase-invoice-table-data.json";

import { postToAPI, getFromAPI, deleteFromAPI, showAlert } from '../../Utils/utils';
import ListViewTable from "../../Utils/listview";

function PurchaseInvoice() {

    const { vendor, fetchCustomerData } = useCustomer();
    const [PIData, setPIData] = useState();
    const [totalProductQuantity, setTotalProductQuantity] = useState(0);
    const [totalItemDiscount, setTotalItemtotalItemDiscount] = useState(0);
    const [vendorTax, setVendorTax] = useState("");
    // get curret date
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    const FetchPIData = () => {
        getFromAPI("FtsPurchaseOrderInvoice/").then(response => { setPIData(response) })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const initialInputData = {
        po_id: 111,
        reference_number: "",
        vendor: 0,
        pi_date: new Date(),
        invoice_date: new Date(),
        sub_total: 0.0,
        discount: 0,
        overall_discount: "",
        overall_discount_type: "",
        total_tax: 0,
        total: 0.0,
        shipping_charge: 0,
        shipping_tax_type: 0,
        shipping_tax_id: null,
        shipping_taxable_amount: "",
        shipping_tax_amount: "",
        shipping_tax_value: 0,
        total_shipping_tax: 0,
        other_charges: 0,
        other_charges_tax_type: "",
        other_tax_id: null,
        other_taxable_amount: "",
        other_tax_amount: "",
        other_charges_tax_value: "",
        total_other_charges: "",
        final_invoice_amount: 0.0,
        payment_type: "1",
        amount_paid: 0,
        amount_due: 0,
        amount_repaid: 0,
        transaction_discount: 0,
        sms_sent: 0,
        ispaid: 0,
        account_id: 0,
        po_credit_note_id: 0,
        status: 0,
        delivery: 0,
        LR_NO: "",
        courier_company: "",
        Packet_NO: "",
        receiver_name: "",
        isprinted: 0,
        isview: 0,
        poi_tax_id: 0,
        poi_tax_amount: 0,
        short_link: "",
        long_link: "",
        reference_view_number: "",
        advance_payment: 0,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        created_date_time: new Date(),
        last_modified_date_time: new Date(),
        isdeleted: 0
    }

    const [inputData, setInputData] = useState(initialInputData);

    const initialProductRow = {
        checkbox: false,
        serialNumber: '',
        description: '',
        unitPrice: '',
        bQty: 0,
        rQty: 0,
        fQty: 0,
        disc: 0,
        dropdown: '',
        taxAmount: 0,
        total: 0
    }

    const [initialProductRows, setInitialProductRows] = useState(Array(10).fill({ ...initialProductRow }));

    const ProductColumnsConfig = [
        { label: '', field: 'checkbox', type: 'checkbox' },
        { label: 'S/N', field: 'serialNumber', type: 'autoIncrement' },
        { label: 'Description', field: 'description', type: 'text' },
        { label: 'Unit Price', field: 'unitPrice', type: 'number' },
        { label: 'B.qty', field: 'bQty', type: 'number' },
        { label: 'R.qty', field: 'rQty', type: 'number' },
        { label: 'F.qty', field: 'fQty', type: 'number' },
        { label: 'Disc', field: 'disc', type: 'number' },
        {
            label: 'Tax(%)',
            field: 'dropdown',
            type: 'select',
            defualt: 'None',
            options: [
                { value: 'gst', label: 'GST' },
                { value: 'cgst', label: 'CGST' },
                { value: 'igst', label: 'IGST' }
            ]
        },
        { label: 'Tax Amount', field: 'taxAmount', type: 'number' },
        { label: 'Total', field: 'total', type: 'number' }
    ];

    const handleAddRow = () => {
        const newRow = { ...initialProductRow }
        setInitialProductRows([...initialProductRows, newRow]);
    };

    const [productsRows, setProductsRows] = useState([]);

    const handleProductChange = (index, field, value) => {
        const updatedRows = initialProductRows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [field]: value };

                // Update total when discount or other fields change
                if (field === 'disc' || field === 'unitPrice' || field === 'bQty') {
                    const discountAmount = (updatedRow.unitPrice * updatedRow.bQty * updatedRow.disc) / 100;
                    updatedRow.total = (updatedRow.unitPrice * updatedRow.bQty) - discountAmount;
                }

                // Update rQty to match bQty when bQty changes
                if (field === 'bQty') {
                    updatedRow.rQty = value;
                }

                return updatedRow;
            } else {
                return row;
            }
        });

        setInitialProductRows(updatedRows);

        // Update total quantity
        const totalProductQTY = updatedRows.reduce((acc, row) => acc + (parseFloat(row.bQty) || 0), 0);
        setTotalProductQuantity(totalProductQTY);

        // Calculate sub-total for products
        const subTotal = parseFloat(updatedRows.reduce((acc, row) => acc + (parseFloat(row.total) || 0), 0));
        // TODO: calculate final invoice amount and total after payment selection and overall discount application
        setInputData(prevState => ({ ...prevState, sub_total: subTotal, total: subTotal, final_invoice_amount: subTotal }));

        // Calculate total item discount value
        const totalDiscount = updatedRows.reduce((totalDiscount, row) => {
            const discountAmount = (row.unitPrice * row.bQty * row.disc) / 100;
            return totalDiscount + discountAmount;
        }, 0);
        setTotalItemtotalItemDiscount(totalDiscount);

        // Filter rows that have description, unit price, and at least one quantity
        const validProducts = updatedRows.filter(row =>
            row.description && row.unitPrice &&
            (row.bQty || row.rQty || row.fQty)
        );
        setProductsRows(validProducts);
    };

    const getVendorNameById = (VendorID) => {
        let ven = vendor.find(v => v.id === VendorID);
        return ven ? ven.company_name : 'Unknown Vendor';
    };

    const getVendorTaxFromVandorID = () => {
        // TODO: Get vendor tax by vendor's id
        console.log("get vendor tax");
    }

    // Change card title
    const handleClick = event => {
        var buttonID = event.currentTarget.id;
        var button = document.getElementById(buttonID);
        var cardtitle = document.getElementById('cardTitle1');
        var buttonText = button.textContent;
        cardtitle.innerHTML = buttonText;
    };

    const GeneratePINumber = async (SetNextNumber) => {
        try {
            // Temporarely Fetch the PO number from the API 
            const response = await fetch('https://myerp-backend.foogletech.com/api/create_prefix', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prefix_type: 'pi_prefix_type',
                    prefix: 'pi_prefix',
                    number_format: 'pi_number_format',
                    next_number: 'next_pi_number',
                    current_time: defaultValue,
                    setnextinvoicenumber: SetNextNumber
                }) // Send parameters in the body as JSON
            });
            const data = await response.json();

            // Extract the PO number from the response data
            const piNumber = data.data.genereted_prifix_number;
            console.log('Generated PI Number:', piNumber);
            setInputData(prevState => ({ ...prevState, reference_view_number: piNumber }))
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    }

    const handleVendorChange = (e) => {
        const vID = parseInt(e.target.value);
        setInputData(prevState => ({ ...prevState, vendor: vID }))

        let ven = vendor.find(v => v.id === vID);
        setVendorTax(ven.tax);

    };

    const validateListViewProducts = () => {
        const validProducts = initialProductRows.filter(row => row.description.trim() !== '');

        if (validProducts.length === 0) {
            toast.error('Please Enter details of atleast one product', 'error')
            return false;
        }

        for (const [index, product] of validProducts.entries()) {
            if (!product.unitPrice || isNaN(product.unitPrice) || product.unitPrice <= 0) {
                toast.error(`Row ${index + 1}: Unit Price is required and must be a positive number.`);
                return false;
            }
            if (product.bQty <= 0) {
                toast.error(`Row ${index + 1}: B. Qty must be greater than 0.`);
                return false;
            }
        }

        return true;
    };

    const handleGeneratePI = async () => {
        if (!inputData.vendor) {
            toast.error('Please Select vendor', 'error')
            return;
        }
        if (!inputData.reference_number) {
            toast.error('Please Enter Bill Number', 'error')
            return;
        }
        if(!validateListViewProducts()) return;

        // Generate PI refrence number for current invoice with setting next number in db 
        GeneratePINumber(1);

        try {
            // Insert data into table
            console.log("inputData ==> ", inputData);
            const postResponse = await postToAPI("FtsPurchaseOrderInvoice/", inputData);
            if (postResponse.status) {
                UpdateDataTable();
                setInputData(initialInputData);
                setVendorTax('');
                // Generate PI refrence number for next invoice without setting next number in db
                GeneratePINumber(0);
                setInitialProductRows(Array(10).fill({ ...initialProductRow }));
                document.getElementById('pills-view-invoice-tab').click();
                toast.success('Purchase order invoice Added Successfully');
            } else {
                toast.error('Failed to add Purchase order invoice');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    }

    const [PurchaseInvoiceTableData] = useState(purchaseInvoiceData);

    // TODO: implimentation of this function gloabally or in utility
    const getStatusFromStatusID = (id) => {
        let status, color;
        switch (id) {
            case 0:
                status = "Pending";
                color = "danger";
                break;

            case 1:
                status = "";
                color = "";
                break;

            case 2:
                status = "";
                color = "";
                break;
        }

        return [status, color];
    }

    const HandlePIDelete = (POID) => {
        try {
            showAlert('Are you sure you want to delete this Purchase invoice?', 'confirm', async (result) => {
                if (result) {
                    try {
                        const response = await deleteFromAPI(`FtsPurchaseOrderInvoice/${POID}/`);
                        if (response.status) {
                            toast.success('Purchase invoice deleted successfully', 'success');
                            FetchPIData();
                            UpdateDataTable();
                        }
                    } catch (error) {
                        console.error('Error deleting purchase invoice:', error);
                        toast.error('purchase invoice Not Deleted', 'error');
                    }
                }
            });
        } catch (error) {
            console.error('Error deleting purchase invoice:', error);
            toast.error('Error deleting purchase invoice', 'error');
        }
    }

    const UpdateDataTable = () => {
        const PIDT = $('#mainDatatablePurchaseInvoice').DataTable();
        getFromAPI("FtsPurchaseOrderInvoice/")
            .then(response => {
                setPIData(response);
                let DTData = response.map(row => [
                    row.reference_view_number,
                    row.invoice_date,
                    row.reference_number,
                    getVendorNameById(row.vendor),
                    row.total,
                    row.status,
                    row.id
                ]);
                PIDT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (PIData && !$.fn.dataTable.isDataTable('#mainDatatablePurchaseInvoice')) {
            $('#mainDatatablePurchaseInvoice').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    }
                ],
                data: PIData.map(row => [
                    row.reference_view_number,
                    row.invoice_date,
                    row.reference_number,
                    getVendorNameById(row.vendor),
                    row.total,
                    row.status,
                    row.id
                ]),
                columns: [
                    { title: "PI#" },
                    { title: "Bill Date" },
                    { title: "Bill No" },
                    { title: "Vendor Name" },
                    { title: "Total" },
                    {
                        title: "Status",
                        render: function (data, type, row) {
                            const [status, color] = getStatusFromStatusID(row[5]);
                            return (`<span class="badge bg-soft-${color}" >${status}</span>`)
                        }
                    },
                    {
                        title: "Action",
                        createdCell: function (td) {
                            // Add a class to the 'Action' column cells
                            td.classList.add('py-1');
                        },
                        render: function (data, type, row) {
                            return `
                        <button class="btn btn-light btn-sm" onClick="">View Invoice</button>
                        <button class="btn btn-light btn-sm" onClick="">Update</button>
                        <button class="btn btn-light btn-sm pi-delete" data-pi_id="${row[6]}">Delete</button>
                      `;
                        }
                    }
                ],
                autoWidth: false,
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });

            if (document.getElementsByClassName("dt-buttons").length) {
                document.getElementById("mainDatatablePurchaseInvoice_wrapper").classList.add("dtl-right");
            } else {
                document.getElementById("mainDatatablePurchaseInvoice_wrapper").classList.remove("dtl-right");
            }

            // Event delegation to handle delete action
            document.querySelector('#mainDatatablePurchaseInvoice tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('pi-delete')) {
                    const pi_id = event.target.getAttribute('data-pi_id');
                    HandlePIDelete(pi_id);
                }
            });
        }
    }, [PIData]);

    useEffect(() => {
        // Generate PI number for refrence without setting next number in db
        GeneratePINumber(0);
        fetchCustomerData();
        FetchPIData();
    }, []);

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center mb-3">
                <Card.Title className='mb-sm-0' id="cardTitle1">View Purchase Invoice</Card.Title>
                <div className="custom-top-nav-tab mt-3 mt-lg-0">
                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" onClick={handleClick} data-text="View Purchase Invoice" id="pills-view-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-view-invoice" type="button" role="tab" aria-controls="pills-view-invoice" aria-selected="true">
                                <Icon path={mdiReceiptTextOutline} className="btn-icon me-1" />View Purchase Invoice
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={handleClick} data-text="Add Purchase Invoice" id="pills-add-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-add-invoice" type="button" role="tab" aria-controls="pills-add-invoice" aria-selected="false">
                                <Icon path={mdiPlus} className="btn-icon me-1" />Add Purchase Invoice
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-view-invoice" role="tabpanel" aria-labelledby="pills-view-invoice-tab" tabIndex="0">
                    <Table className='table-nowrap' responsive bordered id='mainDatatablePurchaseInvoice'>
                        <thead className='table-light'>
                        </thead>
                    </Table>
                </div>
                <div className="tab-pane fade" id="pills-add-invoice" role="tabpanel" aria-labelledby="pills-add-invoice-tab" tabIndex="0">
                    <Form>
                        <Row>
                            <Col sm={3} xxl={1}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PI No</Form.Label>
                                    <Form.Control type="text" value={inputData.reference_view_number} placeholder="Enter pi no..." name='piNo' id='piNo' disabled />
                                </div>
                            </Col>
                            <Col sm={9} xxl={3}>
                                <div className="mb-3 d-flex">
                                    <div className="custom-form-input w-100">
                                        <Form.Label>Vendor Name</Form.Label>
                                        <Form.Select aria-label="Default select example" name='vendorName' id='vendorName' onChange={handleVendorChange} value={inputData.vendor} >
                                            <option>Nothing seleted</option>
                                            {vendor && vendor.map((v) => (
                                                <option key={v.id} value={v.id}>
                                                    {v.company_name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                    <Button variant="light custom-form-input-btn ms-2 mt-2">
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col sm={6} lg={3} xxl={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Vendor Tax</Form.Label>
                                    <Form.Control type="text" value={vendorTax} placeholder="Tax" name='vendorTax' id='vendorTax' disabled />
                                </div>
                            </Col>
                            <Col sm={6} lg={3} xxl={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bill No</Form.Label>
                                    <Form.Control type="text" value={inputData.reference_number} placeholder="Receipt no" name='billNo' id='billNo' onChange={e => setInputData({ ...inputData, reference_number: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} lg={3} xxl={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bill Date</Form.Label>
                                    <Form.Control type="date" defaultValue={inputData.invoice_date} placeholder="Tax" name='billDate' id='billDate' onChange={e => setInputData({ ...inputData, invoice_date: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} lg={3} xxl={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PI Date</Form.Label>
                                    <Form.Control type="date" defaultValue={inputData.pi_date} placeholder="Tax" name='piDate' id='piDate' onChange={e => setInputData({ ...inputData, pi_date: e.target.value })} />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <ul className='list-inline text-md-end text-muted fs-14 mb-2'>
                        <li className='list-inline-item me-5 mb-2'>
                            GST No : <span className='text-muted-dark'>0</span>
                        </li>
                        <li className='list-inline-item mb-2'>
                            Advance Payment : <span className='text-muted-dark'>0</span>
                        </li>
                    </ul>

                    <ListViewTable
                        initialRows={initialProductRows}
                        columnsConfig={ProductColumnsConfig}
                        onAddRow={handleAddRow}
                        handleOnChange={handleProductChange}
                    />

                    <ul className='list-inline fw-medium text-dark fs-16 mb-2'>
                        <li className='list-inline-item mb-2 me-5'>Total Product : {productsRows.length}</li>
                        <li className='list-inline-item mb-2'>Total Qty : {totalProductQuantity}</li>
                    </ul>

                    <Form>
                        <Row>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>LR. No.</Form.Label>
                                    <Form.Control type="text" value={inputData.LR_NO} placeholder="Enter LR. No..." name='lrNo' id='lrNo' onChange={e => setInputData({ ...inputData, LR_NO: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Courier Company Name</Form.Label>
                                    <Form.Control type="text" value={inputData.courier_company} placeholder="Enter company name..." name='courierCompanyName' id='courierCompanyName' onChange={e => setInputData({ ...inputData, courier_company: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Control aria-label="Text input with dropdown button" value={inputData.total_shipping_tax} placeholder='Enter chargs...' name='freightCharges' id='freightCharges' onChange={e => setInputData({ ...inputData, total_shipping_tax: e.target.value })} />
                                    <Form.Label>Freight Charges</Form.Label>
                                    <DropdownButton variant="light" title={
                                        <>
                                            None <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                        </>
                                    } id="input-group-dropdown-2" align="end">
                                        <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">50% Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Control aria-label="Text input with dropdown button" value={inputData.total_other_charges} placeholder='Enter chargs...' name='otherCharges' id='otherCharges' onChange={e => setInputData({ ...inputData, total_other_charges: e.target.value })} />
                                    <Form.Label>Other Charges</Form.Label>
                                    <DropdownButton variant="light" title={
                                        <>
                                            None <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                        </>
                                    } id="input-group-dropdown-2" align="end">
                                        <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">50% Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Payment Type</Form.Label>
                                    <Form.Select aria-label="Default select example" value={inputData.payment_type} name='paymentType' id='paymentType' onChange={e => setInputData({ ...inputData, payment_type: e.target.value })} >
                                        <option>Due</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Receiver Name</Form.Label>
                                    <Form.Control type="text" value={inputData.receiver_name} placeholder="Enter LR. No..." name='receiverName' id='receiverName' onChange={e => setInputData({ ...inputData, receiver_name: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Packet Number</Form.Label>
                                    <Form.Control type="text" value={inputData.Packet_NO} placeholder="Enter packet number..." name='packetNumber' id='packetNumber' onChange={e => setInputData({ ...inputData, Packet_NO: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Amount Paid</Form.Label>
                                    <Form.Control type="text" value={inputData.amount_paid} placeholder="Enter amount paid..." name='amountPaid' id='amountPaid' onChange={e => setInputData({ ...inputData, amount_paid: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Total Tax</Form.Label>
                                    <Form.Control type="text" value={inputData.total_tax} placeholder="Enter total tax..." name='totalTax' id='totalTax' onChange={e => setInputData({ ...inputData, total_tax: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <InputGroup className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Control aria-label="Text input with dropdown button" value={inputData.overall_discount} placeholder='Enter discount...' name='overallDiscount' id='overallDiscount' onChange={e => setInputData({ ...inputData, overall_discount: e.target.value })} />
                                    <Form.Label>Overall Discount</Form.Label>
                                    <DropdownButton variant="light" title={
                                        <>
                                            %Off <Icon path={mdiChevronDown} className="drop-arrow icon-size-15" />
                                        </>
                                    } id="input-group-dropdown-2" align="end">
                                        <Dropdown.Item href="#">10% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">20% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">30% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">40% Off</Dropdown.Item>
                                        <Dropdown.Item href="#">50% Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Advance Payment</Form.Label>
                                    <Form.Control type="text" value={inputData.advance_payment} placeholder="Enter advance payment..." name='advancePayment' id='advancePayment' onChange={e => setInputData({ ...inputData, advance_payment: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Amount Due</Form.Label>
                                    <Form.Control type="text" value={inputData.amount_due} placeholder="Enter amount due..." name='amountDue' id='amountDue' onChange={e => setInputData({ ...inputData, amount_due: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Invoice Total</Form.Label>
                                    <Form.Control type="text" value={inputData.sub_total} placeholder="Enter invoice total..." name='invoiceTotal' id='invoiceTotal' disabled />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Total Item Discount</Form.Label>
                                    <Form.Control type="text" value={totalItemDiscount} placeholder="Enter total item discount..." name='totalItemDiscount' id='totalItemDiscount' disabled />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Advance Payment Amount</Form.Label>
                                    <Form.Control type="text" value={inputData.advance_payment} placeholder="Enter advance payment amount..." name='advancePaymentAmount' id='advancePaymentAmount' onChange={e => setInputData({ ...inputData, advance_payment: e.target.value })} />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Round Off</Form.Label>
                                    <Form.Control type="text" placeholder="Enter round off..." name='roundOff' id='roundOff' disabled />
                                </div>
                            </Col>
                            <Col sm={6} md={4} lg={3} xl={2}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Grand Total</Form.Label>
                                    <Form.Control type="text" value={inputData.total} placeholder="Enter grand total..." name='grandTotal' id='grandTotal' disabled />
                                </div>
                            </Col>
                        </Row>
                        <Row className='align-items-end'>
                            <Col xl={5} xxl={6}>
                                <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                                    <Form.Label>Narration</Form.Label>
                                    <Form.Control type="text" name='narration' id='narration' placeholder='Enter Narration' />
                                </div>
                            </Col>
                            <Col xl={7} xxl={6} className='text-end'>
                                <div className="btn-list">
                                    <Button variant="primary">Save & Print Barcode</Button>{' '}
                                    <Button variant="success">Generate and New</Button>{' '}
                                    <Button variant="info" onClick={handleGeneratePI}>Generate Invoice</Button>{' '}
                                    <Button variant="secondary">Cancel</Button>{' '}
                                    <Button variant="danger">Delete</Button>{' '}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default function PurchaseInvoiceWithProvider() {
    return (
        <CustomerProvider>
            <PurchaseInvoice />
        </CustomerProvider>
    );
}
