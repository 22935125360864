import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Button, Form, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import PurchaseReturnTaxTableData from "./purchase-return-tax-table-data.json";

export default function PurchaseReturnTaxSummary() {
    // datatable
    const [purchaseReturnTaxData] = useState(PurchaseReturnTaxTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs; useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatablePRTSummary')) {
            $('#mainDatatablePRTSummary').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatablePRTSummaryIR')) {
            $('#mainDatatablePRTSummaryIR').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'pdf',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M19 8C20.66 8 22 9.34 22 11V17H18V21H6V17H2V11C2 9.34 3.34 8 5 8H6V3H18V8H19M8 5V8H16V5H8M16 19V15H8V19H16M18 15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18V15M19 11.5C19 12.05 18.55 12.5 18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5Z" style="fill: currentcolor;"></path></svg> Export to Print'
                    },
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatablePRTSummaryIR_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatablePRTSummaryIR_wrapper").classList.remove("dtl-right");
        }
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Reports" breadcrumbLast="Purchase Return Tax Summary" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-0'>Purchase Return Tax Summary</Card.Title>
                                            <Button variant="light">Export to excel</Button>
                                        </div>

                                        <Form>
                                            <Row>
                                                <Col md={4} xl={3} xxl={2}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>GTS Number</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter GTS no..." name='gstNumber' id='gstNumber' />
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4} xl={3} xxl={2} className='ms-auto'>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Tax Category</Form.Label>
                                                        <Form.Select aria-label="Default select example" name='taxCategory' id='taxCategory'>
                                                            <option>All Customer</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4} xl={3} xxl={2}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Time Duration</Form.Label>
                                                        <Form.Select aria-label="Default select example" name='timeDuration' id='timeDuration'>
                                                            <option>All time</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                        <Table responsive bordered className='table-nowrap mb-0' id='mainDatatablePRTSummary'>
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>GST %</th>
                                                    <th>Taxable Amount</th>
                                                    <th>SGST</th>
                                                    <th>CGST</th>
                                                    <th>IGST</th>
                                                    <th>CESS</th>
                                                    <th>Total Tax</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {purchaseReturnTaxData.purchaseReturnData.map((data, i) =>
                                                    <tr key={i}>
                                                        <td>{data.gst}</td>
                                                        <td>{data.taxableAmount}</td>
                                                        <td>{data.sGST}</td>
                                                        <td>{data.cGST}</td>
                                                        <td>{data.iGST}</td>
                                                        <td>{data.cESS}</td>
                                                        <td>{data.totalTax}</td>
                                                        <td>{data.total}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot className='table-light'>
                                                <tr>
                                                    <th>Total:</th>
                                                    <th>7141.50</th>
                                                    <th>24.30</th>
                                                    <th>24.30</th>
                                                    <th>0.00</th>
                                                    <th>0</th>
                                                    <th>48.60</th>
                                                    <th>7190.10</th>
                                                </tr>
                                            </tfoot>
                                        </Table>

                                        <Card className='border shadow-none mb-0 mt-4'>
                                            <Card.Body>
                                                <Card.Title>Inventory Report</Card.Title>

                                                <Table responsive bordered className='table-nowrap' id='mainDatatablePRTSummaryIR'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Invoice No.</th>
                                                            <th>Date</th>
                                                            <th>Name of the Party</th>
                                                            <th>GST No.</th>
                                                            <th>Invoice Amt</th>
                                                            <th>Tax(%)</th>
                                                            <th>Taxable Amt</th>
                                                            <th>S-GST Amt</th>
                                                            <th>C-GST Amt</th>
                                                            <th>I-GST Amt</th>
                                                            <th>State</th>
                                                            <th>Tax Code</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {purchaseReturnTaxData.inventoryReportData.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.invoiceNo}</td>
                                                                <td>{data.date}</td>
                                                                <td>{data.nameParty}</td>
                                                                <td>{data.gstNo}</td>
                                                                <td>{data.invoiceAmt}</td>
                                                                <td>{data.tax}</td>
                                                                <td>{data.taxableAmt}</td>
                                                                <td>{data.sGSTAmt}</td>
                                                                <td>{data.cGSTAmt}</td>
                                                                <td>{data.iGSTAmt}</td>
                                                                <td>{data.state}</td>
                                                                <td>{data.taxCode}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
