import React, { useState, useEffect } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import ViewProduct from './ViewProduct';
import NewProduct from './NewProduct';
import ImportProduct from './ImportProduct';
import ImportBranchProduct from './ImportBranchProduct';
import MyStore from './MyStore';
import { getFromAPI } from '../../Utils/utils';
import { Container, Row, Col, Card, Form, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { productContext } from './productContext';
import { ProductProvider, useProduct } from './getProductData';

// table-data page
import ProductTableData from "./product-data.json";

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiPackageVariant, mdiPlus, mdiApplicationImport, mdiPrinterOutline } from '@mdi/js';


function Product(props) {
    const { productData, fetchproductData1 } = useProduct();
    const [selectedProductId, setSelectedProductId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');


    const myStoreDetails = event => {
        document.getElementById("view-product").classList.remove('d-none');
        document.getElementById("my-store").classList.add('d-none');
    }

    useEffect(() => {
        fetchproductData1();
    }, [selectedProductId]);

    useEffect(() => {
        fetchproductData1();
    }, []);
    // left navlink active
    useEffect(() => {
        var a = document.getElementsByClassName("left-link-item");
        for (var i = 0; i < a.length; i++) {
            a[i].onclick = function () {
                var b = document.querySelector(".left-link-item a.active");
                if (b) b.classList.remove("active");
                this.children[0].classList.add('active');
                setSelectedProductId(this.getAttribute('data-id')); // Update the selected customer ID
            };
        }
    }, [fetchproductData1]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredProductData = productData.filter(customer =>
        customer.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Left list data
    const [ProductTableListData] = useState(ProductTableData)

    return (
        <>
            <productContext.Provider value={selectedProductId}>
                <div className="page-wrapper">
                    <div className='page-content'>
                        <PageTitle breadcrumbLast="Product" />

                        <Container>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <div className='d-xl-flex justify-content-between align-items-center'>
                                                <Form className='custom-form-width'>
                                                    <div className='custom-search-bar'>
                                                        <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                        <Form.Control className=''
                                                            type="text"
                                                            placeholder="Search Product..."
                                                            name='productSearch'
                                                            id='productSearch'
                                                            onChange={handleSearchChange}
                                                            value={searchTerm}
                                                        />
                                                    </div>
                                                </Form>
                                                <div className="custom-top-nav-tab mt-3 mt-xl-0">
                                                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" onClick={myStoreDetails} id="pills-view-product-tab" data-bs-toggle="pill" data-bs-target="#pills-view-product" type="button" role="tab" aria-controls="pills-view-product" aria-selected="true">
                                                                <Icon path={mdiPackageVariant} className="btn-icon me-1" />View Product
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-new-product-tab" data-bs-toggle="pill" data-bs-target="#pills-new-product" type="button" role="tab" aria-controls="pills-new-product" aria-selected="false">
                                                                <Icon path={mdiPlus} className="btn-icon me-1" />New Product
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-import-product-tab" data-bs-toggle="pill" data-bs-target="#pills-import-product" type="button" role="tab" aria-controls="pills-import-product" aria-selected="false">
                                                                <Icon path={mdiApplicationImport} className="btn-icon me-1" />Import Product
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-import-branch-product-tab" data-bs-toggle="pill" data-bs-target="#pills-import-branch-product" type="button" role="tab" aria-controls="pills-import-branch-product" aria-selected="false">
                                                                <Icon path={mdiApplicationImport} className="btn-icon me-1" />Import Branch Product
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-print-all-barcode-tab" type="button" role="tab" aria-selected="false">
                                                                <Icon path={mdiPrinterOutline} className="btn-icon me-1" />Print All Barcode
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={4} xxl={3}>
                                    <Card className='procust-left-list leftbar-list'>
                                        <SimpleBarReact>
                                            <Card.Body>
                                                <div className='left-list-bar'>
                                                    {filteredProductData
                                                        .sort((a, b) => a.description.localeCompare(b.description)) // Sort in ascending order
                                                        .map((data, i) => (
                                                            <Nav.Item className='left-link-item' key={i} data-id={data.id}> {/* Add data-id attribute */}
                                                                <Nav.Link className={i === 0 ? "active" : ""}>
                                                                    <h5 className='product-tab-title'>{data.description}</h5>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                </div>

                                            </Card.Body>
                                        </SimpleBarReact>
                                    </Card>
                                </Col>
                                <Col lg={8} xxl={9}>
                                    <div className="tab-content" id="pills-tabContent">
                                        {/* view-product start */}
                                        <div className="tab-pane fade show active" id="pills-view-product" role="tabpanel" aria-labelledby="pills-view-product-tab" tabIndex="0">
                                            <MyStore />
                                            <ViewProduct />
                                        </div> {/* view-product end */}

                                        {/* new-product start */}
                                        <div className="tab-pane fade" id="pills-new-product" role="tabpanel" aria-labelledby="pills-new-product-tab" tabIndex="0">
                                            <NewProduct />
                                        </div> {/* new-product end */}

                                        {/* import-product start */}
                                        <div className="tab-pane fade" id="pills-import-product" role="tabpanel" aria-labelledby="pills-import-product-tab" tabIndex="0">
                                            <ImportProduct />
                                        </div> {/* import-product end */}

                                        {/* import-branch-product start */}
                                        <div className="tab-pane fade" id="pills-import-branch-product" role="tabpanel" aria-labelledby="pills-import-branch-product-tab" tabIndex="0">
                                            <ImportBranchProduct />
                                        </div> {/* import-branch-product END */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </productContext.Provider>
        </>
    )
}

export default function CustomersWithProvider() {
    return (
        <ProductProvider>
            <Product />
        </ProductProvider>
    );
}