import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import TDSCalculationReportTableData from "./tds-calculation-report-table-data.json";

export default function TDSCalculationReport() {
    // datatable
    const [tdsCalculationData] = useState(TDSCalculationReportTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableTDSCReport')) {
            $('#mainDatatableTDSCReport').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Reports" breadcrumbLast="TDS Calculation Report" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>TDS Calculation Report</Card.Title>

                                        <Form>
                                            <Row>
                                                <Col md={4} xl={3} xxl={2}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Rate Of Interest</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter rate of interest..." name='rateOfIntrest' id='rateOfIntrest' />
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4} xl={3} xxl={2} className='ms-auto'>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Select Group</Form.Label>
                                                        <Form.Select aria-label="Default select example" name='selectGroup' id='selectGroup'>
                                                            <option>Basic</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4} xl={3} xxl={2}>
                                                    <div className="mb-3 custom-form-input custom-form-input-sm">
                                                        <Form.Label>Time Duration</Form.Label>
                                                        <Form.Select aria-label="Default select example" name='timeDuration' id='timeDuration'>
                                                            <option>All time</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>

                                        <Card className='border shadow-none mb-0'>
                                            <Card.Body>
                                                <Card.Title>Inventory Report</Card.Title>

                                                <Table responsive bordered className='table-nowrap' id='mainDatatableTDSCReport'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Sr No.</th>
                                                            <th>Group</th>
                                                            <th>Name</th>
                                                            <th>Opening</th>
                                                            <th>Debit</th>
                                                            <th>Credit</th>
                                                            <th>Closing Balance</th>
                                                            <th>Rate</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tdsCalculationData.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.srNo}</td>
                                                                <td>{data.group}</td>
                                                                <td>{data.name}</td>
                                                                <td>{data.opening}</td>
                                                                <td>{data.debit}</td>
                                                                <td>{data.credit}</td>
                                                                <td>{data.closingBalance}</td>
                                                                <td>{data.rate}</td>
                                                                <td>{data.total}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
