import React, { useState } from 'react';
import { Table, Card, Modal, Form, Row, Col, Button } from 'react-bootstrap';

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

import ViewOptionsTableData from "./view-options-table-data.json";

export default function AccountTabData() {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [viewOptionsData] = useState(ViewOptionsTableData);

    // input value
    const [data, setData] = useState({
        optionsName: "Access Tickets To None Staff Members", optionsValue: "0"
    });
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    return (
        <>
            <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-view-options" role="tabpanel" aria-labelledby="pills-view-options-tab" tabIndex="0">
                    <Card className='right-content-details'>
                        <Card.Body>
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                <Card.Title className='mb-0'>View Options</Card.Title>
                                <div className='d-flex'>
                                    <div className="btn-list">
                                        <Button variant="soft-info p-0" onClick={handleShow}>
                                            <div className='avatar avatar-sm'>
                                                <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                            </div>
                                        </Button>{' '}
                                        <Button variant="soft-danger p-0">
                                            <div className='avatar avatar-sm'>
                                                <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                            </div>
                                        </Button>{' '}
                                    </div>
                                </div>
                            </div>
                            <Table responsive className='table-lg border table-nowrap mb-0'>
                                <tbody>
                                    {viewOptionsData.map((data, i) =>
                                        <tr key={i}>
                                            <th>{data.accountheading}</th>
                                            <td>{data.accountsubtext}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
                <div className="tab-pane fade" id="pills-new-options" role="tabpanel" aria-labelledby="pills-new-options-tab" tabIndex="0">
                    <Card className='right-content-details'>
                        <Card.Body>
                            <Card.Title>New Options</Card.Title>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Options Name</Form.Label>
                                            <Form.Control type="text" placeholder='Enter Options Name...' name='OptionsName1' id='OptionsName1' />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Value</Form.Label>
                                            <Form.Control type="text" placeholder='Enter Value...' name='optionsValue1' id='optionsValue1' />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                        <div className='custom-card-footer'>
                            <div className='text-end btn-list'>
                                <Button variant="primary">Add</Button>{' '}
                                <Button variant="secondary">Cancel</Button>{' '}
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="tab-pane fade" id="pills-add-default-options" role="tabpanel" aria-labelledby="pills-add-default-options-tab" tabIndex="0">
                    <Card className='right-content-details'>
                        <Card.Body>
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                <Card.Title className='mb-0'>Add Default Options</Card.Title>
                                <div className='d-flex'>
                                    <div className="btn-list">
                                        <Button variant="soft-info p-0" onClick={handleShow}>
                                            <div className='avatar avatar-sm'>
                                                <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                            </div>
                                        </Button>{' '}
                                        <Button variant="soft-danger p-0">
                                            <div className='avatar avatar-sm'>
                                                <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                            </div>
                                        </Button>{' '}
                                    </div>

                                </div>
                            </div>
                            <Table responsive className='table-lg border table-nowrap mb-0'>
                                <tbody>
                                    {viewOptionsData.map((data, i) =>
                                        <tr key={i}>
                                            <th>{data.accountheading}</th>
                                            <td>{data.accountsubtext}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-3'>Edit View Options</h5>

                    <Form>
                        <Row>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Options Name</Form.Label>
                                    <Form.Control type="text" name='optionsName' id='optionsName' value={data.optionsName} onChange={onChange} />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Value</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='optionsValue' id='optionsValue' value={data.optionsValue} onChange={onChange} />
                                </div>
                            </Col>
                        </Row>
                        <div className='text-end btn-list'>
                            <Button variant="primary" onClick={handleClose}>Update</Button>{' '}
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
