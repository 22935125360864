import React, { useState, useEffect } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import ViewAccount from './ViewAccount';
import NewAccount from './NewAccount';
import { Container, Row, Col, Card, Form, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";

import AccountData from "./view-account-data.json";

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiAccountDetails, mdiPlus } from '@mdi/js';

export default function Accounts() {
    // left navlink active
    useEffect(() => {
        var a = document.getElementsByClassName("left-link-item");
        for (var i = 0; i < a.length; i++) {
            a[i].onclick = function () {
                var b = document.querySelector(".left-link-item a.active");
                if (b) b.classList.remove("active");
                this.children[0].classList.add('active');
            };
        }
    }, []);

    const [AccountListData] = useState(AccountData);
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumbLast="Accounts" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search account..." name='accountSearch' id='accountSearch' />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-account-tab" data-bs-toggle="pill" data-bs-target="#pills-view-account" type="button" role="tab" aria-controls="pills-view-account" aria-selected="true">
                                                            <Icon path={mdiAccountDetails} className="btn-icon me-1" />View Account
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-new-account-tab" data-bs-toggle="pill" data-bs-target="#pills-new-account" type="button" role="tab" aria-controls="pills-new-account" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Account
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {AccountListData.AccountLaftListData.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i}>
                                                        <Nav.Link className={i === 0 ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.accountName}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>
                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-account" role="tabpanel" aria-labelledby="pills-view-account-tab" tabIndex="0">
                                        <ViewAccount />
                                    </div>
                                    <div className="tab-pane fade" id="pills-new-account" role="tabpanel" aria-labelledby="pills-new-account-tab" tabIndex="0">
                                        <NewAccount />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
