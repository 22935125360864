import React from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import TransferOrder from './TransferOrder';
import TransferInvoice from './TransferInvoice';
import TransferReturn from './TransferReturn';
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function ManageTransfer() {
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Inventory" breadcrumbLast="Manage Transfer " />

                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <div className='custom-top-nav-tab'>
                                            <ul className="nav nav-pills nav-fill btn-list mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="pills-transfer-order-tab" data-bs-toggle="pill" data-bs-target="#pills-transfer-order" type="button" role="tab" aria-controls="pills-transfer-order" aria-selected="true">Transfer Order</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-transfer-invoice-tab" data-bs-toggle="pill" data-bs-target="#pills-transfer-invoice" type="button" role="tab" aria-controls="pills-transfer-invoice" aria-selected="false">Transfer Invoice</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-transfer-return-tab" data-bs-toggle="pill" data-bs-target="#pills-transfer-return" type="button" role="tab" aria-controls="pills-transfer-return" aria-selected="false">Transfer Return</button>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-transfer-order" role="tabpanel" aria-labelledby="pills-transfer-order-tab" tabIndex="0">
                                                    <TransferOrder />
                                                </div>
                                                <div className="tab-pane fade" id="pills-transfer-invoice" role="tabpanel" aria-labelledby="pills-transfer-invoice-tab" tabIndex="0">
                                                    <TransferInvoice />
                                                </div>
                                                <div className="tab-pane fade" id="pills-transfer-return" role="tabpanel" aria-labelledby="pills-transfer-return-tab" tabIndex="0">
                                                    <TransferReturn />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
