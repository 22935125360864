import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Footer() {
    return (
        <>
            <div className="footer">
                <Container>
                    <Row>
                        <Col>
                            <div className="footer-content text-center">
                                <p className='mb-0 fs-16 footer-text'>{new Date().getFullYear()}@myRetail. All rights reserved</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
