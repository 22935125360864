import React, { useState } from 'react';
import { Card, Table } from 'react-bootstrap';

// view account table data
import ViewAccountTableData from "./view-account-data.json";

// icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function ViewAccount() {
    const [viewAccountData] = useState(ViewAccountTableData);
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>Accounts Payable (A/P) Details</Card.Title>
                        <div className='d-flex'>
                            <a href="/" className='me-2'>
                                <div className='avatar avatar-sm bg-soft-info text-info'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </a>
                            <a href="/">
                                <div className='avatar avatar-sm bg-soft-danger text-danger'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </a>
                        </div>
                    </div>

                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {viewAccountData.viewAccountTableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.accountheading1}</th>
                                    <td>{data.accountsubtext1}</td>
                                    <th>{data.accountheading2}</th>
                                    <td>{data.accountsubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}
