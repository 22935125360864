
var charts = {

  // invoiceChart
  invoiceChart : {
    series: [
      {
        name: 'sales',
        data: [20, 27, 16, 22, 33, 25, 38]
      }
    ],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          bottom: -6,
          left: -10,
          right: 0
        }
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '14px',
          horizontal: false,
          endingShape: "rounded",
          borderRadius: 4,
        }
      },
      xaxis: {
        categories: [
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
          "7 Jan",
        ],
        axisTicks: {
          show: false,
        }
      },
      yaxis: {
        labels: {
          maxWidth: 20,
        }
      },
      colors: ['#5570F7']
    }
  },

  // salesChart
  salesChart : {
    series: [{
      name: 'series1',
      data: [5, 18, 6, 22, 10, 26, 15, 35]
    }],
    options: {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          bottom: 0,
          left: 10,
          right: 0
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 1,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.6,
          opacityTo: 0.5,
          stops: [0, 100]
        }
      },

      xaxis: {
        type: 'datetime',
        categories: [
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
          "7 Jan",
          "8 Jan",
        ],
        axisTicks: {
          show: false,
        }
      },
      yaxis: {
        labels: {
          maxWidth: 20,
        }
      },
      colors: ['#39d6aa']
    }
  },

  // visits-chart
  visitsChart : {
    series: [
      {
        name: 'sales',
        data: [10, 16, 32, 25, 15, 22, 38]
      }
    ],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          bottom: -6,
          left: -10,
          right: 0
        }
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '14px',
          horizontal: false,
          endingShape: "rounded",
          borderRadius: 4,
        }
      },
      xaxis: {
        categories: [
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
          "7 Jan",
        ],
        axisTicks: {
          show: false,
        }
      },
      yaxis: {
        labels: {
          maxWidth: 20,
        }
      },
      colors: ['#55c4f7']
    }
  },

  // expensesChart
  expensesChart : {
    series: [{
      name: 'series1',
      data: [4, 18, 8, 22, 10, 26, 15, 35]
    }],
    options: {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          bottom: 0,
          left: 10,
          right: 0
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 1,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.6,
          opacityTo: 0.5,
          stops: [0, 100]
        }
      },

      xaxis: {
        type: 'datetime',
        categories: [
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
          "7 Jan",
          "8 Jan",
        ],
        axisTicks: {
          show: false,
        }
      },
      yaxis: {
        labels: {
          maxWidth: 20,
        }
      },
      colors: ['#dc3545']
    }
  },

  // salesReportChart
  salesReportChart : {
    series: [{
      name: "Desktops",
      data: [0, 22000, 7500, 24000, 5000, 25500, 23000, 40000, 12500, 28200, 13000, 42000]
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 16,
          left: 4,
          blur: 6,
          color: '#080D26',
          opacity: 0.1
        }
      },

      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: undefined,
      },
      grid: {
        borderColor: '#f3f3f3',
        padding: {
          top: -20,
          bottom: 0,
          left: 10,
          right: 0
        }
      },
      xaxis: {
        categories: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
      },
      yaxis: {
        labels: {
          maxWidth: 30,
          formatter: function (val, index) {
            if (val > 999) {
              return (val / 1000) + "k";
            }
            return val;
          },
        }
      },
      colors: ['#5570F7']
    }
  },

  // purchaseReportChart
  purchaseReportChart : {
    series: [{
      name: "Desktops",
      data: [0, 33000, 10000, 28000, 5000, 22000, 10000, 32500, 30000, 36000, 20000, 44000]
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 16,
          left: 4,
          blur: 6,
          color: '#080D26',
          opacity: 0.1
        }
      },

      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: undefined,
      },
      grid: {
        borderColor: '#f3f3f3',
        padding: {
          top: -20,
          bottom: 0,
          left: 10,
          right: 0
        }
      },
      xaxis: {
        categories: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
      },
      yaxis: {
        labels: {
          maxWidth: 30,
          formatter: function (val, index) {
            if (val > 999) {
              return (val / 1000) + "k";
            }
            return val;
          },
        }
      },
      colors: ['#39D6AA']
    }
  },
}

export default charts;
