import React, { useState } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

import BranchData from "./branch-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function ViewBranch() {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // table data
    const [branchTableData] = useState(BranchData);

    // input value
    const [data, setData] = useState({
        branchName: "BD Enterprise", businessName: "BD Enterprise", phoneNumber: "9601270910",
        location: "D-17 Balaji nagar society, Opp Bhagya laxmi society, Surat - 395007", gstNo: "24BDVPP0466N1ZT", panNo: "BDVPP0466N", bankName: "ICICI Bank Essar Hazira Surat",
        bankAccountNo: "178805500195", bankIFSCCode: "ICIC0001788"
    });
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Branch</Card.Title>
                        <div className='d-flex'>
                            <div className="btn-list">
                                <Button variant="soft-info p-0" onClick={handleShow}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                                <Button variant="soft-danger p-0">
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                    </div>
                                </Button>{' '}
                            </div>
                        </div>
                    </div>
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {branchTableData.brnachTable.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.accountheading1}</th>
                                    <td>{data.accountsubtext1}</td>
                                    <th>{data.accountheading2}</th>
                                    <td>{data.accountsubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>


            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-3'>Edit Branch</h5>

                    <Form>
                        <Row>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch Name</Form.Label>
                                    <Form.Control type="text" placeholder='' name='branchName' id='branchName' value={data.branchName} onChange={onChange} />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Business Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='businessName' id='businessName' value={data.businessName} onChange={onChange} />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='phoneNumber' id='phoneNumber' value={data.phoneNumber} onChange={onChange} />
                                </div>
                            </Col>
                            <Col xl={8}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='location' id='location' value={data.location} onChange={onChange} />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select aria-label="Default select example" name='country' id='country'>
                                        <option>India</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select aria-label="Default select example" name='state' id='state'>
                                        <option>Gujarat</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select aria-label="Default select example" name='city' id='city'>
                                        <option>Surat</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST No</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='gstNo' id='gstNo' value={data.gstNo} onChange={onChange} />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN No</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='panNo' id='panNo' value={data.panNo} onChange={onChange} />
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select aria-label="Default select example" name='tax' id='tax'>
                                        <option>No Tax</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Type</Form.Label>
                                    <Form.Select aria-label="Default select example" name='taxType' id='taxType'>
                                        <option>Exclusive</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch Type</Form.Label>
                                    <Form.Select aria-label="Default select example" name='branchType' id='branchType'>
                                        <option>Self</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='bankName' id='bankName' value={data.bankName} onChange={onChange} />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Account No</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='bankAccountNo' id='bankAccountNo' value={data.bankAccountNo} onChange={onChange} />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank IFSC Code</Form.Label>
                                    <Form.Control type="text" placeholder='Enter Value...' name='bankIFSCCode' id='bankIFSCCode' value={data.bankIFSCCode} onChange={onChange} />
                                </div>
                            </Col>
                        </Row>
                        <div className='text-end btn-list'>
                            <Button variant="primary" onClick={handleClose}>Update</Button>{' '}
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
