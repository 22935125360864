import React from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';

export default function ImportBranchProduct() {
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body className='pb-0'>
                    <Card.Title>Import Branch Data Of Products</Card.Title>
                    <Form.Group controlId="formFileImportBranchProducts" className="mb-3">
                        <Form.Label>Select File</Form.Label>
                        <Form.Control type="file" name='importBranchProducts' />
                    </Form.Group>
                    <Row className='justify-content-center'>
                        <Col xl={8} className="text-center">
                            <h5 className='mb-3'>Download a sample csv file that you can populate with your own data</h5>
                            <p className='text-muted lh-lg fs-14'>Use the button below to upload a csv file of your product list. Once you have selected a file to upload, your product list will be imported.
                                Duplicate products(based on product name) are not created by this process, so you can correct errors and upload the same file
                                more than once, if necessary.</p>
                        </Col>
                    </Row>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className="btn-list text-end">
                        <Button variant="primary">Download Sample</Button>{' '}
                        <Button variant="success">Import</Button>{' '}
                        <Button variant="secondary">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
