import React, { useState, useEffect } from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Table } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// react-select
import Select from 'react-select';

// table data
import SalesReturnReportListTableData from "./sales-return-report-list-table-data.json";

export default function SalesReturnReport() {
    // multi-select
    const [value, setValue] = useState(null)
    const options = [
        { value: "All Branch", label: "All Branch" },
        { value: "NSF Adajan", label: "NSF Adajan" },
        { value: "NFS Parle Point", label: "NFS Parle Point" },
        { value: "NFS Uniform", label: "NFS Uniform" },
        { value: "NFS Warehouse", label: "NFS Warehouse" },
    ]

    // datatable
    const [salesReturnReportListData] = useState(SalesReturnReportListTableData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableSRReport')) {
            $('#mainDatatableSRReport').DataTable({
                dom: "Bflrtip",
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    }
                ],
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableSRReport_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableSRReport_wrapper").classList.remove("dtl-right");
        }
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Accounting" breadcrumb2="Reports" breadcrumbLast="Sales Return Report" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-sm-flex justify-content-between align-items-center mb-3">
                                            <Card.Title className='mb-sm-0'>Sales Return Report</Card.Title>
                                            <Form>
                                                <div className='min-sm-w'></div>
                                                <Form.Select aria-label="Default select example" size="sm" name='purchaseReturnTime' id='purchaseReturnTime'>
                                                    <option>All Time</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                </Form.Select>
                                            </Form>
                                        </div>

                                        <Form>
                                            <div className="mb-3 custom-form-input">
                                                <Form.Label className='z-1'>Branch</Form.Label>
                                                <Select options={options} defaultValue={value} placeholder="Select Branch..." name='productBranch' id='productBranch' onChange={setValue} isMulti></Select>
                                            </div>
                                        </Form>

                                        <Card className='border shadow-none mb-0'>
                                            <Card.Body>
                                                <Card.Title>Sales Return Report List</Card.Title>

                                                <Table responsive bordered className='table-nowrap' id='mainDatatableSRReport'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>PR#</th>
                                                            <th>Return Date</th>
                                                            <th>Vendor Name</th>
                                                            <th>GST No</th>
                                                            <th>Invoice Amt</th>
                                                            <th>Item Amt</th>
                                                            <th>Taxable Amt</th>
                                                            <th>Tax Rate</th>
                                                            <th>CGST</th>
                                                            <th>SGST</th>
                                                            <th>IGST</th>
                                                            <th>Discount(%)</th>
                                                            <th>Discount Amt</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {salesReturnReportListData.map((data, i) =>
                                                            <tr key={i}>
                                                                <td>{data.prNumber}</td>
                                                                <td>{data.returnDate}</td>
                                                                <td>{data.vendorName}</td>
                                                                <td>{data.gstNo}</td>
                                                                <td>{data.invoiceAmt}</td>
                                                                <td>{data.itemAmt}</td>
                                                                <td>{data.taxableAmt}</td>
                                                                <td>{data.taxRate}</td>
                                                                <td>{data.cGST}</td>
                                                                <td>{data.sGST}</td>
                                                                <td>{data.iGST}</td>
                                                                <td>{data.discount}</td>
                                                                <td>{data.discountAmt}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
