import React from 'react';
import PageTitle from '../../layout/PageTitle';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

import Icon from '@mdi/react';
import { mdiEyeOffOutline } from '@mdi/js';

export default function ChangePassword() {
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumb1="Settings" breadcrumbLast="Change Password" />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Change Password</Card.Title>

                                        <Form>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Current Password</Form.Label>
                                                        <Icon path={mdiEyeOffOutline} className="icon-size-18 input-ps-icon" />
                                                        <Form.Control type="password" placeholder="Enter password..." name='currentPassword' id='currentPassword' />
                                                    </div>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>New Password</Form.Label>
                                                        <Icon path={mdiEyeOffOutline} className="icon-size-18 input-ps-icon" />
                                                        <Form.Control type="password" placeholder="Enter password..." name='newPassword' id='newPassword' />
                                                    </div>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Confirm Password</Form.Label>
                                                        <Icon path={mdiEyeOffOutline} className="icon-size-18 input-ps-icon" />
                                                        <Form.Control type="password" placeholder="Enter password..." name='confirmPassword' id='confirmPassword' />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className='text-end btn-list'>
                                                <Button variant="primary">Save</Button>{' '}
                                                <Button variant="secondary">Cancel</Button>{' '}
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
