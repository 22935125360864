import React from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';

export default function NewBranch() {
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>New Branch</Card.Title>
                    <Form>
                        <Row>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter branch name...' name='branchName' id='branchName' />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Business Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter business name...' name='businessName' id='businessName' />
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder='Enter phone number...' name='phoneNumber' id='phoneNumber' />
                                </div>
                            </Col>
                            <Col xl={8}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control type="text" placeholder='Enter location...' name='location' id='location' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select aria-label="Default select example" name='country' id='country'>
                                        <option>India</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select aria-label="Default select example" name='state' id='state'>
                                        <option>Gujarat</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select aria-label="Default select example" name='city' id='city'>
                                        <option>Surat</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST No</Form.Label>
                                    <Form.Control type="text" placeholder='Enter gst no...' name='gstNo' id='gstNo' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN No</Form.Label>
                                    <Form.Control type="text" placeholder='Enter pan no...' name='panNo' id='panNo' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select aria-label="Default select example" name='tax' id='tax'>
                                        <option>No Tax</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Type</Form.Label>
                                    <Form.Select aria-label="Default select example" name='taxType' id='taxType'>
                                        <option>Exclusive</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch Type</Form.Label>
                                    <Form.Select aria-label="Default select example" name='branchType' id='branchType'>
                                        <option>Self</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Control type="text" placeholder='Enter bank name...' name='bankName' id='bankName' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank Account No</Form.Label>
                                    <Form.Control type="text" placeholder='Enter bank account no...' name='bankAccountNo' id='bankAccountNo' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Bank IFSC Code</Form.Label>
                                    <Form.Control type="text" placeholder='Enter bank IFSC code...' name='bankIFSCCode' id='bankIFSCCode' />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className='text-end btn-list'>
                        <Button variant="primary">Add</Button>{' '}
                        <Button variant="secondary">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
