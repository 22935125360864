import React, { useState, useEffect } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// invoices data
import BenefitsData from "./benefits-data.json";

export default function Benefits() {
    // datatable
    const [benefitsTableData] = useState(BenefitsData);
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableCustomersBenefits')) {
            $('#mainDatatableCustomersBenefits').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);
    return (
        <>
            <Card.Title>Benefits</Card.Title>
            <Table responsive bordered className='table-nowrap' id='mainDatatableCustomersBenefits'>
                <thead className='table-light'>
                    <tr>
                        <th>Invoice#</th>
                        <th>Date</th>
                        <th>Item</th>
                        <th>Benefit</th>
                        <th>Amount</th>
                        <th>Payment Type</th>
                        <th>Branch Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {benefitsTableData.map((data, i) =>
                        <tr key={i}>
                            <td>{data.invoicesNo}</td>
                            <td>{data.invoicesDate}</td>
                            <td>{data.invoicesItem}</td>
                            <td>{data.invoicesBenefit}</td>
                            <td>{data.invoicesAmount}</td>
                            <td>{data.invoicesPaymentType}</td>
                            <td>{data.invoicesBranch}</td>
                            <td>
                                <Button variant="light btn-sm">View Benefits</Button>{' '}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    )
}
