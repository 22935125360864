import React from 'react';
import Logo from '../../images/logo.png'
import {Container, Row, Col, Button, Form, Dropdown} from 'react-bootstrap';

// icons
import Icon from '@mdi/react';
import { mdiMagnify, mdiCogOutline, mdiBellOutline, mdiChevronDown, mdiAccountOutline, mdiPuzzleOutline, mdiFileOutline, mdiHelpCircleOutline, mdiLogout, mdiCheck } from '@mdi/js';
// img
import userImg_1 from "../../../src/images/user-img/user_1.jpg"
import userImg_2 from "../../../src/images/user-img/user_2.jpg"
import userImg_3 from "../../../src/images/user-img/user_3.jpg"

export default function Topbar() {
  return (
    <>
      <div className='topbar'>
        <Container>
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <div className='d-flex align-items-center'>
                  <a href='/dashboard' >
                    <img src={Logo} alt="" height={36} />
                  </a>
                  {/* start top-search */}
                  <div className='top-search d-none d-md-block'>
                    <Form className='custom-form-width'>
                      <div className="top-search-input">
                        <Icon path={mdiMagnify} className="top-search-input-icon" />
                        <Form.Control type="text" placeholder="Search something..." name='topbarSearch' id='topbarSearch' />
                        <Button variant="primary top-search-input-btn" type="button">Search</Button>
                      </div>
                    </Form>
                  </div>
                  {/* end top-search */}
                </div>
                <div>
                  <ul className='list-inline d-flex align-items-center topbar-right-icons mb-0'>
                    {/* <li className='list-inline-item'>
                      <a href="/" className='top-icon'>
                        <Icon path={mdiCogOutline} className="top-icon-size" />
                      </a>
                    </li> */}

                    {/* start seach-bar */}
                    <li className='list-inline-item d-block d-md-none top-search topbar-noti-content'>
                      <Dropdown>
                        <Dropdown.Toggle variant=" p-0 top-drop-btn" id="dropdown-basic">
                          <div className='top-icon'>
                            <Icon path={mdiMagnify} className="top-icon-size" />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-xl' align="end" title="Dropdown end">

                          <div className='top-search-sm'>
                            <Form className='custom-form-width'>
                              <div className="top-search-input">
                                <Icon path={mdiMagnify} size={1} className="top-search-input-icon" />
                                <Form.Control type="text" placeholder="Search something..." />
                                <Button variant="primary top-search-input-btn" type="submit">Search</Button>
                              </div>
                            </Form>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    {/* end seach-bar */}

                    {/* start notifications */}
                    <li className='list-inline-item topbar-noti-content'>
                      <Dropdown>
                        <Dropdown.Toggle variant=" p-0 top-drop-btn" id="dropdown-basic">
                          <div className='top-icon'>
                            <span className='bell-noti'></span>
                            <Icon path={mdiBellOutline} className="top-icon-size" />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-xl pb-0' align="end" title="Dropdown end">
                          <Dropdown.Header className='d-flex justify-content-between align-items-center'>
                            <h5 className='mb-0 fs-17'>Notifications</h5>
                            <a href="/" className='text-muted'><Icon path={mdiCogOutline} className="icon-size-22" /></a>
                          </Dropdown.Header>
                          <Dropdown.Item href="#/action-1">
                            <div className='d-flex noti-item'>
                              <div className='flex-shrink-0'>
                                <img src={userImg_1} alt="" className='avatar-md rounded-circle img-thumbnail' />
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <div className='d-flex justify-content-between mb-1'>
                                  <h6 className='noti-title mb-0'>Ariane Baker</h6>
                                  <p className='noti-time mb-0 fs-12'>15 min ago</p>
                                </div>
                                <p className='noti-subtitle fs-13 mb-0'>Assigned  you on the call with rozzy.</p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <hr className='border-soft-secondary opacity-100 my-0' />
                          <Dropdown.Item href="#/action-1">
                            <div className='d-flex noti-item'>
                              <div className='flex-shrink-0'>
                                <div className='avatar avatar-md bg-soft-primary text-primary rounded-circle'>
                                  <Icon path={mdiCheck} className='avatar-icon' />
                                </div>
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <div className='d-flex justify-content-between mb-1'>
                                  <h6 className='noti-title mb-0'>Software Update</h6>
                                  <p className='noti-time mb-0 fs-12'>3 hrs ago</p>
                                </div>
                                <p className='noti-subtitle fs-13 mb-0'>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet.</p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <hr className='border-soft-secondary opacity-100 my-0' />
                          <Dropdown.Item href="#/action-1">
                            <div className='d-flex noti-item'>
                              <div className='flex-shrink-0'>
                                <img src={userImg_2} alt="" className='avatar-md rounded-circle img-thumbnail' />
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <div className='d-flex justify-content-between mb-1'>
                                  <h6 className='noti-title mb-0'>Robert L. Anderson</h6>
                                  <p className='noti-time mb-0 fs-12'>1 day ago</p>
                                </div>
                                <p className='noti-subtitle fs-13 mb-0'>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo.</p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <hr className='border-soft-secondary opacity-100 my-0' />
                          <Dropdown.Item href="#/action-1">
                            <div className='d-flex noti-item'>
                              <div className='flex-shrink-0'>
                                <img src={userImg_3} alt="" className='avatar-md rounded-circle img-thumbnail' />
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <div className='d-flex justify-content-between mb-1'>
                                  <h6 className='noti-title mb-0'>Delores A. Mosher</h6>
                                  <p className='noti-time mb-0 fs-12'>3 day ago</p>
                                </div>
                                <p className='noti-subtitle fs-13 mb-0'>Temporibus autem quibusdam et officiis.</p>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Button type='button' variant="soft-primary w-100 mt-3">View All</Button>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    {/* end notifications */}

                    {/* start user */}
                    <li className='list-inline-item topnav-user'>
                      <Dropdown>
                        <Dropdown.Toggle variant=" p-0 top-drop-btn top-user-drop-btn" id="dropdown-basic">
                          <span className='top-user-img rounded-circle'>
                            <img src={userImg_1} alt="" className='rounded-circle m-1' />
                          </span>
                          <span className='top-user-name'>
                            Josie M
                            <Icon path={mdiChevronDown} className="drop-arrow ms-1" size={0.8} />
                          </span>
                          
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="end" title="Dropdown end">
                          <Dropdown.Item href="#/action-1"><Icon path={mdiAccountOutline} className="icon-size-16 me-2" /> Profile</Dropdown.Item>
                          <Dropdown.Item href="#/action-2"><Icon path={mdiPuzzleOutline} className="icon-size-16 me-2" /> Integration</Dropdown.Item>
                          <Dropdown.Item href="#/action-3"><Icon path={mdiCogOutline} className="icon-size-16 me-2" /> Setting</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item href="#/action-4"><Icon path={mdiFileOutline} className="icon-size-16 me-2" /> Guide</Dropdown.Item>
                          <Dropdown.Item href="#/action-5"><Icon path={mdiHelpCircleOutline} className="icon-size-16 me-2" /> Help Center</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item href="#/action-6"><Icon path={mdiLogout} className="icon-size-16 me-2" /> Logout</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    {/* end user */}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
