import React, { useState } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

import ServiceCategoryData from "./service-category-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function ViewServiceCategory() {
  // Default Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // input value
  const [data, setData] = useState({
    serviceCategoryName: "Service Category", serviceDescription: "Service Category"
  });
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  // table data
  const [serviceCategoryTableData] = useState(ServiceCategoryData);
  return (
    <>
      <Card className='right-content-details'>
        <Card.Body>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <Card.Title className='mb-0'>View Service Category</Card.Title>
            <div className='d-flex'>
              <div className="btn-list">
                <Button variant="soft-info p-0" onClick={handleShow}>
                  <div className='avatar avatar-sm'>
                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                  </div>
                </Button>{' '}
                <Button variant="soft-danger p-0">
                  <div className='avatar avatar-sm'>
                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                  </div>
                </Button>{' '}
              </div>
            </div>
          </div>
          <Table responsive className='table-lg border table-nowrap mb-0'>
            <tbody>
              {serviceCategoryTableData.serviceCategoryTable.map((data, i) =>
                <tr key={i}>
                  <th>{data.tableheading1}</th>
                  <td>{data.tablesubtext1}</td>
                  <th>{data.tableheading2}</th>
                  <td>{data.tablesubtext2}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal centered show={show} size="xl" onHide={handleClose}>
        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
        <Modal.Body>
          <h5 className='mb-3'>Edit Service Category</h5>

          <Form>
            <Row>
              <Col sm={12}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Service Category Name</Form.Label>
                  <Form.Control type="text" placeholder='Enter service category Name...' name='serviceCategoryName' id='serviceCategoryName' value={data.serviceCategoryName} onChange={onChange} />
                </div>
              </Col>
              <Col sm={12}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Service Description</Form.Label>
                  <Form.Control as="textarea" placeholder='Type service description...' rows={4} name='serviceDescription' id='serviceDescription' value={data.serviceDescription} onChange={onChange} />
                </div>
              </Col>
            </Row>
            <div className='text-end btn-list'>
              <Button variant="primary" onClick={handleClose}>Update</Button>{' '}
              <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
