import React from 'react';
import { Card, Col, Container, Form, Row, Button } from 'react-bootstrap';

import Icon from '@mdi/react';
import { mdiEyeOffOutline, mdiAccountPlusOutline, mdiGoogle, mdiApple } from '@mdi/js';

// logo
import Logo from '../../images/logo.png';
import { Link } from 'react-router-dom';

export default function Login() {
    return (
        <>
            <div className="auth-page py-4 py-sm-5 ">
                <Container>
                    <Row className='justify-content-center'>
                        <Col sm={8} md={7} lg={6} xl={5} xxl={3}>
                            <img src={Logo} alt="" className='mx-auto d-block' />

                            <Card className='auth-card'>
                                <Card.Body>
                                    <Row className='justify-content-center'>
                                        <Col md={9}>
                                            <div className='text-center'>
                                                <h2 className='mb-3'>Welcome Back</h2>
                                                <p className='text-muted mb-5 fs-17'>Hey, Enter your details to get sign in to your account.</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Form>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>Email ID or User ID</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Email or User..." name='emailIDUserID' id='emailIDUserID' />
                                                </div>
                                                <div className="mb-3 custom-form-input">
                                                    <Form.Label>Password</Form.Label>
                                                    <Icon path={mdiEyeOffOutline} className="icon-size-18 input-ps-icon" />
                                                    <Form.Control type="password" placeholder="Enter password..." name='password' id='password' />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-center'>
                                                <Link to="/dashboard" className='btn btn-primary btn-lg btn-w-xxl'><Icon path={mdiAccountPlusOutline} className="btn-icon me-1" /> Login</Link>

                                                <Link href="#" className='text-muted d-block mt-4'>Forgot your password?</Link>

                                                <div className='or-text my-4'>
                                                    <p className='text-muted or-text-border'>Or sign in with</p>
                                                </div>

                                                <div className='btn-list'>
                                                    <Button variant="outline-dark icon-with-btn btn-lg"><Icon path={mdiGoogle} className="icon-size-17 me-1" /> Google</Button>{' '}
                                                    <Button variant="outline-dark icon-with-btn btn-lg"><Icon path={mdiApple} className="icon-size-17 me-1" /> Apple</Button>{' '}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>

                            <div className='text-center mt-4'>
                                <p className='mb-0 text-muted'>Don't have an account?  <Link to="/sign_up" className='text-primary fw-semibold ms-1'>Sign Up</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
