import React from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';

export default function FreeService() {
    return (
        <>
            <Card className='border shadow-none mb-0'>
                <Card.Body>
                    <Form>
                        <Row className='align-items-end'>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Service Category</Form.Label>
                                    <Form.Select aria-label="Default select example" name='serviceCategory1' id='serviceCategory1'>
                                        <option>All</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Service Name</Form.Label>
                                    <Form.Select aria-label="Default select example" name='serviceCategory1' id='serviceCategory1'>
                                        <option>Select Service</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input custom-form-input-sm">
                                    <Form.Label>Free Visit</Form.Label>
                                    <Form.Control type="text" placeholder="Enter validity period..." name='freeVisit' id='freeVisit' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 mb-xl-0 custom-form-input custom-form-input-sm">
                                    <Form.Label>Earning Type</Form.Label>
                                    <Form.Select aria-label="Default select example" name='earningType' id='earningType'>
                                        <option>Full earning</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 mb-sm-0 custom-form-input custom-form-input-sm">
                                    <Form.Label>Earning(%)</Form.Label>
                                    <Form.Control type="text" placeholder="Enter validity period..." name='earning' id='earning' />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="btn-list text-end">
                                    <Button variant="primary" type="submit">Save</Button>{' '}
                                    <Button variant="secondary" type="button">Cancel</Button>{' '}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}
