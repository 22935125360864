import React from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card } from 'react-bootstrap';

// Icon
import Icon from '@mdi/react';
import { mdiUpdate, mdiOfficeBuildingOutline, mdiWeb, mdiPhoneDialOutline } from '@mdi/js';

export default function AboutUs() {
    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <PageTitle breadcrumbLast="About Us" />

                    <Container>
                        <Row>
                            <Col sm={6} xl={3}>
                                <Card className='text-center'>
                                    <Card.Body>
                                        <div className={`border border-soft-primary d-inline-block rounded-circle p-1`}>
                                            <div className='avatar avatar-xl rounded-circle bg-soft-primary text-primary mx-auto'>
                                                <Icon path={mdiUpdate} className='avatar-icon' />
                                            </div>
                                        </div>
                                        <h5 className='mt-3 mb-2 fs-18'>Version</h5>
                                        <p className='mb-0 fs-18'>myRetail v 2.40.000(5566)</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6} xl={3}>
                                <Card className='text-center'>
                                    <Card.Body>
                                        <div className={`border border-soft-primary d-inline-block rounded-circle p-1`}>
                                            <div className='avatar avatar-xl rounded-circle bg-soft-primary text-primary mx-auto'>
                                                <Icon path={mdiOfficeBuildingOutline} className='avatar-icon' />
                                            </div>
                                        </div>
                                        <h5 className='mt-3 mb-2 fs-18'>Company Name</h5>
                                        <p className='mb-0 fs-18'>Foogle Tech Software</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6} xl={3}>
                                <Card className='text-center'>
                                    <Card.Body>
                                        <div className={`border border-soft-primary d-inline-block rounded-circle p-1`}>
                                            <div className='avatar avatar-xl rounded-circle bg-soft-primary text-primary mx-auto'>
                                                <Icon path={mdiWeb} className='avatar-icon' />
                                            </div>
                                        </div>
                                        <h5 className='mt-3 mb-2 fs-18'>Website</h5>
                                        <p className='mb-0 fs-18'>www.foogletech.com</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6} xl={3}>
                                <Card className='text-center'>
                                    <Card.Body>
                                        <div className={`border border-soft-primary d-inline-block rounded-circle p-1`}>
                                            <div className='avatar avatar-xl rounded-circle bg-soft-primary text-primary mx-auto'>
                                                <Icon path={mdiPhoneDialOutline} className='avatar-icon' />
                                            </div>
                                        </div>
                                        <h5 className='mt-3 mb-2 fs-18'>Contact Us</h5>
                                        <p className='mb-0 fs-18'>(+91) 7069 9999 51</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
