import React from 'react';
import { Card, Table } from 'react-bootstrap';

// icon
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';

export default function Notes() {
    return (
        <>
            <Card.Title>Notes Details</Card.Title>
            <Table responsive bordered className='table-nowrap'>
                <thead className='table-light'>
                    <tr>
                        <th>Notes Details</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>mkmk</td>
                        <td>
                            <a href="/">
                                <div className='avatar avatar-xs bg-soft-danger text-danger'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}
