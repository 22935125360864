import React, { useState, useEffect,useRef } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { getFromAPI, postToAPI } from '../../../Utils/utils';
import { toast, ToastContainer } from "react-toastify";
import { useCustomer, CustomerProvider } from '../getCustomerData'; // Import the useCustomer hook


export default function ImportCustomer() {
    const { fetchCustomerData } = useCustomer();
    const fileInputRef = useRef(null); // Create a reference for the file input
    
    const [data, setData] = useState({
        customer_name: '',
        email: '',
        Mobile_Number: '',
        gender: '',
        birthday: '',
        anniversary: '',
        address: '',
        referred_by: '',
        GST: '',
        country: '',
        state: '',
        city: '',
        tax: '',
        pan: '',
        opening_balance: '',
        bank_name: '',
        ifsc_code: '',
        AccountNumber: '',
    });

    const [importedData, setImportedData] = useState([]);
    const [isDataValid, setIsDataValid] = useState(true); // New state for button disabled status


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const binaryStr = event.target.result;
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const importedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    const headerMapping = {
                        'Customer Name': 'customer_name',
                        'Email Address': 'email',
                        'Mobile Number': 'Mobile_Number',
                        'Gender': 'gender',
                        'Birthday': 'birthday',
                        'Anniversary': 'anniversary',
                        'Address': 'address',
                        'Referred By': 'referred_by',
                        'GST': 'GST',
                        'Country': 'country',
                        'State': 'state',
                        'City': 'city',
                        'Tax': 'tax',
                        'Pan': 'pan',
                        'Opening Balance': 'opening_balance',
                        'Bank Name': 'bank_name',
                        'IFSC Code': 'ifsc_code',
                        'Account Number': 'AccountNumber'
                    };

                    const [headers, ...rows] = importedData;
                    const trimmedHeaders = headers.map(header => header.trim());
                    const mappedHeaders = trimmedHeaders.map(header => headerMapping[header] || header);

                    const validData = rows.map((row, index) => {
                        const entry = {};
                        mappedHeaders.forEach((header, headerIndex) => {
                            const value = row[headerIndex];
                            if (header) {
                                entry[header] = header === 'Birthday' || header === 'Anniversary'
                                    ? convertExcelDate(value)
                                    : value;
                            }
                        });
                        return {
                            ...entry,
                            sRNo: index + 1,
                            verified: false
                        };
                    });

                    setImportedData(validData);

                } catch (error) {
                    console.error('Error reading file:', error);
                    toast.error('Invalid file format', {});
                }
            };
            reader.readAsBinaryString(file);
        }
    };

    function convertExcelDate(serial) {
        const baseDate = new Date(1900, 0, 1);
        const date = new Date(baseDate.getTime() + (serial - 2) * 24 * 60 * 60 * 1000);
        return date.toISOString().split('T')[0];
    }

    const convertedData = importedData.map(entry => {
        return {
            ...entry,
            birthday: convertExcelDate(entry.birthday),
            anniversary: convertExcelDate(entry.anniversary)
        };
    });


    function convertDateToYMD(serial) {
        const baseDate = new Date(1900, 0, 1);
        const date = new Date(baseDate.getTime() + (serial - 2) * 24 * 60 * 60 * 1000);
        return date.toISOString().split('T')[0];
    }

    const handleAcceptData = async () => {
        try {
            // Remove 'sRNo' and 'verified' fields, and convert 'opening_balance' to a string
            const cleanedData = convertedData.map(({ sRNo, verified, opening_balance, tax, ...rest }) => ({
                ...rest,
                opening_balance: String(opening_balance), // Convert to string
                tax: String(tax)

            }));

            // Wrap data in the required structure
            const dataToSend = {
                data: cleanedData
            };

            const response = await postToAPI('FtsClients/', { data: cleanedData }, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.status) {
                toast.success('Data verified and saved successfully', 'success');
                setImportedData([]);
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear the file input value
                }
                fetchCustomerData(); // Update the customer list
            } else {
                toast.error('Failed to save data. Please check the format and try again.', 'error');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error('An error occurred while saving the data.', 'error');
        }
    };

    const handleCancel = () => {
        setImportedData([]);
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the file input value
        }
    };

    const downloadSample = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sample Data');

            const headers = [
                'Customer Name', 'Email Address', 'Mobile Number', 'Gender', 'Birthday', 'Anniversary',
                'Address', 'Referred By', 'GST', 'Country', 'State', 'City', 'Tax', 'Pan',
                'Opening Balance', 'Bank Name', 'IFSC Code', 'Account Number'
            ];
            worksheet.addRow(headers);

            // Add a sample row of data
            worksheet.addRow([
                'John Doe', 'john.doe@example.com', '1234567890', 'Male', '1980-01-01', '2000-01-01',
                '123 Main St', 'Jane Doe', '29ABCDE1234F1Z5', 'India', 'Delhi', 'New Delhi', 'CGST 5%', 'ABCDE1234F',
                '1000.00', 'ICICI', 'ICIC0001234', '123456789012'
            ]);

            worksheet.columns = headers.map(header => ({ header, key: header }));

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'sample_customer_data.xlsx';
            a.click();
            URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error generating sample file:', error);
            toast.error('Failed to download sample file.', 'error');
        }
    };


    return (
        <>
            <Card.Body className='pb-0'>
                <Card.Title>Import Customer</Card.Title>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="formFileImportProducts" className="mb-3">
                            <Form.Label>Select File</Form.Label>
                            <Form.Control 
                                type="file" 
                                name='importProducts' 
                                accept=".xlsx, .xls, .csv"
                                onChange={handleFileChange} 
                                ref={fileInputRef} // Attach ref to file input
                            />
                        </Form.Group>
                        <Row className='justify-content-center'>
                            <Col xl={8} className="text-center">
                                <h5 className='mb-3'>Download a sample csv file that you can populate with your own data</h5>
                                <p className='text-muted lh-lg fs-14'>Use the button below to upload a csv file of your service list. Once you have selected a file to upload, your service list will be imported.
                                    Duplicate services(based on service name & service category) are not created by this process, so you can correct errors and
                                    upload the same file more than once, if necessary.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
            <div className='custom-card-footer'>
                <div className="btn-list text-end">
                <Button variant="primary" onClick={downloadSample}>Download Sample</Button>{' '}
                <Button variant="success" onClick={handleAcceptData}>Import</Button>{' '}
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                </div>
            </div>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />

        </>
    )
}
